import View from "Views/View.js";
import {Link} from "react-router-dom";

const InvalidRouteView = () => {

	return (
		<View>
			<h1>Invalid URI</h1>
			<p>Please check the link you've visited.</p>
			<Link to="/">Back to Home</Link>
		</View>
	);

};

export default InvalidRouteView;
