import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import CategoriesIndex from "./BuildCategoriesIndex.js";
import CategoriesTabStrip from "./BuildCategoriesTabStrip.js";
import ErrorBoundary from "Components/ErrorBoundary.js";
import ErrorBoundaryRouted from "Components/ErrorBoundaryRouted.js";
import Flex from "Components/Flex.js";
import Sheet from "./Sheet/BuildDatabaseSheet.js";
import View from "Views/View.js";
import {memo, useCallback, useMemo} from "react";
import {useParams} from "react-router-dom";

const BuildDatabaseView = memo(() => {

	const params = useParams();
	const ProductRangeCategoryId = (parseInt(params.ProductRangeCategoryId) || null);
	const SheetCategoryId = (parseInt(params.SheetCategoryId) || null);


	/**
	 * Fetch the categories list
	 */
	const categoriesFetch = useAsync(useCallback(() => {
		return api({
			url: `/build/categories`
		}).then(({data}) => data);
	}, []));


	/**
	 * Resort our categories so the order's consistent after local changes
	 */
	const categories = useMemo(() => {
		return categoriesFetch.result?.sort((a, b) => {
			return a.Name.localeCompare(b.Name);
		});
	}, [categoriesFetch]);


	/**
	 * Product ranges are currently assumed to be the top-level categories
	 */
	const productRanges = useMemo(() => {
		return categories?.filter(c => !c.Parent);
	}, [categories]);


	/**
	 * A category was created/changed.
	 */
	const handleCategoryChanged = useCallback((category, existingCategoryId) => {

		const updatedCategories = [...categoriesFetch.result];

		if (existingCategoryId) {
			const index = updatedCategories.indexOf(updatedCategories.find(({Id}) => (Id === existingCategoryId)));
			updatedCategories[index] = category;
		}
		else updatedCategories.push(category);

		categoriesFetch.setResult(updatedCategories);

	}, [categoriesFetch]);


	/**
	 * A category was deleted.
	 */
	const handleCategoryDeleted = useCallback(categoryId => {
		categoriesFetch.setResult(
			categoriesFetch.result.filter(c => (c.Id !== categoryId))
		);
	}, [categoriesFetch]);


	/**
	 * Get the current sheet category
	 */
	const sheetCategory = useMemo(() => {
		return (SheetCategoryId ? categories?.find(c => (c.Id === SheetCategoryId)) : null);
	}, [categories, SheetCategoryId]);


	/**
	 * Are we rendering the Product Ranges index?
	 */
	const isProductRangesIndex = (!ProductRangeCategoryId && !SheetCategoryId);


	/**
	 * Render!
	 */
	return (
		<View>
			<Flex gap={0.5}>
				<ErrorBoundary>
					<CategoriesTabStrip
						activeProductRangeId={(ProductRangeCategoryId || sheetCategory?.Parent || sheetCategory?.Id)}
						categories={(isProductRangesIndex ? productRanges : categories?.filter(c => (((c.Parent === ProductRangeCategoryId) && ProductRangeCategoryId) || (c.Parent === sheetCategory?.Parent))))}
						productRanges={productRanges}
						isProductRangesIndex={(isProductRangesIndex || (sheetCategory && !sheetCategory.Parent))} />
				</ErrorBoundary>
				<ErrorBoundaryRouted>
					{(
						!SheetCategoryId ?
							<CategoriesIndex
								categories={(isProductRangesIndex ? productRanges : categories)}
								error={categoriesFetch.error}
								loading={categoriesFetch.loading}
								onCategoryChanged={handleCategoryChanged}
								onCategoryDeleted={handleCategoryDeleted}
								onErrorRetry={categoriesFetch.call}
								topLevelCategoryId={ProductRangeCategoryId} /> :
							<Sheet
								categoryId={SheetCategoryId}
								onCategoryChanged={handleCategoryChanged}
								onCategoryDeleted={handleCategoryDeleted} />
					)}
				</ErrorBoundaryRouted>
			</Flex>
		</View>
	);

});

export default BuildDatabaseView;
