import CustomerStatus from "./CustomerStatus.js";
import EnumPicker from "Components/EnumPicker.js";
import {memo} from "react";

const CustomerStatusPicker = memo(props => {

	return (
		<EnumPicker
			enumClass={CustomerStatus}
			label="Customer Status"
			{...props} />
	);

});

export default CustomerStatusPicker;
