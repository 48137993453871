import {memo} from "react";
import scss from "./Panes.module.scss";

const Panes = memo(({
	children,
	className
}) => {

	return (
		<div
			className={`${scss.root} ${(className || "")}`.trim()}>
			{children}
		</div>
	);

});

export default Panes;
