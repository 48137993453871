import api from "api.js";
import scss from "./BuildDatabaseSheet.module.scss";
import useAsync from "Hooks/useAsync.js";
import BuildItemDialog from "BuildDatabase/Items/BuildItemDialog.js";
import BuildItemTypes from "BuildDatabase/BuildItemTypes.js";
import DividerRow from "./BuildDatabaseSheetDividerRow.js";
import ErrorBoundary from "Components/ErrorBoundary.js";
import Flex from "Components/Flex.js";
import Header from "./BuildDatabaseSheetHeader.js";
import Loadable from "Components/Loadable.js";
import ProductsSection from "./BuildDatabaseSheetProductsSection.js";
import TaskColumns from "./BuildDatabaseSheetTaskColumns.js";
import TaskRow from "./BuildDatabaseSheetTaskRow.js";
import TaskGroupRow from "./BuildDatabaseSheetTaskGroupRow.js";
import TaskGroupTotalsRow from "./BuildDatabaseSheetTaskGroupTotalsRow.js";
import ToastStore from "Toasts/ToastStore.js";
import Toolbar from "./BuildDatabaseSheetToolbar.js";
import Totals from "./BuildDatabaseSheetTotals.js";
import {sortBuildResources} from "BuildDatabase/BuildDatabaseUtils.js";
import {memo, useCallback, useEffect, useMemo, useState, Fragment} from "react";
import {Table} from "semantic-ui-react";

const BuildDatabaseSheet = memo(({
	categoryId,
	onCategoryChanged,
	onCategoryDeleted
}) => {

	/**
	 * Active build item to show the dialog for
	 */
	const [activeBuildItem, setActiveBuildItem] = useState(null);


	/**
	 * Fetch our sheet's data
	 */
	const dataFetch = useAsync(useCallback(() => {
		return api({url: `/build/sheets/categories/${categoryId}`}).then(({data}) => data);
	}, [categoryId]));


	/**
	 * Find the Build Category that this sheet relates to
	 */
	const sheetCategory = useMemo(() => {
		return dataFetch.result?.BuildCategories.find(({Id}) => (Id === categoryId));
	}, [dataFetch.result, categoryId]);


	/**
	 * Associating Build Tasks with their Build Task Groups
	 *
	 * (Allows us to conveniently render the group headings.)
	 */
	const taskGroupsWithTasks = useMemo(() => {
		return sortBuildResources(dataFetch.result?.BuildTaskGroups).map(group => {
			return {
				...group,
				Tasks: sortBuildResources(dataFetch.result?.BuildTasks?.filter(t => (t.BuildTaskGroup === group.Id)))
			};
		});
	}, [dataFetch.result]);


	/**
	 * Get the Build Item Task Step Links object from our data,
	 * but exclude links referencing specific steps by their IDs.
	 */
	const getItemTaskStepLinksWithStepExclusions = useCallback(excludedStepIds => {
		return Object.keys(dataFetch.result.BuildItemTaskStepLinks).reduce((current, itemId) => {

			current[itemId] = Object.keys(dataFetch.result.BuildItemTaskStepLinks[itemId]).reduce((current, stepId) => {

				if (!excludedStepIds.includes(parseInt(stepId))) {
					current[stepId] = dataFetch.result.BuildItemTaskStepLinks[itemId][stepId];
				}

				return current;

			}, {});

			return current;

		}, {});
	}, [dataFetch.result]);


	/**
	 * Build category edited.
	 */
	const handleCategoryEdit = useCallback((category, existingId) => {
		onCategoryChanged(category, existingId);
		dataFetch.call();
	}, [dataFetch, onCategoryChanged]);


	/**
	 * Build item dialog closed.
	 */
	const handleBuildItemDialogClose = useCallback(() => {
		setActiveBuildItem(null);
	}, []);


	/**
	 * Changed/created a build item.
	 */
	const handleChangeBuildItem = useCallback((BuildItem, existingBuildItemId) => {

		setActiveBuildItem(null);

		const updatedData = {
			...dataFetch.result,
			BuildItems: [...dataFetch.result.BuildItems]
		};

		if (existingBuildItemId) {

			const index = updatedData.BuildItems.indexOf(
				updatedData.BuildItems.find(({Id}) => {
					return (Id === existingBuildItemId);
				})
			);

			updatedData.BuildItems[index] = BuildItem;

		}
		else updatedData.BuildItems.push(BuildItem);

		dataFetch.setResult(updatedData);

	}, [dataFetch]);


	/**
	 * Deleted a build item.
	 */
	const handleDeleteBuildItem = useCallback(buildItemId => {

		setActiveBuildItem(null);

		dataFetch.setResult({
			...dataFetch.result,
			BuildItems: dataFetch.result.BuildItems.filter(({Id}) => (Id !== buildItemId))
		});

	}, [dataFetch]);


	/**
	 * Active build item set.
	 */
	const handleSetActiveBuildItem = useCallback(BuildItem => {
		setActiveBuildItem(BuildItem);
	}, []);


	/**
	 * Changed/created a task.
	 */
	const handleChangeTask = useCallback((Task, TaskSteps, existingTaskId) => {

		const currentStepIds = TaskSteps?.map(step => step.Id);
		const existingStepIds = (dataFetch.result.BuildTaskSteps[existingTaskId] || []).map(step => step.Id);
		const removedStepIds = existingStepIds.filter(id => !currentStepIds?.includes(id));

		const updatedData = {
			...dataFetch.result,
			BuildTasks: [
				...dataFetch.result.BuildTasks
			],
			BuildTaskSteps: {
				...dataFetch.result.BuildTaskSteps,
				[(existingTaskId || Task.Id)]: (TaskSteps || [])
			},
			BuildItemTaskStepLinks: getItemTaskStepLinksWithStepExclusions(removedStepIds)
		};

		if (existingTaskId) {

			const index = updatedData.BuildTasks.indexOf(
				updatedData.BuildTasks.find(({Id}) => {
					return (Id === existingTaskId);
				})
			);

			updatedData.BuildTasks[index] = Task;

		}
		else updatedData.BuildTasks.push(Task);

		dataFetch.setResult(updatedData);

	}, [dataFetch, getItemTaskStepLinksWithStepExclusions]);


	/**
	 * Deleted a task.
	 */
	const handleDeleteTask = useCallback(taskId => {

		const taskStepIds = (dataFetch.result.BuildTaskSteps[taskId] || []).map(step => step.Id);

		dataFetch.setResult({
			...dataFetch.result,
			BuildTasks: dataFetch.result.BuildTasks.filter(({Id}) => (Id !== taskId)),
			BuildTaskSteps: Object.keys(dataFetch.result.BuildTaskSteps).reduce((current, key) => {
				if (parseInt(key) !== taskId) {
					current[key] = dataFetch.result.BuildTaskSteps[key];
				}
				return current;
			}, {}),
			BuildItemTaskStepLinks: getItemTaskStepLinksWithStepExclusions(taskStepIds)
		});
	}, [dataFetch, getItemTaskStepLinksWithStepExclusions]);


	/**
	 * Changed/created a task group.
	 */
	const handleChangeTaskGroup = useCallback((TaskGroup, existingTaskGroupId) => {

		const updatedData = {
			...dataFetch.result,
			BuildTaskGroups: [...dataFetch.result.BuildTaskGroups]
		};

		if (existingTaskGroupId) {

			const index = updatedData.BuildTaskGroups.indexOf(
				updatedData.BuildTaskGroups.find(({Id}) => {
					return (Id === existingTaskGroupId);
				})
			);

			updatedData.BuildTaskGroups[index] = TaskGroup;

		}
		else updatedData.BuildTaskGroups.push(TaskGroup);

		dataFetch.setResult(updatedData);

	}, [dataFetch]);


	/**
	 * Deleted a task group.
	 */
	const handleDeleteTaskGroup = useCallback(taskGroupId => {
		dataFetch.setResult({
			...dataFetch.result,
			BuildTaskGroups: dataFetch.result.BuildTaskGroups.filter(({Id}) => (Id !== taskGroupId))
		});
	}, [dataFetch]);


	/**
	 * Changed the time allowance for a build item/build task step.
	 */
	const handleChangeBuildItemTaskStepTimeAllowance = useCallback((TimeAllowance, BuildItemId, BuildTaskStepId) => {

		const updatedData = {
			...dataFetch.result,
			BuildItemTaskStepLinks: {
				...dataFetch.result?.BuildItemTaskStepLinks,
				[BuildItemId]: {
					...dataFetch.result?.BuildItemTaskStepLinks?.[BuildItemId],
					[BuildTaskStepId]: {
						...dataFetch.result?.BuildItemTaskStepLinks?.[BuildItemId]?.[BuildTaskStepId],
						TimeAllowance
					}
				}
			}
		};

		dataFetch.setResult(updatedData);

	}, [dataFetch]);


	/**
	 * Changed the quantity required of a build item by another build item.
	 */
	const handleChangeBuildItemRequirementQuantity = useCallback((QuantityRequired, BuildItemId, RequiredBuildItemId) => {

		const updatedData = {
			...dataFetch.result,
			BuildItemRequirements: {
				...dataFetch.result?.BuildItemRequirements,
				[BuildItemId]: {
					...dataFetch.result?.BuildItemRequirements?.[BuildItemId],
					[RequiredBuildItemId]: {
						...dataFetch.result?.BuildItemRequirements?.[BuildItemId]?.[RequiredBuildItemId],
						QuantityRequired
					}
				}
			}
		};

		dataFetch.setResult(updatedData);

	}, [dataFetch]);


	/**
	 * Get our Build Items data in the sort order we want
	 *
	 * (We must locally sort so the order remains correct after 
	 * e.g. editing a build item and changing its sort order as 
	 * we don't refetch the remote data after every change.)
	 */
	const buildItemsSorted = sortBuildResources(dataFetch.result?.BuildItems);


	/**
	 * Get the Build Items that are/are not Build Products
	 *
	 * (These are the only ones which get columns on the sheet.)
	 */
	const buildItems = buildItemsSorted?.filter(i => (i.BuildItemType !== BuildItemTypes.Product));
	const buildProducts = buildItemsSorted?.filter(i => (i.BuildItemType === BuildItemTypes.Product));


	/**
	 * Cache the count of build items that we have
	 */
	const buildItemsCount = buildItems?.length;


	/**
	 * Compute time totals for the build items
	 */
	const buildItemsTotals = useMemo(() => {

		const totals = {};

		for (const buildItem of buildItems) {

			const itemTaskLinks = Object.values(dataFetch?.result?.BuildItemTaskStepLinks?.[buildItem.Id] || {});
			const durationMinutes = (itemTaskLinks?.reduce((a, b) => (a + (b?.TimeAllowance || 0)), 0) || 0);

			totals[buildItem.Id] = {
				DurationMinutes: durationMinutes,
				DurationWorkingDays: (durationMinutes / (dataFetch?.result?.Settings?.DailyWorkingMinutes || 0)).toFixed(2)
			};

		}

		return totals;

	}, [buildItems, dataFetch]);


	/**
	 * Render a divider row appropriate for our current state.
	 * 
	 * @return {ReactNode}
	 */
	const renderDividerRow = useCallback(() => {
		return (
			<DividerRow
				columnCount={(TaskColumns.length + buildItemsCount)} />
		);
	}, [buildItemsCount]);


	/**
	 * Loading error message
	 */
	useEffect(() => {
		if (dataFetch.error) {
			ToastStore.error(`Error ${(!dataFetch.result ? "loading" : "reloading")} data.`);
		}
	}, [dataFetch]);


	/**
	 * Render!
	 */
	return (
		<Flex gap={0.5}>
			<ErrorBoundary fallback={null}>
				<Toolbar
					loading={dataFetch.loading}
					sheetCategory={sheetCategory}
					onCreatedBuildItem={handleChangeBuildItem}
					onCreatedCategory={handleCategoryEdit}
					onCreatedTask={handleChangeTask}
					onCreatedTaskGroup={handleChangeTaskGroup}
					onCategoryChanged={handleCategoryEdit}
					onCategoryDeleted={onCategoryDeleted} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Loadable
					error={(dataFetch.error && !dataFetch.result)}
					loading={(dataFetch.loading && !dataFetch.result)}
					onErrorRetry={dataFetch.call}>
					<div
						className={scss.tableContainer}>
						<Table
							celled={true}
							compact="very"
							fixed={true}
							structured={true}
							unstackable={true}>
							<Header
								buildItems={buildItems}
								genericColumns={TaskColumns}
								onSelectedBuildItem={handleSetActiveBuildItem} />
							<Table.Body>
								{
									taskGroupsWithTasks?.map((taskGroup, key) => {
										return (
											<Fragment key={key}>
												<TaskGroupRow
													group={taskGroup}
													buildItemsCount={buildItemsCount}
													genericColumnsCount={TaskColumns.length}
													onChanged={handleChangeTaskGroup}
													onDeleted={handleDeleteTaskGroup} />
												{
													taskGroup.Tasks?.map((task, key) => {
														return (
															<TaskRow
																key={key}
																task={task}
																buildItems={buildItems}
																sheetCategory={sheetCategory}
																sheetData={dataFetch.result}
																onChanged={handleChangeTask}
																onChangedCategory={handleCategoryEdit}
																onChangeBuildItemTaskStepTimeAllowance={handleChangeBuildItemTaskStepTimeAllowance}
																onCreatedTaskGroup={handleChangeTaskGroup}
																onDeleted={handleDeleteTask} />
														);
													})
												}
												<TaskGroupTotalsRow
													tasks={taskGroup.Tasks}
													buildItems={buildItems}
													sheetData={dataFetch.result}
													genericColumnsCount={TaskColumns.length} />
												{renderDividerRow()}
											</Fragment>
										);
									})
								}
								<ErrorBoundary>
									<Totals
										sheetData={dataFetch.result}
										buildItems={buildItems}
										totals={buildItemsTotals}
										genericColumnsCount={TaskColumns.length} />
								</ErrorBoundary>
								<ErrorBoundary>
									{
										!!buildProducts?.length &&
											<Fragment>
												{renderDividerRow()}
												<ProductsSection
													buildItems={buildItems}
													buildProducts={buildProducts}
													requirements={dataFetch.result?.BuildItemRequirements}
													totals={buildItemsTotals}
													onSelectBuildItem={handleSetActiveBuildItem}
													onChangeBuildItemRequirementQuantity={handleChangeBuildItemRequirementQuantity}
													genericColumnsCount={TaskColumns.length}
													workingMinsPerDay={dataFetch.result?.Settings?.DailyWorkingMinutes} />
											</Fragment>
									}
								</ErrorBoundary>
							</Table.Body>
						</Table>
					</div>
				</Loadable>
			</ErrorBoundary>
			{
				activeBuildItem &&
					<BuildItemDialog
						buildItem={activeBuildItem}
						defaultBuildCategory={sheetCategory}
						onClose={handleBuildItemDialogClose}
						onDeleted={handleDeleteBuildItem}
						onSubmitted={handleChangeBuildItem}
						open={true} />
			}
		</Flex>
	);

});

export default BuildDatabaseSheet;
