import api from "api.js";
import BuildRevisionPicker from "./ProjectBuildRevisionPicker.js";
import Pane from "Components/Pane.js";
import ProjectStatus from "Projects/ProjectStatus.js";
import ProjectsBuildRevisionsPaneContent from "./ProjectsBuildRevisionsPaneContent.js";
import SubduedStateLabel from "Components/SubduedStateLabel.js";
import ToastStore from "Toasts/ToastStore.js";
import scss from "./ProjectBuildRevisionsPane.module.scss";
import useAsync from "Hooks/useAsync.js";
import {memo, useCallback, useMemo, useState} from "react";
import {Button, Divider} from "semantic-ui-react";

const ProjectBuildRevisionsPane = memo(({
	project,
	onApproveRevision,
	onSelectRevision,
	selectedRevisionId
}) => {

	const projectId = project?.Id;
	const [isSubmitting, setIsSubmitting] = useState(false);


	const findInitialSelectedRevision = useCallback(revisions => {
		return (revisions.find(r => r.Active) || revisions[0]);
	}, []);


	const revisionsFetch = useAsync(useCallback(() => {
		return (
			projectId ?
				api({
					url: `/projects/${projectId}/build/revisions`
				}).then(({data}) => {
					onSelectRevision(findInitialSelectedRevision(data)?.Id);
					return data;
				}) :
				[]
		);
	}, [projectId, onSelectRevision, findInitialSelectedRevision]));


	const selectedRevision = useMemo(() => {
		if (selectedRevisionId && revisionsFetch.result) {
			return revisionsFetch.result?.find(r => (r.Id === selectedRevisionId));
		}
		else return null;
	}, [revisionsFetch.result, selectedRevisionId]);


	const handleRevisionCreated = useCallback(revision => {

		revisionsFetch.setResult([
			revision,
			...revisionsFetch.result.map(r => ({...r, Active: false}))
		]);

		onSelectRevision(revision.Id);

	}, [revisionsFetch, onSelectRevision]);


	const handleRevisionDeleted = useCallback(revisionId => {

		const remainingRevisions = revisionsFetch.result.filter(r => (r.Id !== revisionId));

		revisionsFetch.setResult(remainingRevisions);
		onSelectRevision(remainingRevisions[0]?.Id);

	}, [revisionsFetch, onSelectRevision]);


	const handleRevisionMadeActive = useCallback(revisionId => {

		const updatedRevisions = revisionsFetch.result.map(revision => {
			return {
				...revision,
				Active: (revision.Id === revisionId)
			};
		});

		revisionsFetch.setResult(updatedRevisions);

	}, [revisionsFetch]);


	const handleRevisionSelected = useCallback(revision => {
		onSelectRevision(revision.Id);
	}, [onSelectRevision]);


	const handleRevisionUpdated = useCallback((updated, current) => {
		const updatedRevisions = [...revisionsFetch.result];
		const targetRevisionIndex = updatedRevisions.indexOf(current);
		if (targetRevisionIndex > -1) updatedRevisions[targetRevisionIndex] = updated;
		revisionsFetch.setResult(updatedRevisions);
	}, [revisionsFetch]);


	const handleCreateRevision = useCallback(async () => {

		setIsSubmitting(true);

		try {

			const revision = await api({
				url: `/projects/${projectId}/build/revisions`,
				method: "POST",
				data: {
					Active: true,
					Notes: null
				}
			}).then(({data}) => data);

			handleRevisionCreated(revision);

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [projectId, handleRevisionCreated]);


	return (
		<Pane
			label="Project Revisions">
			<div className={scss.header}>
				<BuildRevisionPicker
					disabled={!project}
					error={revisionsFetch.error}
					loading={revisionsFetch.loading}
					onChange={handleRevisionSelected}
					revisions={revisionsFetch.result}
					value={selectedRevisionId} />
				{
					(!project || (project?.Status.Id === ProjectStatus.Created)) &&
						<Button
							basic={true}
							disabled={(!project || isSubmitting)}
							icon="add"
							onClick={handleCreateRevision}
							title="New Project Revision" />
				}
			</div>
			<Divider />
			<div
				className={scss.content}>
				{
					project ?
						(
							selectedRevision ?
								<ProjectsBuildRevisionsPaneContent
									project={project}
									revision={selectedRevision}
									onApproved={onApproveRevision}
									onCreated={handleRevisionCreated}
									onDeleted={handleRevisionDeleted}
									onMakeActive={handleRevisionMadeActive}
									onUpdated={handleRevisionUpdated} /> :
								<SubduedStateLabel
									content={
										(
											revisionsFetch.loading ?
												"Loading..." :
												(
													revisionsFetch.error ?
														"Error." :
														"No Project Revision selected."
												)
										)
									} />
						) :
						<SubduedStateLabel
							content="No Project selected." />
				}
			</div>
		</Pane>
	);

});

export default ProjectBuildRevisionsPane;
