import Enum from "Includes/Enum.js";

/**
 * Project status enum
 *
 * @package RedSky
 * @subpackage Projects
 * @author Heron Web Ltd
 * @copyright Red Sky Shepherds Huts Limited
 */
class ProjectStatus extends Enum {

	static Created = 1;

	static Live = 2;

	static Complete = 3;

	static Cancelled = 4;

	static getColour(name) {
		return {
			Created: "yellow",
			Live: "teal",
			Complete: "blue",
			Cancelled: "grey"
		}[name];
	}

	static getLabel(name) {
		return ({
			Created: "Enquiry",
			Live: "Approved",
			Complete: "Completed"
		}[name] || name);
	}

	static getDefaultProjectPhaseCaseName(name) {
		return {
			Created: "CustomerWaitingForInitialEnquiryReply",
			Live: "FirstPaymentReceived",
			Complete: "BuildCompleted",
			Cancelled: "CustomerCancellationRequestUnderReview"
		}[name];
	}

}

export default ProjectStatus;
