import api from "api.js";
import moment from "moment";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useState} from "react";

const UnassignStaffMemberDateScheduledTasksDialog = memo(({
	open,
	onClose,
	onUnassigned,
	staffMember,
	scheduleDate
}) => {

	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleSubmit = useCallback(async () => {

		setIsSubmitting(true);

		try {

			await api({
				/** The two "scheduleDate" references *are* needed (start and end date) */
				url: `/staff/${staffMember.Id}/tasks/assignments/${scheduleDate}/${scheduleDate}`,
				method: "DELETE"
			});

			onClose();
			onUnassigned(staffMember, scheduleDate);

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [staffMember, scheduleDate, onClose, onUnassigned]);

	return (
		<Dialog
			open={open}
			onOk={handleSubmit}
			onClose={onClose}
			submitting={isSubmitting}
			title="Unassign Tasks"
			content={`Are you sure you want to unassign all of ${staffMember.Name}'s tasks for ${(new moment(scheduleDate)).format("DD/MM/YYYY")}?`}
			size="tiny" />
	);

});

export default UnassignStaffMemberDateScheduledTasksDialog;
