/**
 * Get the duration label to display for a duration value.
 *
 * Includes `h`/`m` labels.
 *
 * @param {Integer} duration Minutes
 * @return {String}
 */
const getDurationLabel = duration => {

	if (!duration) {
		return "";
	}

	const durationHrs = Math.floor((duration / 60));
	const durationMins = (duration - (durationHrs * 60));

	return [
		((durationHrs > 0) ? `${durationHrs}h` : null),
		(((durationMins > 0) || !durationHrs) ? `${durationMins}m` : null)
	].filter(i => i).join(" ");

};

/**
 * Get the duration label to display for a duration value.
 *
 * Displays in clock format.
 *
 * @param {Integer} duration
 * @return {String}
 */
const getDurationLabelClockFormat = duration => {

	if (!duration) duration = 0;
	const durationHrs = Math.floor((duration / 60)).toString();
	const durationMins = (duration - (durationHrs * 60)).toString();

	return `${durationHrs.padStart(2, 0)}:${durationMins.padStart(2, 0)}`;

};

/**
 * Get the number of weekdays between two dates.
 * 
 * @param {Moment} startDate
 * @param {Moment} endDate
 * @return {Integer}
 */
const getWeekdaysDiff = (startDate, endDate) => {

	let elapsed = 0;

	while (startDate.format("DD-MM-YYYY") !== endDate.format("DD-MM-YYYY")) {

		startDate = startDate.clone().add(1, "day");

		if (!["Sat", "Sun"].includes(startDate.format("ddd"))) {
			elapsed++;
		}

	}

	return elapsed;

};

export {getDurationLabel, getDurationLabelClockFormat, getWeekdaysDiff};
