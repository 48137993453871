import api from "api.js";
import BuildCategoryPicker from "BuildDatabase/Categories/BuildCategoryPicker.js";
import BuildItemPicker from "BuildDatabase/Items/BuildItemPicker.js";
import BuildItemTypes from "BuildDatabase/BuildItemTypes.js";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import scss from "./ProjectBuildRevisionBuildItemLinkCreationDialog.module.scss";
import useForm from "Hooks/useForm.js";
import {memo, useCallback, useMemo, useState} from "react";
import {Divider, Form} from "semantic-ui-react";

const ProjectBuildRevisionBuildItemLinkCreationDialog = memo(({
	revisionId,
	open,
	onClose,
	onCreated
}) => {

	const form = useForm({
		BuildCategoryId: null,
		BuildItem: null,
		ProductRangeId: null,
		Quantity: 1,
		PersistOnSubmit: false
	});

	const [isSubmitting, setIsSubmitting] = useState(false);


	const buildItemPickerParams = useMemo(() => {
		return {
			BuildItemTypes: [
				BuildItemTypes.Product,
				BuildItemTypes.Item
			]
		};
	}, []);


	const handleCategoryChange = useCallback(BuildCategoryId => {
		form.setState({
			...form.state,
			BuildCategoryId,
			BuildItem: null
		});
	}, [form]);


	const handleProductRangeChange = useCallback(ProductRangeId => {
		form.setState({
			...form.state,
			ProductRangeId,
			BuildCategoryId: null,
			BuildItem: null
		});
	}, [form]);


	const handlePersistOnSubmitToggle = useCallback(() => {
		form.setState({
			...form.state,
			PersistOnSubmit: !form.state.PersistOnSubmit
		});
	}, [form]);


	const handleSubmit = useCallback(async e => {

		e.preventDefault();

		if (!form.state.BuildItem) {
			ToastStore.error("You must select a Project Item to add.");
			return;
		}

		setIsSubmitting(true);

		try {

			const result = await api({
				url: `/projects/build/revisions/${revisionId}/items`,
				method: "POST",
				data: {
					BuildItem: form.state.BuildItem.Id,
					Quantity: parseInt(form.state.Quantity)
				}
			}).then(({data}) => data);

			onCreated(result, form.state.BuildItem);

			if (form.state.PersistOnSubmit) {
				form.setState({
					...form.state,
					BuildItem: null
				});
			}
			else onClose();

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [form, onClose, onCreated, revisionId]);


	return (
		<Dialog
			title="Add Project Item"
			open={open}
			okButtonForm={form.id}
			okButtonType="submit"
			onClose={onClose}
			submitting={isSubmitting}>
			<Form
				id={form.id}
				onSubmit={handleSubmit}>
				<Form.Field
					required={true}>
					<label
						children="Project Item" />
					<div
						className={scss.buildItemInputsContainer}>
						<BuildCategoryPicker
							disabled={isSubmitting}
							label="Product Range"
							name="ProductRangeId"
							onChange={handleProductRangeChange}
							reportIds={true}
							topLevelOnly={true}
							value={form.state.ProductRangeId} />
						<BuildCategoryPicker
							disabled={(isSubmitting || !form.state.ProductRangeId)}
							label="Product Category"
							name="BuildCategoryId"
							onChange={handleCategoryChange}
							parentId={form.state.ProductRangeId}
							reportIds={true}
							value={form.state.BuildCategoryId} />
						<BuildItemPicker
							categoryId={form.state.BuildCategoryId}
							disabled={(isSubmitting || !form.state.BuildCategoryId)}
							label="Project Item"
							name="BuildItem"
							onChange={form.updateProp}
							params={buildItemPickerParams}
							value={form.state.BuildItem?.Id} />
					</div>
				</Form.Field>
				<Divider
					hidden={true} />
				<Form.Input
					disabled={isSubmitting}
					min={1}
					label="Quantity"
					name="Quantity"
					onChange={form.updateStateFromSemanticInputChangeEvent}
					placeholder="Quantity"
					required={true}
					type="number"
					width="4"
					value={(form.state.Quantity || "")} />
				<Divider
					hidden={true} />
				<Form.Checkbox
					checked={form.state.PersistOnSubmit}
					disabled={isSubmitting}
					label="Add another Project Item after this one?"
					onChange={handlePersistOnSubmitToggle} />
			</Form>
		</Dialog>
	);

});

export default ProjectBuildRevisionBuildItemLinkCreationDialog;
