import BuildCategoryDialog from "BuildDatabase/Categories/BuildCategoryDialog.js";
import BuildItemDialog from "BuildDatabase/Items/BuildItemDialog.js";
import BuildTaskDialog from "BuildDatabase/Tasks/BuildTaskDialog.js";
import BuildTaskGroupDialog from "BuildDatabase/Tasks/Groups/BuildTaskGroupDialog.js";
import Flex from "Components/Flex.js";
import ToastStore from "Toasts/ToastStore.js";
import scss from "./BuildDatabaseSheetToolbar.module.scss";
import useValue from "Hooks/useValue.js";
import {memo, useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "semantic-ui-react";

const BuildDatabaseSheetToolbar = memo(({
	loading,
	sheetCategory,
	onCreatedBuildItem,
	onCreatedCategory,
	onCreatedTask,
	onCreatedTaskGroup,
	onCategoryChanged,
	onCategoryDeleted
}) => {

	const navigate = useNavigate();


	const {
		value: isNewBuildItemDialogOpen,
		setTrue: openNewBuildItemDialog,
		setFalse: closeNewBuildItemDialog
	} = useValue(false);

	const handleCreatedBuildItem = useCallback((buildItem, existing, addAnother) => {
		if (!addAnother) closeNewBuildItemDialog();
		onCreatedBuildItem(buildItem);
	}, [onCreatedBuildItem, closeNewBuildItemDialog]);


	const {
		value: isNewTaskDialogOpen,
		setTrue: openNewTaskDialog,
		setFalse: closeNewTaskDialog
	} = useValue(false);

	const handleCreatedTask = useCallback((task, steps, taskId, addAnother) => {
		if (!addAnother) closeNewTaskDialog();
		onCreatedTask(task, steps);
	}, [onCreatedTask, closeNewTaskDialog]);


	const {
		value: isNewTaskGroupDialogOpen,
		setTrue: openNewTaskGroupDialog,
		setFalse: closeNewTaskGroupDialog
	} = useValue(false);

	const handleCreatedTaskGroup = useCallback((taskGroup, existing, addAnother) => {
		if (!addAnother) closeNewTaskGroupDialog();
		onCreatedTaskGroup(taskGroup);
	}, [onCreatedTaskGroup, closeNewTaskGroupDialog]);


	const {
		value: isNewSubCategoryDialogOpen,
		setTrue: openNewSubCategoryDialog,
		setFalse: closeNewSubCategoryDialog
	} = useValue(false);

	const handleCreatedSubCategory = useCallback((category, existing, addAnother) => {
		if (!addAnother) closeNewSubCategoryDialog();
		onCreatedCategory(category);
		ToastStore.success("Sub-category created successfully.");
	}, [onCreatedCategory, closeNewSubCategoryDialog]);


	const {
		value: buildCategoryEditorDialogOpen,
		setTrue: handleOpenBuildCategoryEditorDialog,
		setFalse: handleCloseBuildCategoryEditorDialog
	} = useValue(false);

	const handleDeletedBuildCategory = useCallback(() => {
		navigate(`/build${(sheetCategory.Parent ? `/ranges/${(sheetCategory.Parent?.Id || sheetCategory.Parent)}` : "")}`);
		onCategoryDeleted(sheetCategory.Id);
	}, [navigate, onCategoryDeleted, sheetCategory]);

	const handleEditedBuildCategory = useCallback((buildCategory, existingId) => {
		handleCloseBuildCategoryEditorDialog();
		onCategoryChanged(buildCategory, existingId);
	}, [handleCloseBuildCategoryEditorDialog, onCategoryChanged]);


	return (
		<Flex
			alignItems="center"
			columnar={true}
			justifyContent="space-between"
			gap={0.5}
			wrap={true}>
			<Flex
				alignItems="center"
				columnar={true}
				gap={0.5}>
				<h3
					children={sheetCategory?.Name}
					className={scss.label} />
				{
					sheetCategory &&
						<Button
							basic={true}
							disabled={loading}
							onClick={handleOpenBuildCategoryEditorDialog}
							icon="pencil"
							size="mini" />
				}
				{
					sheetCategory &&
						<Button
							basic={true}
							content="New Sub-Category"
							disabled={loading}
							icon="add"
							onClick={openNewSubCategoryDialog}
							size="mini" />
				}
				{
					isNewSubCategoryDialogOpen &&
						<BuildCategoryDialog
							disableParentEditing={true}
							onClose={closeNewSubCategoryDialog}
							onSubmitted={handleCreatedSubCategory}
							open={isNewSubCategoryDialogOpen}
							parentId={sheetCategory?.Id} />
				}
			</Flex>
			<Flex
				alignItems="center"
				columnar={true}
				justifyContent="flex-end"
				gap={0.25}
				wrap={true}>
				<Button
					basic={true}
					content="New Task"
					disabled={loading}
					icon="add"
					onClick={openNewTaskDialog}
					primary={true}
					size="tiny" />
				{(
					isNewTaskDialogOpen &&
						<BuildTaskDialog
							targetBuildCategoryId={sheetCategory?.Id}
							onClose={closeNewTaskDialog}
							onCreatedCategory={onCreatedCategory}
							onCreatedGroup={onCreatedTaskGroup}
							open={true}
							onSubmitted={handleCreatedTask} />
				)}
				<Button
					basic={true}
					content="New Task Group"
					disabled={loading}
					icon="add"
					onClick={openNewTaskGroupDialog}
					primary={true}
					size="tiny" />
				{(
					isNewTaskGroupDialogOpen &&
						<BuildTaskGroupDialog
							defaultBuildCategory={sheetCategory}
							onClose={closeNewTaskGroupDialog}
							open={true}
							onSubmitted={handleCreatedTaskGroup} />
				)}
				<Button
					basic={true}
					content="New Project Item/Product"
					disabled={loading}
					icon="add"
					onClick={openNewBuildItemDialog}
					primary={true}
					size="tiny" />
				{(
					isNewBuildItemDialogOpen &&
						<BuildItemDialog
							defaultBuildCategory={sheetCategory}
							onClose={closeNewBuildItemDialog}
							open={true}
							onSubmitted={handleCreatedBuildItem} />
				)}
			</Flex>
			{
				buildCategoryEditorDialogOpen &&
					<BuildCategoryDialog
						buildCategory={sheetCategory}
						disableParentEditing={true}
						onClose={handleCloseBuildCategoryEditorDialog}
						onDeleted={handleDeletedBuildCategory}
						onSubmitted={handleEditedBuildCategory}
						open={true} />
			}
		</Flex>
	);

});

export default BuildDatabaseSheetToolbar;
