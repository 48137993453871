import Flex from "Components/Flex.js";
import scss from "./Dialog.module.scss";
import {memo} from "react";
import {Button, Modal} from "semantic-ui-react";

const Dialog = memo(({
	children,
	content,
	disabled,
	headerActions,
	headerLabel,
	noScrolling,
	okButtonForm,
	okButtonType,
	onClose,
	onOk,
	open,
	size,
	submitting,
	title
}) => {

	const areControlsDisabled = (disabled || submitting);
	const hasOkButton = (onOk || okButtonForm);

	return (
		<Modal
			className={scss.root}
			closeOnDimmerClick={!areControlsDisabled}
			onClose={onClose}
			open={open}
			size={size}>
			<Modal.Header>
				<Flex
					alignItems="center"
					columnar={true}
					gap={0.5}
					justifyContent="space-between">
					<Flex
						alignItems="center"
						columnar={true}
						gap={0.5}>
						{headerLabel}
						{title}
					</Flex>
					{headerActions}
				</Flex>
			</Modal.Header>
			<Modal.Content
				scrolling={!noScrolling}>
				{(content && <Modal.Description content={content} />)}
				{children}
			</Modal.Content>
			<Modal.Actions>
				<Button
					disabled={areControlsDisabled}
					icon={(!hasOkButton ? "check" : undefined)}
					content={(!hasOkButton ? "OK" : "Cancel")}
					loading={(!hasOkButton && submitting)}
					onClick={onClose}
					primary={!hasOkButton}
					type="button" />
				{(
					hasOkButton &&
						<Button
							icon="check"
							content="OK"
							disabled={areControlsDisabled}
							loading={submitting}
							onClick={onOk}
							primary={true}
							form={okButtonForm}
							type={(okButtonType || "button")} />
				)}
			</Modal.Actions>
		</Modal>
	);

});

export default Dialog;
