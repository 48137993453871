import Contact from "./CustomerViewDetailsPaneContact.js";
import CustomerContactDialog from "./CustomerContactDialog.js";
import PaneHeader from "Components/PaneHeader.js";
import SubduedStateLabel from "Components/SubduedStateLabel.js";
import scss from "./CustomerViewContactsPane.module.scss";
import useValue from "Hooks/useValue.js";
import {memo} from "react";

const CustomerViewContactsPane = memo(({
	contacts,
	customerId,
	disabled,
	onSavedContact,
	onDeleteContact,
	onToggleOpen,
	open,
	paneRef
}) => {

	const newContactDialogOpen = useValue(false);

	return (
		<div
			className={scss.root}>
			<PaneHeader
				buttonDisabled={disabled}
				buttonIcon="add"
				buttonLabel="New Contact"
				icon={(!open ? "caret right" : "caret down")}
				iconSize="large"
				label="Contacts"
				labelSize="small"
				onButtonClick={newContactDialogOpen.setTrue}
				onLabelClick={onToggleOpen} />
			{
				open &&
					<div
						className={scss.content}
						ref={paneRef}>
						{
							contacts.map((contact, key) => (
								<Contact
									contact={contact}
									disabled={disabled}
									onDelete={onDeleteContact}
									onSaved={onSavedContact}
									key={(contact?.Id || `i${key}`)} />
							))
						}
						{(!contacts.length && <SubduedStateLabel content="No contacts." />)}
					</div>
			}
			{
				newContactDialogOpen.value &&
					<CustomerContactDialog
						customerId={customerId}
						open={newContactDialogOpen.value}
						onClose={newContactDialogOpen.setFalse}
						onSavedContact={onSavedContact} />
			}
		</div>
	);

});

export default CustomerViewContactsPane;
