import BuildItemDeletionDialog from "./BuildItemDeletionDialog.js";
import BuildItemForm from "./BuildItemForm.js";
import Dialog from "Components/Dialog.js";
import {memo, useCallback, useState} from "react";
import {Button} from "semantic-ui-react";

const BuildItemDialog = memo(({
	buildItem,
	defaultBuildCategory,
	open,
	onClose,
	onDeleted,
	onSubmitted
}) => {

	const formId = "build_item_dialog";

	const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);


	const handleDeletionDialogOpen = useCallback(() => {
		setIsDeletionDialogOpen(true);
	}, []);


	const handleDeletionDialogClose = useCallback(() => {
		setIsDeletionDialogOpen(false);
	}, []);


	return (
		<Dialog
			headerActions={(
				buildItem &&
					<Button
						basic={true}
						content="Delete"
						disabled={isSubmitting}
						negative={true}
						onClick={handleDeletionDialogOpen}
						size="tiny" />
			)}
			okButtonForm={formId}
			okButtonType="submit"
			onClose={onClose}
			open={open}
			size="small"
			submitting={isSubmitting}
			title={(buildItem?.Name || "Project Item")}>
			<BuildItemForm
				formId={formId}
				buildItem={buildItem}
				defaultBuildCategory={defaultBuildCategory}
				onSubmitted={onSubmitted}
				onSubmittingChange={setIsSubmitting} />
			<BuildItemDeletionDialog
				buildItem={buildItem}
				open={isDeletionDialogOpen}
				onClose={handleDeletionDialogClose}
				onDeleted={onDeleted} />
		</Dialog>
	);

});

export default BuildItemDialog;
