import {memo, useEffect} from "react";
import {Menu} from "semantic-ui-react";
import scss from "./ContextMenu.module.scss";

const ContextMenu = memo(({
	children,
	event,
	onClose
}) => {

	useEffect(() => {
		document.addEventListener("click", onClose);
		return () => document.removeEventListener("click", onClose);
	}, [onClose]);

	return (
		<Menu
			className={scss.root}
			compact={true}
			size="tiny"
			style={{
				top: event?.clientY,
				left: event?.clientX
			}}
			vertical={true}>
			{children}
		</Menu>
	);

});

export default ContextMenu;
