import CustomerCreationForm from "./CustomerCreationForm.js";
import Dialog from "Components/Dialog.js";
import {memo, useCallback, useState} from "react";

const CustomerCreationDialog = memo(({
	open,
	onClose,
	onCreated
}) => {

	const formId = "rsh_customer_creation_dialog_form";
	const [isSubmitting, setIsSubmitting] = useState(false);


	const handleCreated = useCallback(customer => {
		onCreated(customer);
		onClose();
	}, [onCreated, onClose]);


	return (
		<Dialog
			open={open}
			onClose={onClose}
			okButtonForm={formId}
			okButtonType="submit"
			submitting={isSubmitting}
			title="New Customer"
			size="small">
			<CustomerCreationForm
				formId={formId}
				hideControls={true}
				onCreated={handleCreated}
				onSubmitting={setIsSubmitting} />
		</Dialog>
	);

});

export default CustomerCreationDialog;
