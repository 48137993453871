import scss from "./PaddedDiv.module.scss";
import {memo} from "react";

const PaddedDiv = memo(({children}) => (
	<div className={scss.root}>
		{children}
	</div>
));

export default PaddedDiv;
