import api from "api.js";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useState} from "react";

const CustomerContactDeletionDialog = memo(({
	contactId,
	open,
	onClose,
	onDeleted
}) => {

	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleSubmit = useCallback(async () => {

		setIsSubmitting(true);

		try {

			await api({
				url: `/customers/contacts/${contactId}`,
				method: "DELETE"
			});

			onClose();
			onDeleted(contactId);

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [contactId, onClose, onDeleted]);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			onOk={handleSubmit}
			submitting={isSubmitting}
			size="mini"
			title="Delete Contact"
			content="Are you sure you want to delete this Contact?" />
	);

});

export default CustomerContactDeletionDialog;
