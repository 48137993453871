import scss from "./BuildCategoriesTabStrip.module.scss";
import {memo, useCallback, useMemo} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Dropdown, Menu} from "semantic-ui-react";

const BuildCategoriesTabStrip = memo(({
	activeProductRangeId,
	categories,
	productRanges,
	isProductRangesIndex
}) => {

	const location = useLocation();
	const navigate = useNavigate();

	const items = useMemo(() => {
		return [
			{
				content: "Overview",
				key: 0,
				uri: `/ranges/${activeProductRangeId}`,
				hidden: !activeProductRangeId
			},
			...(categories || []).map((category, key) => {
				return {
					key: (key + 1),
					content: category.Name,
					uri: `/sheets/${category.Id}`
				};
			})
		].filter(i => !i.hidden);
	}, [activeProductRangeId, categories]);

	const handleProductRangeChange = useCallback((e, data) => {
		navigate(`/build${(data.value ? `/ranges/${data.value}` : "")}`);
	}, [navigate]);

	const handleTabChange = useCallback((e, data) => {
		navigate(`/build${(data?.uri || "")}`);
	}, [navigate]);

	return (
		<div className={scss.root}>
			<Dropdown
				placeholder="Product Ranges"
				options={
					[
						{
							text: "Product Ranges",
							icon: "settings",
							onClick: handleProductRangeChange,
							value: 0,
							key: 0
						},
						...(productRanges || []).map(pr => {
							return {
								text: pr.Name,
								onClick: handleProductRangeChange,
								icon: "dot circle",
								value: pr.Id,
								key: pr.Id
							};
						})
					]
				}
				selection={true}
				value={(activeProductRangeId || 0)} />
			{
				(!isProductRangesIndex && !!items.length) &&
					<div className={scss.menuContainer}>
						<Menu
							activeIndex={
								(location.pathname !== "/build") ?
									items.indexOf(items.find(t => (`/build${(t.uri || "")}` === location.pathname))) :
									0
							}
							items={items}
							secondary={true}
							pointing={true}
							size="small"
							tabular={true}
							onItemClick={handleTabChange}>
						</Menu>
					</div>
			}
		</div>
	);

});

export default BuildCategoriesTabStrip;
