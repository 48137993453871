import "index.scss";
import "semantic-ui-less/semantic.less";
import App from "./App/App.js";
import initSentry from "./initSentry.js";
import initServiceWorker from "./initServiceWorker.js";
import moment from "moment";
import {createRoot} from "react-dom/client";

initSentry();

initServiceWorker();

moment.updateLocale("en", {week: {dow: 1}});

const reactRoot = createRoot(document.getElementById("react"));
reactRoot.render(<App />);
