import Hidden from "./Hidden.js";
import View from "Views/View.js";
import {memo} from "react";
import {Button} from "semantic-ui-react";

const ErrorFallback = memo(({withRefreshButton}) => {

	return (
		<View>
			<h1>Unhandled error</h1>
			<p>An unexpected application error has occurred.</p>
			<p>Please try what you were doing again.</p>
			<Hidden hidden={!withRefreshButton}>
				<Button content="Reload" onClick={() => window.location.reload()} />
			</Hidden>
		</View>
	);

});

export default ErrorFallback;
