import {memo} from "react";
import CorrespondenceChannel from "./CorrespondenceChannel.js";
import EnumPicker from "Components/EnumPicker.js";

const CorrespondenceChannelPicker = memo(props => {

	return (
		<EnumPicker
			enumClass={CorrespondenceChannel}
			label="Correspondence Type"
			placeholder="Correspondence Type"
			{...props} />
	);

});

export default CorrespondenceChannelPicker;
