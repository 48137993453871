import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import CustomerStatus from "./CustomerStatus.js";
import EnumChip from "Components/EnumChip.js";
import Picker from "Components/PickerAsync.js";
import {memo, useCallback, useState} from "react";

const CustomerPicker = memo(props => {

	const [searchQuery, setSearchQuery] = useState(null);


	const customers = useAsync(useCallback(() => {
		if (searchQuery) {
			return api({
				url: `/customers`,
				params: {
					Limit: 100,
					Search: searchQuery
				}
			}).then(({data}) => data);
		}
		else return null;
	}, [searchQuery]));


	return (
		<Picker
			{...props}
			getOptionChip={
				customer => (
					<EnumChip
						enumClass={CustomerStatus}
						size="tiny"
						valueCase={customer.Status} />
				)
			}
			loading={customers.loading}
			loadingError={customers.error}
			onFetchOptions={setSearchQuery}
			options={(customers.result?.Objects || [])} />
	);

});

export default CustomerPicker;
