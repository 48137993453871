import api from "api.js";
import scss from "./DepartmentStaffMemberLinksPane.module.scss";
import useAsync from "Hooks/useAsync.js";
import useValue from "Hooks/useValue.js";
import AddStaffMemberDialog from "./AddStaffMemberToDepartmentDialog.js";
import Flex from "Components/Flex.js";
import Loadable from "Components/Loadable.js";
import RemoveStaffMemberDialog from "./RemoveStaffMemberFromDepartmentDialog.js";
import SubduedStateLabel from "Components/SubduedStateLabel.js";
import {memo, useCallback, useMemo} from "react";
import {Link} from "react-router-dom";
import {Checkbox, Button, Label} from "semantic-ui-react";

const DepartmentStaffMemberLinksPane = memo(({
	departmentId,
	preventMutation
}) => {

	const addDialogOpen = useValue(false);
	const deletionTarget = useValue(null);
	const showArchivedStaff = useValue(false);


	const fetch = useAsync(useCallback(() => {
		return api({
			url: `/departments/${departmentId}/staff`
		}).then(({data}) => data);
	}, [departmentId]));


	const handleAddStaffMember = useCallback(staffMember => {
		fetch.setResult([
			...fetch.result,
			staffMember
		]);
	}, [fetch]);


	const handleRemoveStaffMember = useCallback((departmentId, staffMemberId) => {
		fetch.setResult(fetch.result.filter(({Id}) => (Id !== staffMemberId)));
	}, [fetch]);


	const staffToRender = useMemo(() => {
		return (fetch.result || []).filter(sm => {
			return (showArchivedStaff.value || !sm.Archived);
		}).sort((a, b) => {
			return a.Name.localeCompare(b.Name);
		});
	}, [fetch.result, showArchivedStaff.value]);


	return (
		<Loadable
			loading={fetch.loading}
			error={fetch.error}
			onErrorRetry={fetch.call}>
			<Flex
				gap={0.5}>
				<Flex
					alignItems="center"
					className={scss.toolbar}
					columnar={true}>
					{
						!preventMutation &&
							<Button
								className={scss.btn__add}
								content="Add to Department"
								icon="add"
								size="tiny"
								onClick={addDialogOpen.setTrue}
								primary={true} />
					}
					<Checkbox
						checked={showArchivedStaff.value}
						label="Show archived Staff Members"
						onChange={showArchivedStaff.toggleBoolean} />
				</Flex>
				{
					fetch.result?.length ?
						staffToRender.map((staff, key) => {
							return (
								<Flex
									alignItems="center"
									columnar={true}
									gap={0.25}
									key={key}>
									{
										(!preventMutation && !staff.Archived) &&
											<Button
												basic={true}
												icon="close"
												size="mini"
												negative={true}
												onClick={() => deletionTarget.set(staff)} />
									}
									{
										staff.Archived &&
											<Label
												color="orange"
												content="Archived"
												size="mini" />
									}
									<Link
										children={staff.Name}
										to={`/staff/${staff.Id}`} />
								</Flex>
							);
						}) :
						<SubduedStateLabel
							content="This Department has no members." />
				}
				{
					(!!fetch.result?.length && !staffToRender.length) &&
						<SubduedStateLabel
							content="Nothing to show." />
				}
			</Flex>
			{
				addDialogOpen.value &&
					<AddStaffMemberDialog
						open={addDialogOpen.value}
						onAdded={handleAddStaffMember}
						onClose={addDialogOpen.setFalse}
						departmentId={departmentId} />
			}
			{
				deletionTarget.value &&
					<RemoveStaffMemberDialog
						open={!!deletionTarget.value}
						onClose={deletionTarget.setNull}
						onRemoved={handleRemoveStaffMember}
						departmentId={departmentId}
						staffMemberId={deletionTarget.value?.Id} />
			}
		</Loadable>
	);

});

export default DepartmentStaffMemberLinksPane;
