import api from "api.js";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useState} from "react";

const StaffMemberAnnualLeaveDeletionDialog = memo(({
	open,
	onClose,
	onDeleted,
	period
}) => {

	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleSubmit = useCallback(async () => {

		setIsSubmitting(true);

		try {

			await api({
				url: `/staff/leave/${period.Id}`,
				method: "DELETE"
			});

			onClose();
			onDeleted();

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [period, onClose, onDeleted]);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			onOk={handleSubmit}
			title="Annual Leave"
			content="Are you sure you want to delete this leave period?"
			size="tiny"
			submitting={isSubmitting} />
	);

});

export default StaffMemberAnnualLeaveDeletionDialog;
