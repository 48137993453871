import scss from "./BuildDatabaseSheetTotals.module.scss";
import DividerRow from "./BuildDatabaseSheetDividerRow.js";
import {getDurationLabel, getDurationLabelClockFormat} from "Includes/Duration.js";
import {memo, useCallback, Fragment} from "react";
import {Table} from "semantic-ui-react";

const BuildDatabaseSheetTotals = memo(({
	sheetData,
	buildItems,
	totals,
	genericColumnsCount
}) => {

	const renderDividerRow = useCallback(() => {
		return (
			<DividerRow
				columnCount={(genericColumnsCount + (buildItems?.length || 0))} />
		);
	}, [buildItems, genericColumnsCount]);

	return (
		<Fragment>
			<Table.Row className={scss.row}>
				<Table.Cell
					className={scss.cell}
					colSpan={genericColumnsCount}
					content="Total Hours & Minutes" />
				{
					buildItems.map((i, key) => (
						<Table.Cell
							key={key}
							className={scss.cell}
							content={getDurationLabelClockFormat((totals?.[i.Id]?.DurationMinutes || 0))}
							textAlign="center" />
					))
				}
			</Table.Row>
			{renderDividerRow()}
			<Table.Row className={scss.row}>
				<Table.Cell
					className={scss.cell}
					colSpan={genericColumnsCount}
					content={`Total Working Days (${(getDurationLabel(sheetData?.Settings?.DailyWorkingMinutes))})`} />
				{
					buildItems.map((i, key) => (
						<Table.Cell
							key={key}
							className={`${scss.cell} ${scss.cell__durationWorkingDays}`}
							content={(totals?.[i.Id]?.DurationWorkingDays || 0)}
							textAlign="center" />
					))
				}
			</Table.Row>
		</Fragment>
	);

});

export default BuildDatabaseSheetTotals;
