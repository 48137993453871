import api from "api.js";
import AddressFormInputs from "Components/AddressFormInputs.js";
import ContactsPane from "./CustomerViewContactsPane.js";
import CustomerDeletionDialog from "./CustomerDeletionDialog.js";
import CustomerDiscoveryMethodPicker from "./CustomerDiscoveryMethodPicker.js";
import CustomerEnquiryMethodPicker from "./CustomerEnquiryMethodPicker.js";
import CustomerStatusPicker from "./CustomerStatusPicker.js";
import CustomerTypePicker from "./CustomerTypePicker.js";
import DetailsPanel from "Components/DetailsPanel.js";
import Flex from "Components/Flex.js";
import LabelledCheckbox from "Components/LabelledCheckbox.js";
import PaneHeader from "Components/PaneHeader.js";
import StatusChip from "./CustomerStatusChip.js";
import ToastStore from "Toasts/ToastStore.js";
import moment from "moment";
import useValue from "Hooks/useValue.js";
import {memo, useCallback, useEffect, useRef, useState} from "react";
import {Button, Divider, Form, Ref, Segment} from "semantic-ui-react";

const CustomerViewDetailsPane = memo(({
	customer,
	onUpdateCustomer
}) => {

	const contactsPaneRef = useRef();
	const headerRef = useRef();
	const rootRef = useRef();

	const deletionDialogOpen = useValue(false);
	const [contactsVisible, setContactsVisible] = useState(false);
	const [editState, setEditState] = useState({...customer});
	const [hasEdits, setHasEdits] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);


	/**
	 * Changed a Customer-related input's value.
	 */
	const handleCustomerInputChange = useCallback((value, name) => {

		setHasEdits(true);

		const updatedEditState = {
			...editState,
			[name]: value
		};

		setEditState(updatedEditState);

	}, [editState]);


	/**
	 * Changed a Customer-related input's value.
	 */
	const handleCustomerInputPropChange = useCallback((e, {value, name}) => {
		handleCustomerInputChange(value, name);
	}, [handleCustomerInputChange]);


	/**
	 * Changed a Profile-related input's value.
	 */
	const handleProfileInputChange = useCallback((value, name) => {

		setHasEdits(true);

		const updatedEditState = {
			...editState,
			Profile: {
				...editState.Profile,
				[name]: value
			}
		};

		setEditState(updatedEditState);

	}, [editState]);


	/**
	 * Changed a Profile-related input's value.
	 */
	const handleProfileInputPropChange = useCallback((e, {value, name}) => {
		handleProfileInputChange(value, name);
	}, [handleProfileInputChange]);


	/**
	 * Deleting a contact.
	 */
	const handleDeleteContact = useCallback(deletedContactId => {
		onUpdateCustomer({
			...customer,
			Contacts: (customer.Contacts || []).filter(c => (c.Id !== deletedContactId))
		});
	}, [customer, onUpdateCustomer]);


	/**
	 * Saving a new or existing contact.
	 */
	const handleSavedContact = useCallback((savedContact, existingContact) => {

		/**
		 * We can only have one Primary contact at a time
		 */
		const updatedContacts = [...(customer.Contacts || [])].map(contact => {
			return (
				!savedContact.PrimaryContact ?
					contact :
					{...contact, PrimaryContact: false}
			);
		});


		if (existingContact) {

			const existingContactIndex = updatedContacts.indexOf(updatedContacts.find(c => (c.Id === existingContact.Id)));

			if (existingContactIndex > -1) {
				updatedContacts[existingContactIndex] = savedContact;
			}

		}
		else updatedContacts.push(savedContact);


		const updatedCustomer = {
			...customer,
			Contacts: updatedContacts
		};

		onUpdateCustomer(updatedCustomer);


		/**
		 * Scroll to the botttom so the newly added contact is shown
		 */
		if (!existingContact) {
			setTimeout(() => {
				const scrollHeight = rootRef.current?.scrollHeight;
				if (scrollHeight) rootRef.current.scrollTop = scrollHeight;
			});
		}

	}, [customer, onUpdateCustomer]);


	/**
	 * Toggling whether the contacts panel is visible.
	 */
	const handleToggleContactsVisible = useCallback(() => {
		setContactsVisible(!contactsVisible);
	}, [contactsVisible]);


	/**
	 * Submitting!
	 */
	const handleSubmit = useCallback(async e => {

		e.preventDefault();
		setIsSubmitting(true);

		/**
		 * We do this now so the edit state is still preserved 
		 * if there are errors, allowing the user to retry without 
		 * having to enter all the info again.
		 */
		onUpdateCustomer({...editState});

		try {

			const updatedCustomer = await api({
				url: `/customers/${customer.Id}`,
				method: "PUT",
				data: {
					Name: editState.Name,
					/** Must massage enums to be scalars */
					Type: (editState.Type?.Name || editState.Type),
					Status: (editState.Status?.Name || editState.Status),
					EnquiryDate: (editState.Profile.EnquiryDate || null),
					EnquiryMethod: (editState.Profile.EnquiryMethod?.Name || editState.Profile.EnquiryMethod || null),
					DiscoveryMethod: (editState.Profile.DiscoveryMethod?.Name || editState.Profile.DiscoveryMethod || null),
					BrochureSent: editState.Profile.BrochureSent,
					Address: (
						Object.values((editState.Profile.Address || {})).some(v => v?.trim()) ?
							editState.Profile.Address :
							null
					),
					BillingAddress: (
						Object.values((editState.Profile.BillingAddress || {})).some(v => v?.trim()) ?
							editState.Profile.BillingAddress :
							null
					),
					Notes: (editState.Profile.Notes || null)
				}
			}).then(({data}) => data);

			onUpdateCustomer({
				...customer,
				...updatedCustomer
			});

			setHasEdits(false);

		}
		catch (e) {
			if (e?.response?.status === 409) {
				ToastStore.error("A customer with the same name already exists.");
			}
			else ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [customer, editState, onUpdateCustomer]);


	/**
	 * Reset the edit state when the rendered customer changes
	 */
	useEffect(() => {
		if (customer?.Id !== editState?.Id) {
			setEditState({...customer});
		}
	}, [customer, editState.Id]);


	/**
	 * Scroll the contacts pane into view when it's toggled
	 */
	useEffect(() => {
		if (contactsVisible) {

			const contactsPaneOffsetTop = (contactsPaneRef.current?.offsetTop || null);

			if (contactsPaneOffsetTop !== null) {
				rootRef.current?.scrollTo?.({top: (contactsPaneOffsetTop - (headerRef.current?.scrollHeight || 0))});
			}

		}
	}, [contactsVisible]);


	return (
		<Ref
			innerRef={rootRef}>
			<Segment>
				<Flex
					gap={0.5}>
					<Form
						onSubmit={handleSubmit}>
						<PaneHeader
							buttonDisabled={(!hasEdits || isSubmitting)}
							buttonIcon="check"
							buttonLabel="Save Changes"
							buttonType="submit"
							chip={<StatusChip status={customer.Status} />}
							innerRef={headerRef}
							label={customer.Name} />
						<Flex
							gap={0.5}>
							<DetailsPanel
								defaultOpen={true}
								label="Customer Details">
								<Form.Input
									disabled={isSubmitting}
									label="Customer Name"
									placeholder="Customer Name"
									maxLength={255}
									name="Name"
									onChange={handleCustomerInputPropChange}
									required={true}
									value={(editState.Name || "")} />
								<CustomerTypePicker
									disabled={isSubmitting}
									label="Customer Type"
									name="Type"
									onChange={handleCustomerInputChange}
									required={true}
									value={(editState.Type?.Name || editState.Type)} />
								<CustomerStatusPicker
									disabled={isSubmitting}
									label="Customer Status"
									name="Status"
									onChange={handleCustomerInputChange}
									required={true}
									value={(editState.Status?.Name || editState.Status)} />
							</DetailsPanel>
							<DetailsPanel
								defaultOpen={true}
								label="Enquiry Details">
								<Form.Input
									disabled={isSubmitting}
									label="Enquiry Date"
									max={(new moment()).format("YYYY-MM-DD")}
									name="EnquiryDate"
									onChange={handleProfileInputPropChange}
									type="date"
									value={(editState.Profile.EnquiryDate || "")} />
								<CustomerEnquiryMethodPicker
									clearable={true}
									disabled={isSubmitting}
									label="Enquiry Method"
									name="EnquiryMethod"
									onChange={handleProfileInputChange}
									value={(editState.Profile?.EnquiryMethod?.Name || editState.Profile?.EnquiryMethod)} />
								<CustomerDiscoveryMethodPicker
									disabled={isSubmitting}
									label="How did you hear about us?"
									name="DiscoveryMethod"
									onChange={handleProfileInputChange}
									value={(editState.Profile?.DiscoveryMethod?.Name || editState.Profile?.DiscoveryMethod)} />
								<LabelledCheckbox
									disabled={isSubmitting}
									label="Brochure Sent?"
									name="BrochureSent"
									onChange={handleProfileInputChange}
									value={editState.Profile.BrochureSent} />
							</DetailsPanel>
							<DetailsPanel
								label="Address">
								<AddressFormInputs
									disabled={isSubmitting}
									name="Address"
									onChange={handleProfileInputChange}
									values={editState.Profile.Address} />
							</DetailsPanel>
							<DetailsPanel
								label="Billing Address">
								<AddressFormInputs
									disabled={isSubmitting}
									name="BillingAddress"
									onChange={handleProfileInputChange}
									values={editState.Profile.BillingAddress} />
							</DetailsPanel>
							<DetailsPanel
								defaultOpen={true}
								label="Notes">
								<Form.TextArea
									disabled={isSubmitting}
									name="Notes"
									placeholder="Notes"
									maxLength={65535}
									onChange={handleProfileInputPropChange}
									value={(editState.Profile.Notes || "")} />
							</DetailsPanel>
							<Button
								basic={true}
								content="Delete Customer"
								color="red"
								disabled={isSubmitting}
								icon="trash alternate"
								onClick={deletionDialogOpen.setTrue}
								size="tiny"
								type="button" />
						</Flex>
					</Form>
					<Divider />
					<ContactsPane
						contacts={customer.Contacts}
						customerId={customer.Id}
						disabled={isSubmitting}
						onDeleteContact={handleDeleteContact}
						onSavedContact={handleSavedContact}
						onToggleOpen={handleToggleContactsVisible}
						open={contactsVisible}
						paneRef={contactsPaneRef} />
					{
						deletionDialogOpen &&
							<CustomerDeletionDialog
								customerId={customer?.Id}
								open={deletionDialogOpen.value}
								onClose={deletionDialogOpen.setFalse} />
					}
				</Flex>
			</Segment>
		</Ref>
	);

});

export default CustomerViewDetailsPane;
