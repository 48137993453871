import AppMenuItems from "Ui/AppMenuItems.js";
import usePermissions from "./usePermissions.js";
import {useMemo} from "react";

const useAppMenuItems = () => {

	const {hasPermission} = usePermissions();

	return useMemo(() => {
		return AppMenuItems.filter(i => {
			return (
				!i.permission ||
				hasPermission(i.permission)
			);
		});
	}, [hasPermission]);

};

export default useAppMenuItems;
