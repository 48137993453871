import BuildCategoryDialog from "BuildDatabase/Categories/BuildCategoryDialog.js";
import BuildItemTaskStepTimeInput from "./BuildItemTaskStepTimeInput.js";
import BuildTaskDialog from "BuildDatabase/Tasks/BuildTaskDialog.js";
import EmptyStateLabel from "Components/EmptyStateLabel.js";
import Hidden from "Components/Hidden.js";
import useValue from "Hooks/useValue.js";
import {sortBuildResources} from "BuildDatabase/BuildDatabaseUtils.js";
import {memo, useCallback, Fragment} from "react";
import {Table} from "semantic-ui-react";

const BuildDatabaseSheetBuildTaskRow = memo(({
	task,
	buildItems,
	sheetCategory,
	sheetData,
	onChanged,
	onChangedCategory,
	onChangeBuildItemTaskStepTimeAllowance,
	onCreatedTaskGroup,
	onDeleted
}) => {

	const {
		value: dialogOpen,
		setFalse: closeDialog,
		setTrue: openDialog
	} = useValue(false);

	const {
		value: subCategoryDialogOpen,
		setFalse: closeSubCategoryDialog,
		setTrue: openSubCategoryDialog
	} = useValue(false);


	/**
	 * We only render one layer of sub-categories for the task,
	 * as we currently assume there is only one layer of sub-categories
	 * in the system (although it's technically possible to nest to an 
	 * infinite level on the backend); therefore we want the deepest 
	 * nested category that applies to the task.
	 *
	 * (Note that we won't actually display this unless it's different 
	 * to the category we're rendering at the sheet layer; i.e., the task 
	 * must actually have a sub-category — see the render process below.)
	 * 
	 * Below, we discard Product Ranges — i.e. the `Category` should always 
	 * be an actual category, not a Product Range, so we can safely render 
	 * Product Range-level sheets.
	 * 
	 * In summary, Sub-Category is always the category defined precisely 
	 * on the task; Category is the category directly above that. But, if 
	 * Category is a Product Range, then Category becomes Sub-Category and 
	 * Sub-Category is `null` (no sub-category assigned).
	 */
	let taskSubCategory = sheetData.BuildCategories.find(c => (c.Id === task.BuildCategory));

	let taskCategory = (
		taskSubCategory?.Parent ?
			sheetData.BuildCategories.find(c => ((c.Id === taskSubCategory?.Parent?.Id))) :
			null
	);

	if (!taskCategory?.Parent) {
		taskCategory = taskSubCategory;
		taskSubCategory = null;
	}

	/**
	 * Get this build task's Build Task Steps from the sheet data
	 */
	const taskSteps = sortBuildResources(sheetData.BuildTaskSteps[task.Id]);

	/**
	 * The rows to render for this Build Task depends on the number of steps
	 */
	const rowCount = Math.max((taskSteps?.length || 0), 1);


	/**
	 * The task was changed (edited).
	 */
	const handleChanged = useCallback((updatedTask, updatedSteps) => {

		/**
		 * When only the step list has changed, we are given
		 * the task object we already have, as the form component
		 * avoids mutating it unnecessarily.
		 *
		 * This is helpful as we don't want to close the dialog in 
		 * this scenario (i.e. immediately after adding/deleting a 
		 * step on an existing task — there may be more edits to make!)
		 */
		if (updatedTask !== task) {
			closeDialog();
		}

		onChanged(updatedTask, updatedSteps, task.Id);

	}, [task, closeDialog, onChanged]);


	/**
	 * The task was deleted.
	 */
	const handleDeleted = useCallback(() => {
		closeDialog();
		onDeleted(task.Id);
	}, [task, closeDialog, onDeleted]);


	/**
	 * Render!
	 */
	return (
		<>
			{
				(new Array(rowCount)).fill(null).map((i, key) => {

					const step = taskSteps?.[key];

					return (
						<Table.Row key={key}>
							{(
								(key === 0) &&
									<Fragment>
										<Table.Cell
											content={task.BuildOrderIndex}
											rowSpan={rowCount}
											textAlign="center" />
										<Table.Cell
											content={(taskCategory?.Name || <EmptyStateLabel />)}
											rowSpan={rowCount}
											textAlign="center" />
										<Table.Cell
											content={
												taskSubCategory ?
													<a onClick={openSubCategoryDialog}>
														{taskSubCategory?.Name}
													</a> :
													<EmptyStateLabel />
											}
											rowSpan={rowCount}
											textAlign="center" />
										<Table.Cell
											content={
												<a onClick={openDialog}>
													{task.Title}
												</a>
											}
											rowSpan={rowCount} />
									</Fragment>
							)}
							<Table.Cell
								content={step?.Title} />
							{
								buildItems.map((i, key) => {

									const link = sheetData.BuildItemTaskStepLinks?.[i.Id]?.[step?.Id];

									return (
										<Table.Cell
											key={(link ? `l${link.Id}` : key)}
											textAlign="center">
											<Hidden hidden={!step}>
												<BuildItemTaskStepTimeInput
													buildItem={i}
													buildTaskStep={step}
													onChange={onChangeBuildItemTaskStepTimeAllowance}
													value={(link?.TimeAllowance || 0)} />
											</Hidden>
										</Table.Cell>
									);

								})
							}
						</Table.Row>
					);

				})
			}
			{
				dialogOpen &&
					<BuildTaskDialog
						task={task}
						targetBuildCategoryId={sheetCategory?.Id}
						open={dialogOpen}
						onClose={closeDialog}
						onCreatedCategory={onChangedCategory}
						onCreatedGroup={onCreatedTaskGroup}
						onDeleted={handleDeleted}
						onSubmitted={handleChanged} />
			}
			{
				subCategoryDialogOpen &&
					<BuildCategoryDialog
						buildCategory={taskSubCategory}
						disableParentEditing={true}
						disableDeletion={true}
						open={subCategoryDialogOpen}
						onClose={closeSubCategoryDialog}
						onSubmitted={onChangedCategory}
						parentId={sheetCategory?.Id}
						productRangeId={(sheetCategory?.Parent?.Id || sheetCategory?.Parent)} />
			}
		</>
	);

});

export default BuildDatabaseSheetBuildTaskRow;
