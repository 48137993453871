import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import DepartmentStaffMemberLinksPane from "./StaffMemberLinks/DepartmentStaffMemberLinksPane.js";
import DetailsPane from "./DepartmentDetailsPane.js";
import Panes from "Components/Panes.js";
import TabPanel from "Components/TabPanel.js";
import View from "Views/View.js";
import {useCallback} from "react";
import {useParams} from "react-router-dom";

const DepartmentView = () => {

	const Id = parseInt(useParams().Id);

	const fetch = useAsync(useCallback(() => {
		return api({
			url: `/departments/${Id}`
		}).then(({data}) => data);
	}, [Id]));

	return (
		<View
			loading={fetch.loading}
			error={fetch.error}
			onErrorRetry={fetch.call}>
			<Panes>
				<DetailsPane
					department={fetch.result}
					onChangeDepartment={fetch.setResult} />
				<TabPanel
					tabs={[
						{
							label: "Department Members",
							render: () => (
								<DepartmentStaffMemberLinksPane
									departmentId={Id}
									preventMutation={(fetch.result?.Archived !== false)} />
							)
						}
					]} />
			</Panes>
		</View>
	);

};

export default DepartmentView;
