import BuildCategoryDialog from "BuildDatabase/Categories/BuildCategoryDialog.js";
import BuildCategoryDeletionDialog from "BuildDatabase/Categories/BuildCategoryDeletionDialog.js";
import ErrorBoundary from "Components/ErrorBoundary.js";
import Flex from "Components/Flex.js";
import Loadable from "Components/Loadable.js";
import scss from "./BuildCategoriesIndex.module.scss";
import useValue from "Hooks/useValue.js";
import {memo, useCallback, useMemo, useState, Fragment} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Button, Table} from "semantic-ui-react";

const BuildCategoriesIndex = memo(({
	categories,
	error,
	loading,
	onErrorRetry,
	onCategoryChanged,
	onCategoryDeleted,
	topLevelCategoryId
}) => {

	const navigate = useNavigate();


	/**
	 * Active category instance (visible in dialogs etc.)
	 */
	const [activeBuildCategory, setActiveBuildCategory] = useState(null);


	/**
	 * Are we in Product Range mode?
	 */
	const isProductRanges = (topLevelCategoryId === null);


	/**
	 * Editor dialog state
	 */
	const {
		value: editDialogOpen,
		setFalse: closeEditDialog,
		setTrue: openEditDialog
	} = useValue(false);


	/**
	 * Deletion dialog state
	 */
	const {
		value: deleteDialogOpen,
		setFalse: closeDeleteDialog,
		setTrue: openDeleteDialog
	} = useValue(false);


	/**
	 * Get the top-level category
	 */
	const topLevelCategory = useMemo(() => {
		return (topLevelCategoryId ? categories?.find(c => (c.Id === topLevelCategoryId)) : null);
	}, [categories, topLevelCategoryId]);


	/**
	 * Get whether the top-level category has any children
	 */
	const topLevelCategoryHasChildren = useMemo(() => {
		return categories?.some(c => (c.Parent === topLevelCategoryId));
	}, [categories, topLevelCategoryId]);


	/**
	 * Deleting a category.
	 */
	const handleDeleteCategory = useCallback(category => {
		setActiveBuildCategory(category);
		openDeleteDialog();
	}, [openDeleteDialog]);


	/**
	 * Category deletion completed.
	 */
	const handleDeletedCategory = useCallback(categoryId => {

		setActiveBuildCategory(null);
		closeEditDialog();
		closeDeleteDialog();

		onCategoryDeleted(categoryId);

		if (categoryId === topLevelCategoryId) {
			navigate(`/build`);
		}

	}, [closeDeleteDialog, closeEditDialog, onCategoryDeleted, topLevelCategoryId, navigate]);


	/**
	 * Editing a category.
	 */
	const handleEditCategory = useCallback(category => {
		setActiveBuildCategory((category || null));
		openEditDialog();
	}, [openEditDialog]);


	/**
	 * Editing the top-level category.
	 */
	const handleEditTopLevelCategory = useCallback(() => {
		handleEditCategory(topLevelCategory);
	}, [handleEditCategory, topLevelCategory]);


	/**
	 * Category creation/editing completed.
	 */
	const handleEditedCategory = useCallback((category, existingCategoryId, addAnother) => {
		setActiveBuildCategory(null);
		if (!addAnother) closeEditDialog();
		onCategoryChanged(category, existingCategoryId);
	}, [closeEditDialog, onCategoryChanged]);


	/**
	 * Creating a new category.
	 *
	 * @return {void}
	 */
	const handleNewCategory = useCallback(() => {
		handleEditCategory(null);
	}, [handleEditCategory]);


	/**
	 * Blur the active document element.
	 * 
	 * Allows us to reset link styles upon click due to virtual DOM manipulation.
	 * 
	 * @return {void}
	 */
	const handleBlurActiveElement = useCallback(() => {
		document.activeElement?.blur?.();
	}, []);


	/**
	 * Render a node within the categories hierarchy tree.
	 */
	const renderCategoriesTreeNode = useCallback((categories, indentationLevel=0) => {

		const isTopLevel = (indentationLevel === 0);

		return categories?.map((category, key) => {

			if (isTopLevel && (category.Parent !== topLevelCategoryId)) {
				return null;
			}

			const children = categories.filter(c => (c.Parent === category.Id));

			return (
				<Fragment key={key}>
					<Table.Row>
						<Table.Cell>
							<Flex
								columnar={true}
								gap={0.1}>
								<Button
									icon="pencil"
									onClick={() => handleEditCategory(category)}
									size="tiny" />
								<Button
									icon="trash alternate"
									onClick={() => handleDeleteCategory(category)}
									size="tiny" />
							</Flex>
						</Table.Cell>
						<Table.Cell
							className={`${scss.cell__categoryName} ${(isTopLevel ? scss.cell__topLevel : "")}`.trim()}>
							<div
								className={scss.content}
								style={{"--indentation-level": indentationLevel}}>
								{(
									isTopLevel ?
										<Link
											children={category?.Name}
											onClick={handleBlurActiveElement}
											to={`/build/${(!isProductRanges ? "sheets" : "ranges")}/${category?.Id}`} /> :
										category?.Name
								)}
							</div>
						</Table.Cell>
					</Table.Row>
					{(!!children?.length && renderCategoriesTreeNode(children, (indentationLevel + 1)))}
				</Fragment>
			);

		});

	}, [
		handleBlurActiveElement,
		handleDeleteCategory,
		handleEditCategory,
		isProductRanges,
		topLevelCategoryId
	]);


	/**
	 * Render!
	 */
	return (
		<Flex gap={0.5}>
			<Flex
				alignItems="center"
				justifyContent="space-between"
				columnar={true}
				gap={0.25}
				wrap={true}>
				<Flex
					alignItems="center"
					columnar={true}
					gap={0.5}>
					<h3
						children={(isProductRanges ? "Product Ranges" : (topLevelCategory?.Name || "Product Categories"))}
						className={scss.heading} />
					{
						topLevelCategory &&
							<Button
								basic={true}
								disabled={loading}
								onClick={handleEditTopLevelCategory}
								icon="pencil"
								size="mini" />
					}
				</Flex>
				<Button
					basic={true}
					content={`New ${(isProductRanges ? "Product Range" : "Category")}`}
					disabled={loading}
					icon="add"
					onClick={handleNewCategory}
					primary={true}
					size="tiny" />
			</Flex>
			<ErrorBoundary>
				<Loadable
					error={error}
					empty={!topLevelCategoryHasChildren}
					emptyMessage={(isProductRanges ? "No Product Ranges have been created." : (topLevelCategory ? "No Categories have been created in this Product Range." : "Invalid Product Range ID specified."))}
					loading={loading}
					onErrorRetry={onErrorRetry}>
					<Table
						className={scss.table}
						compact="very"
						fixed={true}
						unstackable={true}>
						<Table.Header>
							<Table.Row
								className={scss.header}>
								<Table.HeaderCell
									content={null} />
								<Table.HeaderCell
									content={(isProductRanges ? "Product Range" : "Category")} />
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{renderCategoriesTreeNode(categories)}
						</Table.Body>
					</Table>
				</Loadable>
			</ErrorBoundary>
			{(
				editDialogOpen &&
					<BuildCategoryDialog
						buildCategory={activeBuildCategory}
						onClose={closeEditDialog}
						onDeleted={handleDeletedCategory}
						onSubmitted={handleEditedCategory}
						parentId={topLevelCategoryId}
						productRangeMode={(isProductRanges || (!activeBuildCategory?.Parent && (activeBuildCategory?.Id === topLevelCategoryId)))}
						open={true} />
			)}
			{(
				deleteDialogOpen &&
					<BuildCategoryDeletionDialog
						buildCategoryId={activeBuildCategory?.Id}
						onClose={closeDeleteDialog}
						onDeleted={handleDeletedCategory}
						open={true}
						productRangeMode={isProductRanges} />
			)}
		</Flex>
	);

});

export default BuildCategoriesIndex;
