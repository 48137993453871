import BuildTaskGroupDialog from "BuildDatabase/Tasks/Groups/BuildTaskGroupDialog.js";
import scss from "./BuildDatabaseSheetTaskGroupRow.module.scss";
import useValue from "Hooks/useValue.js";
import {memo, useCallback} from "react";
import {Table} from "semantic-ui-react";

const BuildDatabaseSheetBuildTaskGroupRow = memo(({
	group,
	buildItemsCount,
	genericColumnsCount,
	onChanged,
	onDeleted
}) => {

	const {
		value: dialogOpen,
		setFalse: closeDialog,
		setTrue: openDialog
	} = useValue(false);


	const handleChanged = useCallback(updated => {
		closeDialog();
		onChanged(updated, group.Id);
	}, [onChanged, group, closeDialog]);


	const handleDeleted = useCallback(() => {
		closeDialog();
		onDeleted(group.Id);
	}, [onDeleted, group, closeDialog]);


	return (
		<Table.Row>
			{(
				(genericColumnsCount > 1) &&
					<Table.Cell
						className={scss.main}
						colSpan={(genericColumnsCount - 1)} />
			)}
			<Table.Cell
				className={scss.main}>
				<a onClick={openDialog}>
					{group.Name}
				</a>
			</Table.Cell>
			{
				(new Array((buildItemsCount || 0))).fill(null).map((i, key) => (
					<Table.Cell
						content={null}
						key={key} />
				))
			}
			{(
				dialogOpen &&
					<BuildTaskGroupDialog
						taskGroup={group}
						open={true}
						onClose={closeDialog}
						onDeleted={handleDeleted}
						onSubmitted={handleChanged} />
			)}
		</Table.Row>
	);

});

export default BuildDatabaseSheetBuildTaskGroupRow;
