import scss from "./BuildSheetInput.module.scss";
import {memo} from "react";
import {Input} from "semantic-ui-react";

const BuildSheetInput = memo(props => {

	return (
		<Input
			{...props}
			className={scss.root}
			size="small"
			transparent={true} />
	);

});

export default BuildSheetInput;
