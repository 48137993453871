import scss from "./TabPanel.module.scss";
import {memo} from "react";
import {Tab} from "semantic-ui-react";

const TabPanel = memo(({tabs}) => {

	return (
		<Tab
			className={scss.root}
			menu={{
				pointing: true,
				secondary: true
			}}
			panes={
				tabs.map((tab, key) => {
					return {
						key,
						menuItem: tab.label,
						render: () => (
							<Tab.Pane
								attached={false}>
								{tab.render()}
							</Tab.Pane>
						)
					};
				})
			} />
	);

});

export default TabPanel;
