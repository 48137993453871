const State = {

	/**
	 * Auth state
	 *
	 * @type {Object}
	 */
	auth: {

		/**
		 * Auth expiry time
		 *
		 * @type {String|null} YYYY-MM-DD HH:MM:SS
		 */
		expiry: null,

		/**
		 * Auth token
		 *
		 * @type {String|null}
		 */
		token: null

	},

	/**
	 * Permission IDs associated with the authenticated user
	 * 
	 * @type {Array<String>}
	 */
	permissions: [],

	/**
	 * Target URI
	 *
	 * (When this value changes, we reroute to this URI. It allows 
	 * navigation changes to be initiated globally, from outside the 
	 * React tree.)
	 *
	 * @type {String|null}
	 */
	targetUri: null,

	/**
	 * Authenticated user's details
	 *
	 * @type {Object}
	 */
	user: {

		/**
		 * ID
		 *
		 * @type {Integer|null}
		 */
		Id: null,

		/**
		 * User's name
		 *
		 * @type {String|null}
		 */
		Name: null,

		/**
		 * Username
		 *
		 * @type {String|null}
		 */
		Username: null

	}

};

export default State;
