import api from "api.js";
import moment from "moment";
import scss from "./StaffMemberAnnualLeavePane.module.scss";
import useAsync from "Hooks/useAsync.js";
import useQuery from "Hooks/useQuery.js";
import DataTable from "Components/DataTable.js";
import Flex from "Components/Flex.js";
import Loadable from "Components/Loadable.js";
import StaffMemberAnnualLeaveDialog from "./StaffMemberAnnualLeaveDialog.js";
import StaffMemberAnnualLeaveDeletionDialog from "./StaffMemberAnnualLeaveDeletionDialog.js";
import {memo, useCallback, useMemo, useState} from "react";
import {Checkbox, Button} from "semantic-ui-react";

const StaffMemberAnnualLeavePane = memo(({
	staffMemberId,
	preventMutation
}) => {

	const [activePeriod, setActivePeriod] = useState(null);
	const [deletionDialogOpen, setDeletionDialogOpen] = useState(false);
	const [editorDialogOpen, setEditorDialogOpen] = useState(false);


	const query = useQuery(useMemo(() => ({
		StaffMember: staffMemberId,
		OnlyCurrentOrFuture: 1,
		Page: 1,
		Limit: 25,
		SortOrder: "Date",
		SortDirection: "Asc"
	}), [staffMemberId]));


	const fetch = useAsync(useCallback(() => {
		return api({
			url: `/staff/leave`,
			params: query.value
		}).then(({data}) => data);
	}, [query.value]));


	const handleDeletePeriod = useCallback(period => {
		setActivePeriod(period);
		setDeletionDialogOpen(true);
	}, []);


	const handleCloseDeletionDialog = useCallback(() => {
		setDeletionDialogOpen(false);
	}, []);


	const handleOpenEditorDialog = useCallback(() => {
		setEditorDialogOpen(true);
	}, []);


	const handleCloseEditorDialog = useCallback(() => {
		setEditorDialogOpen(false);
		setActivePeriod(null);
	}, []);


	const handleEditPeriod = useCallback(period => {
		setActivePeriod(period);
		setEditorDialogOpen(true);
	}, []);


	const handleToggleOnlyCurrentOrFuture = useCallback(() => {

		const currentValue = query.resolveBooleanValue("OnlyCurrentOrFuture");

		query.updateWithPageReset({
			OnlyCurrentOrFuture: (!currentValue ? 1 : 0),
			SortDirection: (currentValue ? "Desc" : "Asc")
		});

	}, [query]);


	const tableFields = useMemo(() => {
		return [
			{
				label: "",
				render(i) {
					return (
						<>
							<Button
								icon="pencil"
								onClick={() => handleEditPeriod(i)}
								size="tiny" />
							<Button
								icon="trash alternate"
								onClick={() => handleDeletePeriod(i)}
								size="tiny" />
						</>
					);
				},
				align: "center",
				width: "7rem",
				hidden: preventMutation
			},
			{
				id: "Date",
				label: "Date",
				render: i => [i.StartDate, i.EndDate].map(d => (new moment(d)).format("ddd DD/MM/YYYY")).join(" – "),
				sortable: true
			}
		];
	}, [handleDeletePeriod, handleEditPeriod, preventMutation]);


	return (
		<Flex
			gap={0.5}>
			<div
				className={scss.toolbar}>
				{
					!preventMutation &&
						<Button
							className={scss.btn__new}
							content="Record New"
							icon="add"
							onClick={handleOpenEditorDialog}
							primary={true}
							size="tiny" />
				}
				<Checkbox
					checked={query.resolveBooleanValue("OnlyCurrentOrFuture")}
					disabled={fetch.loading}
					label="Only show current/future leave"
					onChange={handleToggleOnlyCurrentOrFuture} />
			</div>
			<Loadable
				loading={fetch.loading}
				empty={!fetch.result?.Objects?.length}
				emptyMessage="No Annual Leave saved."
				error={fetch.error}
				onErrorRetry={fetch.call}>
				<DataTable
					data={fetch}
					fields={tableFields}
					query={query}
					sortable={true} />
			</Loadable>
			{
				deletionDialogOpen &&
					<StaffMemberAnnualLeaveDeletionDialog
						open={deletionDialogOpen}
						onClose={handleCloseDeletionDialog}
						onDeleted={fetch.call}
						period={activePeriod} />
			}
			{
				editorDialogOpen &&
					<StaffMemberAnnualLeaveDialog
						open={editorDialogOpen}
						onClose={handleCloseEditorDialog}
						onSubmitted={fetch.call}
						period={activePeriod}
						staffMemberId={staffMemberId} />
			}
		</Flex>
	);

});

export default StaffMemberAnnualLeavePane;
