import DividerRow from "./BuildDatabaseSheetDividerRow.js";
import ErrorBoundary from "Components/ErrorBoundary.js";
import ProductItem from "./BuildDatabaseSheetProductsSectionItem.js";
import {memo, Fragment} from "react";
import {Table} from "semantic-ui-react";

const BuildDatabaseSheetProductsSection = memo(({
	buildItems,
	buildProducts,
	requirements,
	totals,
	onSelectBuildItem,
	onChangeBuildItemRequirementQuantity,
	genericColumnsCount,
	workingMinsPerDay
}) => {

	return (
		<Fragment>
			<Table.Row>
				<Table.Cell colSpan={(genericColumnsCount + buildItems.length)}>
					<strong><u>Products</u></strong>
				</Table.Cell>
			</Table.Row>
			<ErrorBoundary>
				{
					buildProducts.map((buildProduct, key) => {
						return (
							<Fragment key={key}>
								<ProductItem
									onSelect={() => onSelectBuildItem(buildProduct)}
									onChangeQuantityRequirement={onChangeBuildItemRequirementQuantity}
									buildItems={buildItems}
									buildProduct={buildProduct}
									requirements={requirements}
									totals={totals}
									genericColumnsCount={genericColumnsCount}
									workingMinsPerDay={workingMinsPerDay} />
								{
									(key !== (buildProducts.length - 1)) &&
										<DividerRow
											columnCount={(genericColumnsCount + buildItems.length)} />
								}
							</Fragment>
						);
					})
				}
			</ErrorBoundary>
		</Fragment>
	);

});

export default BuildDatabaseSheetProductsSection;
