import Picker from "./Picker.js";
import debounce from "lodash.debounce";
import {memo, useCallback, useEffect, useMemo, useState} from "react";

const PickerAsync = memo(props => {

	const {
		loading,
		onFetchOptions,
		options,
		multiple,
		value
	} = props;


	const [isLoading, setIsLoading] = useState(loading);
	const [searchQuery, setSearchQuery] = useState("");


	let noResultsMessage = props.noResultsMessage;

	if (isLoading) {
		noResultsMessage = "Loading...";
	}
	else if (props.loadingError) {
		noResultsMessage = "Error.";
	}
	else if (!searchQuery) {
		noResultsMessage = "Type to search...";
	}
	else if (searchQuery && !options?.length) {
		noResultsMessage = "No results";
	}


	const handleRefetchOptions = useMemo(() => {
		return debounce(
			searchQuery => {
				onFetchOptions(searchQuery);
			},
			300
		);
	}, [onFetchOptions]);


	const handleSearchChange = useCallback((e, {searchQuery}) => {
		setIsLoading(true);
		setSearchQuery(searchQuery);
		handleRefetchOptions(searchQuery);
	}, [handleRefetchOptions]);


	useEffect(() => {
		setIsLoading(loading);
	}, [loading]);


	useEffect(() => {
		if ((value && !multiple) || (value?.length && multiple)) {
			setSearchQuery("");
		}
	}, [value, multiple]);


	return (
		<Picker
			clearable={props.clearable}
			disabled={props.disabled}
			fluid={props.fluid}
			getOptionId={props.getOptionId}
			getOptionLabel={props.getOptionLabel}
			getOptionChip={props.getOptionChip}
			getOptionDescription={props.getOptionDescription}
			getOptionDisabled={props.getOptionDisabled}
			label={props.label}
			loading={props.loading}
			multiple={multiple}
			name={props.name}
			onChange={props.onChange}
			onSearchChange={handleSearchChange}
			options={options}
			placeholder={props.placeholder}
			noResultsMessage={noResultsMessage}
			reportIds={props.reportIds}
			searchQuery={searchQuery}
			value={value} />
	);

});

export default PickerAsync;
