import Appv from "Components/Appv.js";
import ErrorBoundary from "Components/ErrorBoundary.js";
import Flex from "Components/Flex.js";
import PasswordChangeForm from "./ProfilePasswordChangeForm.js";
import TotpForm from "./ProfileTotpForm.js";
import View from "Views/View.js";
import dReset from "Dispatchers/dReset.js";
import useUser from "Hooks/useUser.js";
import {Button, Header, Segment} from "semantic-ui-react";

const ProfileView = () => {

	const user = useUser();

	return (
		<View>
			<Flex
				gap={0.5}
				margin="0 auto"
				maxWidth="36rem">
				<Segment>
					<Header
						content={user.Name}
						size="large" />
					<Button
						content="Logout"
						icon="log out"
						onClick={dReset} />
				</Segment>
				<ErrorBoundary>
					<PasswordChangeForm />
				</ErrorBoundary>
				<ErrorBoundary>
					<TotpForm />
				</ErrorBoundary>
				<Segment>
					<p><strong>App version:</strong> <Appv /></p>
					<p>Software development by <a href="https://www.heron-web.com" target="_blank" rel="noreferrer">Heron Web</a>.</p>
					<p>&copy; Red Sky Shepherd's Huts.</p>
				</Segment>
			</Flex>
		</View>
	);

};

export default ProfileView;
