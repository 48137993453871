import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import Picker from "Components/Picker.js";
import {memo, useCallback} from "react";

const DepartmentPicker = memo(props => {

	const fetch = useAsync(useCallback(() => {
		return api({
			url: `/departments`,
			params: {
				Archived: 0
			}
		}).then(({data}) => data);
	}, []));

	return (
		<Picker
			{...props}
			label={(props.label || "Department")}
			options={(fetch.result || [])}
			loading={fetch.loading}
			error={fetch.error}
			reportIds={props.reportIds} />
	);

});

export default DepartmentPicker;
