import scss from "./ProductionScheduleViewTask.module.scss";
import {getDurationLabel} from "Includes/Duration.js";
import {resolveCssColour} from "Includes/Colour.js";
import {memo, useCallback, useMemo} from "react";
import {Icon} from "semantic-ui-react";

const ProductionScheduleViewTask = memo(({task, duration, onClick}) => {

	/** We allow `task` to be either a Task or a Task Assignment */
	const assignment = (task.Task ? task : null);
	task = (task.Task || task);


	const durationLbl = useMemo(() => {
		return getDurationLabel(
			(duration !== undefined) ?
				duration :
				task?.Duration
		);
	}, [task, duration]);


	const handleClick = useCallback(() => {
		onClick?.(task);
	}, [onClick, task]);


	const handleDragStart = useCallback(e => {

		const dataTransfer = {
			Id: assignment?.Id,
			Task: task?.Id,
			ScheduleDate: assignment?.ScheduleDate,
			StaffMember: assignment?.StaffMember?.Id
		};

		/**
		 * `text/plain` is used instead of `application/json` because 
		 * it's the only way to get this working in Android Chrome :/
		 */
		e.dataTransfer.setData("text/plain", JSON.stringify(dataTransfer));
		e.dataTransfer.effectAllowed = "move";

	}, [assignment, task]);


	const isComplete = (assignment?.Complete || (task.Status.Name === "Complete"));


	return (
		<div className={scss.root}>
			<div
				className={`${scss.main} ${(isComplete ? scss.complete : "")}`.trim()}
				draggable={true}
				onClick={handleClick}
				onDragStart={handleDragStart}
				style={{
					background: resolveCssColour(task.Project?.Colour)
				}}
				tabIndex="0">
				<Icon
					className={scss.icon}
					name={(isComplete ? "check" : "clock outline")} />
				<p className={scss.label}>{task?.Title}{(durationLbl ? ` – ${durationLbl}` : null)}</p>
			</div>
			{(isComplete && <div className={scss.indicator__complete} />)}
		</div>
	);

});

export default ProductionScheduleViewTask;
