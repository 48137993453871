import Hidden from "Components/Hidden.js";
import Item from "./ProductionScheduleViewProjectBinsSidebarItem.js";
import SettingsDialog from "./ProductionScheduleViewSettingsDialog.js";
import scss from "./ProductionScheduleViewProjectBinsSidebar.module.scss";
import useValue from "Hooks/useValue.js";
import {memo, useCallback} from "react";
import {Button, Divider} from "semantic-ui-react";

const ProductionScheduleViewProjectBinsSidebar = memo(({
	className,
	config,
	projects,
	isRefreshing,
	onRefresh,
	onSelectProject,
	onUpdateConfig,
	binCount,
	staffMembers,
	staffConfig,
	onSetStaffMemberVisibility
}) => {

	const settingsDialogOpen = useValue(false);

	const handleSelectProject = useCallback(project => {
		onSelectProject(project);
	}, [onSelectProject]);

	return (
		<div className={`${scss.root} ${(className || "")}`.trim()}>
			<div className={scss.controls}>
				<Button
					basic={true}
					className={scss.control}
					disabled={isRefreshing}
					icon="refresh"
					onClick={onRefresh}
					title="Refresh Data" />
				<Button
					basic={true}
					className={scss.control}
					icon="setting"
					onClick={settingsDialogOpen.setTrue}
					title="View Settings" />
				<Hidden hidden={!projects.length}>
					<Divider
						className={scss.divider}
						fitted={true} />
				</Hidden>
			</div>
			{
				projects.map(({Project, Tasks}, key) => {
					return (
						<Item
							key={key}
							onClick={() => handleSelectProject(Project)}
							project={Project}
							taskCount={(Tasks?.length || 0)} />
					);
				})
			}
			{
				settingsDialogOpen.value &&
					<SettingsDialog
						open={settingsDialogOpen.value}
						onClose={settingsDialogOpen.setFalse}
						config={config}
						onChangeConfig={onUpdateConfig}
						binCount={binCount}
						staffMembers={staffMembers}
						staffConfig={staffConfig}
						onSetStaffMemberVisibility={onSetStaffMemberVisibility} />
			}
		</div>
	);

});

export default ProductionScheduleViewProjectBinsSidebar;
