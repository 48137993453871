import {memo, useCallback} from "react";
import {Form} from "semantic-ui-react";

const Select = memo(props => {

	const {onChange} = props;

	const handleChange = useCallback((e, {name, value}) => {
		onChange((value || null), name);
	}, [onChange]);

	return (
		<Form.Select
			{...props}
			onChange={handleChange}
			placeholder={(props.placeholder || props.label)}
			selectOnBlur={false} />
	);

});

export default Select;
