import CustomerEnquiryMethod from "./CustomerEnquiryMethod.js";
import EnumPicker from "Components/EnumPicker.js";
import {memo} from "react";

const CustomerEnquiryMethodPicker = memo(props => {

	return (
		<EnumPicker
			enumClass={CustomerEnquiryMethod}
			label="Enquiry Method"
			noIcons={true}
			{...props} />
	);

});

export default CustomerEnquiryMethodPicker;
