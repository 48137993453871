import Enum from "Includes/Enum.js";

/**
 * Customer enquiry methods
 * 
 * @package RedSky
 * @subpackage Customers
 * @author Heron Web Ltd
 * @copyright Red Sky Shepherds Huts Limited
 */
class CustomerEnquiryMethod extends Enum {

	static Unknown = 1;

	static PhoneCall = 2;

	static SquarespaceEnquiry = 3;

	static EmailEnquiry = 4;

	static TextMessage = 5;

	static WhatsApp = 6;

	static ChanceMeeting = 7;

	static BrochureRequest = 8;

	static UnscheduledVisit = 9;

	static Other = 10;


	static getLabel(name) {
		return ({
			PhoneCall: "Phone Call",
			SquarespaceEnquiry: "Squarespace Enquiry",
			EmailEnquiry: "Email Enquiry",
			TextMessage: "Text Message",
			ChanceMeeting: "Chance Meeting",
			BrochureRequest: "Brochure Request",
			UnscheduledVisit: "Unscheduled Visit"
		}[name] || name);
	}

}

export default CustomerEnquiryMethod;
