import api from "api.js";
import Input from "./BuildSheetInput.js";
import ToastStore from "Toasts/ToastStore.js";
import {getDurationLabelClockFormat} from "Includes/Duration.js";
import {memo, useCallback, useState} from "react";

const BuildItemTaskStepTimeInput = memo(({
	buildItem,
	buildTaskStep,
	onChange,
	value
}) => {

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [inputValue, setInputValue] = useState(getDurationLabelClockFormat(value));


	const handleChange = useCallback((e, {value}) => {
		setInputValue(value);
	}, []);


	const handleSubmit = useCallback(async () => {

		/** Validate the input */
		if (!inputValue.match(/^[0-9]{1,}(:([0-5]{1}[0-9]?)?)?$/)) {
			ToastStore.error("Change not saved — the time value you've entered is invalid.");
			return;
		}

		/** Get the duration components */
		let [hours, minutes] = inputValue.split(":");
		hours = (hours || "").padStart(2, "0");
		minutes = (minutes || "").padEnd(2, "0");

		/** Update the input value state */
		const resolvedInputValue = `${hours}:${minutes}`;
		if (resolvedInputValue !== inputValue) {
			setInputValue(resolvedInputValue);
		}

		/** Get the new duration in minutes */
		const totalMinutes = ((parseInt(hours) * 60) + parseFloat(minutes));

		/** Check the duration has actually changed */
		if (totalMinutes === value) {
			return;
		}

		/** We will submit now */
		setIsSubmitting(true);

		/** Make the API call */
		try {

			const buildItemId = buildItem.Id;
			const buildTaskStepId = buildTaskStep.Id;

			await api({
				url: `/build/items/tasks/steps/links`,
				method: "POST",
				data: {
					BuildItem: buildItemId,
					BuildTaskStep: buildTaskStepId,
					TimeAllowance: totalMinutes
				}
			});

			onChange(totalMinutes, buildItemId, buildTaskStepId);

		}
		catch (e) {
			ToastStore.error(e);
		}

		/** Done submitting! */
		setIsSubmitting(false);

	}, [inputValue, value, onChange, buildItem, buildTaskStep]);


	return (
		<Input
			disabled={isSubmitting}
			onBlur={handleSubmit}
			onChange={handleChange}
			value={inputValue} />
	);

});

export default BuildItemTaskStepTimeInput;
