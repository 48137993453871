import DateControls from "./ProductionScheduleViewDateControls.js";
import DateHeader from "./ProductionScheduleViewDateHeader.js";
import moment from "moment";
import pluralize from "pluralize";
import scss from "./ProductionScheduleViewHeader.module.scss";
import {resolveCssColour} from "Includes/Colour.js";
import {getWeekdaysDiff} from "Includes/Duration.js";
import {memo, useMemo} from "react";

const ProductionScheduleViewHeader = memo(({
	className,
	daysToRender,
	onChangeStartDate,
	projects
}) => {

	const startDate = daysToRender[0];

	const endDate = useMemo(() => {
		return daysToRender[(daysToRender.length - 1)];
	}, [daysToRender]);

	const projectsRenderData = useMemo(() => {

		return (projects || []).map(project => {

			const {StartDate, EndDate} = project;
			if (!StartDate || !EndDate) return null;

			const projectStartDate = new moment(StartDate);
			const projectEndDate = new moment(EndDate);

			/**
			 * We can only show the project if its production 
			 * actually falls within the rendered time period
			 */
			if ((projectStartDate > endDate) ||
				(projectEndDate < startDate)) {

				return null;
			}

			/**
			 * We now constrain the project's start/end 
			 * dates so that they do not exceed the dates 
			 * that we're rendering, in order that we can 
			 * next determine how many rendered grid 
			 * columns (i.e. days) they should span.
			 */
			const effectiveProjectStartDate = ((projectStartDate < startDate) ? startDate : projectStartDate);
			const effectiveProjectEndDate = ((projectEndDate > endDate) ? endDate : projectEndDate);
			const effectiveProjectDurationDays = (getWeekdaysDiff(effectiveProjectStartDate, effectiveProjectEndDate) + 1);

			/**
			 * The grid track layout is e.g.
			 * 
			 * (1) First Column | (2) Day 1 | (3) Day 2 | (4) Day 3 | (5) Day 4 | (6) Day 5 | (7) Right Border
			 */
			const startGridTrackIndex = (effectiveProjectStartDate.diff(startDate, "days") + 2);
			const endGridTrackIndex = (startGridTrackIndex + effectiveProjectDurationDays);

			return {
				Project: project,
				GridTrackStartIndex: startGridTrackIndex,
				GridTrackEndIndex: endGridTrackIndex
			};

		}).filter(p => p).sort((a, b) => {
			if (a.GridTrackStartIndex === b.GridTrackStartIndex) {
				if (a.GridTrackEndIndex === b.GridTrackEndIndex) return 0;
				else return ((a.GridTrackEndIndex > b.GridTrackEndIndex) ? 1 : -1);
			}
			else return ((a.GridTrackStartIndex > b.GridTrackStartIndex) ? 1 : -1);
		});

	}, [projects, startDate, endDate]);

	return (
		<header className={`${scss.header} ${(className || "")}`.trim()}>
			<DateControls
				date={startDate}
				onChange={onChangeStartDate} />
			{
				daysToRender.map((date, key) => {
					return (
						<DateHeader
							date={date}
							key={key} />
					);
				})
			}
			{
				projectsRenderData?.map(({Project, GridTrackStartIndex, GridTrackEndIndex}, key) => {
					return (
						<div
							className={scss.project}
							key={key}
							style={{
								background: resolveCssColour(Project.Colour),
								gridColumn: `${GridTrackStartIndex} / ${GridTrackEndIndex}`
							}}>
							{(Project.Name || Project.Customer?.Name)} ({Project.DurationDays} {pluralize("day", Project.DurationDays)})
						</div>
					);
				})
			}
		</header>
	);

});

export default ProductionScheduleViewHeader;
