import BuildTaskStepDeletionDialog from "./BuildTaskStepDeletionDialog.js";
import Flex from "Components/Flex.js";
import ToastStore from "Toasts/ToastStore.js";
import scss from "./BuildTaskStepsListItem.module.scss";
import useForm from "Hooks/useForm.js";
import useValue from "Hooks/useValue.js";
import {memo, useCallback} from "react";
import {Button, Form, Input} from "semantic-ui-react";

const BuildTaskStepsListItem = memo(({
	disabled,
	disableDeletion,
	onSubmit,
	onDelete,
	onDeleted,
	step,
	stepIndex
}) => {

	const isNew = !step;
	const form = useForm({...step});
	const resolvedBuildOrderIndex = parseInt((form.state.BuildOrderIndex || step?.BuildOrderIndex || stepIndex));


	const {
		value: deletionDialogOpen,
		setTrue: openDeletionDialog,
		setFalse: closeDeletionDialog
	} = useValue(false);


	const handleSubmit = useCallback(() => {

		if (isNew && !form.state.Title) {
			ToastStore.error("No step name entered.");
			return;
		}

		onSubmit({
			Title: (form.state.Title || null),
			BuildOrderIndex: resolvedBuildOrderIndex
		}, step);

		if (isNew) {
			form.reset();
		}

	}, [form, isNew, resolvedBuildOrderIndex, onSubmit, step]);


	const handleBlurBuildOrderIndex = useCallback(() => {
		if (resolvedBuildOrderIndex !== step.BuildOrderIndex) {
			handleSubmit();
		}
	}, [resolvedBuildOrderIndex, step, handleSubmit]);


	const handleBlurTitle = useCallback(() => {
		if (form.state.Title !== step.Title) {
			handleSubmit();
		}
	}, [form, step, handleSubmit]);


	const handleDelete = useCallback(() => {
		if (!step.Id) onDelete(step);
		else openDeletionDialog();
	}, [step, onDelete, openDeletionDialog]);


	return (
		<Form
			onSubmit={handleSubmit}>
			<Flex
				columnar={true}
				gap={0.5}>
				<Input
					className={scss.input__buildOrderIndex}
					min={0}
					name="BuildOrderIndex"
					onBlur={(!isNew ? handleBlurBuildOrderIndex : undefined)}
					onChange={form.updateStateFromSemanticInputChangeEvent}
					size="mini"
					readOnly={disabled}
					placeholder="Order"
					type="number"
					value={resolvedBuildOrderIndex} />
				<Input
					className={scss.input__title}
					maxLength={255}
					name="Title"
					onBlur={(!isNew ? handleBlurTitle : undefined)}
					onChange={form.updateStateFromSemanticInputChangeEvent}
					placeholder={(form.state.Title || (isNew ? "Task Step" : "(Unnamed Step)"))}
					readOnly={disabled}
					size="mini"
					value={(form.state.Title || "")} />
				{
					!isNew ?
						<Button
							disabled={(disabled || disableDeletion)}
							icon="trash alternate outline"
							onClick={handleDelete}
							size="mini"
							type="button" /> :
						<Button
							basic={true}
							className={scss.btn__add}
							content="Add"
							disabled={disabled}
							icon="add"
							primary={true}
							size="mini"
							type="submit" />
				}
			</Flex>
			{
				deletionDialogOpen &&
					<BuildTaskStepDeletionDialog
						onClose={closeDeletionDialog}
						onDeleted={onDeleted}
						open={true}
						stepId={step.Id} />
			}
		</Form>
	);

});

export default BuildTaskStepsListItem;
