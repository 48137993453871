import {useSelector} from "react-redux";

const useUser = () => {

	const user = useSelector(store => store.user);

	return user;

};

export default useUser;
