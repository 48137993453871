import {memo} from "react";
import {Label} from "semantic-ui-react";

const EnumChip = memo(({
	enumClass,
	size,
	valueCase
}) => {

	const valueCaseName = (valueCase?.Name || valueCase);

	return (
		<Label
			color={enumClass.getColour?.(valueCaseName)}
			content={enumClass.getLabel?.(valueCaseName)}
			size={size} />
	);

});

export default EnumChip;
