import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import Picker from "Components/Picker.js";
import {memo, useCallback} from "react";

const BuildItemPicker = memo(props => {

	const fetch = useAsync(useCallback(() => {
		if (props.categoryId) {
			return api({
				url: `/build/items`,
				params: {
					BuildCategory: props.categoryId,
					...props.params
				}
			}).then(({data}) => data);
		}
		else return [];
	}, [props.categoryId, props.params]));

	return (
		<Picker
			clearable={props.clearable}
			disabled={props.disabled}
			error={fetch.error}
			label={props.label}
			loading={fetch.loading}
			name={props.name}
			onChange={props.onChange}
			options={(fetch.result || [])}
			placeholder={props.placeholder}
			value={props.value} />
	);

});

export default BuildItemPicker;
