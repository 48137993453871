import api from "api.js";
import moment from "moment";
import scss from "./YearPlannerView.module.scss";
import useAsync from "Hooks/useAsync.js";
import Calendar from "./YearPlannerCalendar.js";
import Loadable from "Components/Loadable.js";
import ProjectStatus from "Projects/ProjectStatus.js";
import ProjectsSidebarItem from "./YearPlannerProjectsSidebarItem.js";
import SubduedStateLabel from "Components/SubduedStateLabel.js";
import View from "Views/View.js";
import {useCallback, useMemo, useState, Fragment} from "react";
import {Checkbox, Divider, Header} from "semantic-ui-react";

const YearPlannerView = () => {

	const defaultTargetYear = useMemo(() => {
		return parseInt((new moment()).format("YYYY"));
	}, []);


	const [showCompleted, setShowCompleted] = useState(false);
	const [targetYear, setTargetYear] = useState(defaultTargetYear);


	const fetch = useAsync(useCallback(() => {
		return api({
			url: `/production/schedule/years/${targetYear}`
		}).then(({data}) => data);
	}, [targetYear]));


	const handleToggleShowCompleted = useCallback(() => {
		setShowCompleted(!showCompleted);
	}, [showCompleted]);


	const projectsToRender = useMemo(() => {
		if (fetch.result && !showCompleted) {
			return fetch.result.Projects.filter(p => (p.Status.Id !== ProjectStatus.Complete));
		}
		else return fetch.result?.Projects;
	}, [fetch.result, showCompleted]);


	return (
		<View>
			<div
				className={scss.root}>
				<div
					className={scss.projectsSidebar}>
					<header>
						<div>
							<Header
								content="Active Projects"
								className={scss.label}
								sub={true}
								size="huge" />
							<Checkbox
								checked={showCompleted}
								label="Show Completed?"
								onChange={handleToggleShowCompleted}
								size="small" />
						</div>
						<Divider />
					</header>
					<Loadable
						error={fetch.error}
						empty={!projectsToRender?.length}
						emptyMessage={`No Projects scheduled in ${targetYear}.`}
						loading={(fetch.loading && !fetch.result)}
						onErrorRetry={fetch.call}>
						{
							projectsToRender?.map((project, key) => {
								return (
									<Fragment
										key={key}>
										<ProjectsSidebarItem
											project={project}
											onScheduleChange={fetch.call} />
										<Divider
											className={scss.divider} />
									</Fragment>
								);
							})
						}
						<SubduedStateLabel
							content="Reschedule Projects by dragging them. When a Project is dropped, it will acquire the start date of the Project it's dropped onto. Active Projects that then overlap the dropped Project will be rescheduled after it."
							style={{fontSize: "0.85em", marginTop: "1rem"}} />
						<SubduedStateLabel
							content="Note: Projects are always scheduled based on the duration of their currently associated Tasks. Projects that have had Tasks added or removed since they were last scheduled may change duration when rescheduled."
							style={{fontSize: "0.85em", marginTop: "1rem"}} />
					</Loadable>
				</div>
				<div
					className={scss.calendar}>
					<Calendar
						projects={(fetch.result?.Projects || [])}
						holidays={(fetch.result?.BankHolidays || [])}
						onChangeTargetYear={setTargetYear}
						targetYear={targetYear} />
				</div>
			</div>
		</View>
	);

};

export default YearPlannerView;
