import {memo} from "react";
import {Icon} from "semantic-ui-react";

const BooleanStateIcon = memo(({
	value,
	useDashAsFalse
}) => {

	if (!value && useDashAsFalse) {
		return "—";
	}

	return (
		<Icon
			color={(value ? "green" : "red")}
			name={(value ? "check" : "close")} />
	);

});

export default BooleanStateIcon;
