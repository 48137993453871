import Enum from "Includes/Enum.js";

/**
 * Customer type enum
 *
 * @package RedSky
 * @subpackage Customers
 * @author Heron Web Ltd
 * @copyright Red Sky Shepherds Huts Limited
 */
class CustomerType extends Enum {

	static Individual = 1;

	static Business = 2;

	static Organisation = 3;

	static Other = 4;

	static getLabel(name) {
		return ({
			Individual: "Individual / End User"
		}[name] || name);
	}

	static getIcon(name) {
		return {
			Individual: "user",
			Business: "building",
			Organisation: "object group outline",
			Other: "ellipsis horizontal"
		}[name];
	}

}

export default CustomerType;
