import api from "api.js";
import dAuth from "Dispatchers/dAuth.js";
import scss from "./LoginView.module.scss";
import Appv from "Components/Appv.js";
import ErrorMessages from "./LoginViewErrorMessages.js";
import Flex from "Components/Flex.js";
import {useCallback, useEffect, useRef, useState} from "react";
import {Button, Form, Label, Message, Ref, Segment} from "semantic-ui-react";

const LoginView = () => {

	const [state, setState] = useState({
		Username: null,
		Password: null,
		Totp: null
	});

	const usernameInputRef = useRef();
	const passwordInputRef = useRef();

	const [error, setError] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isTotp, setIsTotp] = useState(false);


	const handleCancelTotp = useCallback(() => {

		setState({
			Username: null,
			Password: null,
			Totp: null
		});

		setError(null);
		setIsTotp(false);
		document.activeElement?.blur?.();

	}, []);


	const handleInputChange = useCallback((e, {name, value}) => {

		setError(null);

		setState({
			...state,
			[name]: value
		});

	}, [state]);


	const handleSubmit = useCallback(async e => {

		e.preventDefault();

		setIsSubmitting(true);

		try {

			const result = await api({
				url: `/auth`,
				method: "POST",
				data: {...state}
			});

			dAuth(result.data);

		}
		catch (e) {

			const isTotpInvalid = (e?.response?.data?.["auth.reason"] === "TotpInvalid");

			if (isTotpInvalid && !isTotp) {
				setIsTotp(true);
			}
			else setError(e);

		}

		setIsSubmitting(false);

	}, [state, isTotp]);


	useEffect(() => {
		if (!isSubmitting) {
			if (error?.response?.status === 404) {
				usernameInputRef?.current?.querySelector("input").select();
			}
			if (error?.response?.status === 401) {
				passwordInputRef?.current?.querySelector("input").select();
			}
		}
	}, [error, isSubmitting, usernameInputRef, passwordInputRef]);


	return (
		<Flex
			className={scss.container}
			gap={2}>
			<Segment
				className={scss.root}>
				<Form
					error={!!error}
					onSubmit={handleSubmit}>
					<Flex>
						<img
							alt="Red Sky Shepherd's Huts"
							className={scss.icon}
							src="/logo.png" />
						<Label
							content="Login to your account"
							size="large" />
						<Flex
							gap={0.5}>
							{
								!isTotp &&
									<Ref
										innerRef={usernameInputRef}>
										<Form.Input
											autoFocus={true}
											className={scss.input}
											error={(error?.response?.status === 404)}
											icon="user"
											iconPosition="left"
											name="Username"
											onChange={handleInputChange}
											placeholder="Username"
											readOnly={isSubmitting}
											required={true}
											value={(state.Username || "")} />
									</Ref>
							}
							{
								isTotp &&
									<Message
										header="Two-Factor Authentication"
										content="Please enter the code shown in your authenticator app to confirm your login." />
							}
							<Ref
								innerRef={passwordInputRef}>
								<Form.Input
									autoComplete={(isTotp ? "off" : undefined)}
									autoFocus={isTotp}
									className={scss.input}
									error={(error?.response?.status === 401)}
									icon={(!isTotp ? "lock" : "key")}
									iconPosition="left"
									inputMode={(isTotp ? "numeric" : undefined)}
									key={(isTotp ? "totp" : "password")}
									onChange={handleInputChange}
									maxLength={(isTotp ? 6 : undefined)}
									minLength={(isTotp ? 6 : undefined)}
									name={(!isTotp ? "Password" : "Totp")}
									placeholder={(!isTotp ? "Password" : "123456")}
									type={(!isTotp ? "password" : "text")}
									readOnly={isSubmitting}
									required={true}
									value={((!isTotp ? state.Password : state.Totp) || "")} />
							</Ref>
						</Flex>
						<Message
							className={scss.message}
							content={(ErrorMessages[error?.response?.status]?.[error?.response?.data?.["auth.reason"]] || ErrorMessages[error?.response?.status] || ErrorMessages.default)}
							error={true} />
						<Flex
							gap={0.5}>
							<Button
								className={scss.button}
								content={(!isTotp ? "Login" : "Confirm")}
								disabled={isSubmitting}
								loading={isSubmitting}
								primary={true}
								type="submit" />
							{
								isTotp &&
									<Button
										basic={true}
										content="Cancel"
										disabled={isSubmitting}
										onClick={handleCancelTotp}
										type="button" />
							}
						</Flex>
					</Flex>
				</Form>
			</Segment>
			<Appv />
		</Flex>
	);

};

export default LoginView;
