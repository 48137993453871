import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import Picker from "Components/Picker.js";
import {memo, useCallback} from "react";

const CustomerContactPicker = memo(props => {

	const {customerId} = props;

	const fetch = useAsync(useCallback(() => {
		return api({
			url: `/customers/${customerId}/contacts`
		}).then(({data}) => data);
	}, [customerId]));

	return (
		<Picker
			{...props}
			getOptionLabel={contact => contact.ContactName}
			getOptionDescription={contact => contact.ContactDescription}
			label={(props.label || "Customer Contact")}
			options={(fetch.result || [])}
			loading={fetch.loading}
			error={fetch.error} />
	);

});

export default CustomerContactPicker;
