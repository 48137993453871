import InlineInput from "Components/InlineInput.js";
import scss from "./AddressFormInputs.module.scss";
import {memo, useCallback} from "react";

const AddressFormInputs = memo(({
	disabled,
	name,
	onChange,
	readOnly,
	values
}) => {

	const handleChange = useCallback((e, {value: v, name: n}) => {
		const updatedValues = {...values};
		updatedValues[n] = (v || null);
		onChange(updatedValues, name);
	}, [values, onChange, name]);

	return (
		<div className={scss.root}>
			<InlineInput
				disabled={disabled}
				label="Building"
				maxLength={255}
				name="Building"
				onChange={handleChange}
				placeholder="Building"
				readOnly={readOnly}
				size="small"
				value={(values?.Building || "")} />
			<InlineInput
				disabled={disabled}
				label="Street"
				maxLength={255}
				name="Street"
				onChange={handleChange}
				placeholder="Street"
				readOnly={readOnly}
				size="small"
				value={(values?.Street || "")} />
			<InlineInput
				disabled={disabled}
				label="City"
				maxLength={255}
				name="City"
				onChange={handleChange}
				placeholder="City"
				readOnly={readOnly}
				size="small"
				value={(values?.City || "")} />
			<InlineInput
				disabled={disabled}
				label="Region"
				maxLength={255}
				name="Region"
				onChange={handleChange}
				placeholder="Region"
				readOnly={readOnly}
				size="small"
				value={(values?.Region || "")} />
			<InlineInput
				disabled={disabled}
				label="Country"
				maxLength={255}
				name="Country"
				onChange={handleChange}
				placeholder="Country"
				readOnly={readOnly}
				size="small"
				value={(values?.Country || "")} />
			<InlineInput
				disabled={disabled}
				label="Postcode"
				maxLength={10}
				name="Postcode"
				onChange={handleChange}
				pattern="^[A-Z]{1,2}[0-9][A-Z0-9]? [0-9][A-Z]{2}$"
				placeholder="Postcode"
				readOnly={readOnly}
				size="small"
				value={(values?.Postcode || "")} />
		</div>
	);

});

export default AddressFormInputs;
