import moment from "moment";
import pluralize from "pluralize";
import scss from "./YearPlannerCalendar.module.scss";
import {resolveCssColour} from "Includes/Colour.js";
import {memo, useCallback, useMemo} from "react";
import {Link} from "react-router-dom";
import {Icon} from "semantic-ui-react";

const YearPlannerCalendar = memo(({
	projects,
	holidays,
	onChangeTargetYear,
	targetYear
}) => {

	/**
	 * Row height (rem)
	 */
	const rowHeight = 3;

	/**
	 * Row height multiplier to apply to Project spans
	 */
	const rowHeightProjectSpanMultiplier = 0.6;

	/**
	 * First date in view
	 */
	const viewStartDate = useMemo(() => {
		return new moment(`${targetYear}-01-01`);
	}, [targetYear]);

	/**
	 * Last date in view
	 */
	const viewEndDate = useMemo(() => {
		return new moment(`${targetYear}-12-31`);
	}, [targetYear]);


	/**
	 * Months to render
	 */
	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December"
	];

	/**
	 * Weekdays reference
	 */
	const weekdays = [
		"Mon",
		"Tue",
		"Wed",
		"Thu",
		"Fri",
		"Sat",
		"Sun"
	];

	/**
	 * Days we're rendering across the calendar
	 * 
	 * We have five weeks of Mon-Sun, plus an extra two days (Mon/Tue) 
	 * in the sixth week — as a 31-day month starting on a Sunday will 
	 * span six calendar weeks, with the 31st being on the fifth Tuesday 
	 * following the start of the month.
	 */
	const daysToRender = (new Array(((5 * 7) + 2))).fill(null);


	/**
	 * Get whether a date is a valid date to render a project span across.
	 * 
	 * @param {?Moment} date
	 * @return {Boolean}
	 */
	const isValidProjectDate = useCallback(date => {

		const dateYmd = date.format("YYYY-MM-DD");

		return (
			!date || (
				(date?.isoWeekday() <= 5) &&
				!holidays.find(i => (i.Date === dateYmd))
			)
		);

	}, [holidays]);


	/**
	 * Compute the Project spans to render
	 */
	const projectSpans = useMemo(() => {

		const spans = [];

		for (const project of projects) {

			const periods = [];

			const startDate = project.ProductionStartDate;
			const endDate = project.ProductionEndDate;
			if (!startDate || !endDate) continue;

			const startDateMoment = new moment(startDate);
			const endDateMoment = new moment(endDate);

			if ((endDateMoment < startDateMoment) ||
				(endDateMoment < viewStartDate) ||
				(startDateMoment > viewEndDate)) {

				continue;
			}

			let viewEffectiveStartDateMoment = startDateMoment;
			let viewEffectiveEndDateMoment = endDateMoment;

			if (viewEffectiveStartDateMoment < viewStartDate) {
				viewEffectiveStartDateMoment = viewStartDate.clone();
			}

			if (viewEffectiveEndDateMoment > viewEndDate) {
				viewEffectiveEndDateMoment = viewEndDate.clone();
			}

			let currentDate = viewEffectiveStartDateMoment;
			let prevDate = currentDate;

			const targetEndDateYmd = viewEffectiveEndDateMoment.format("YYYY-MM-DD");
			const breakDateYmd = viewEffectiveEndDateMoment.clone().add(1, "day").format("YYYY-MM-DD");

			while (currentDate.format("YYYY-MM-DD") !== breakDateYmd) {

				const currentPeriod = periods[0];

				const dayIndex = parseInt(currentDate.format("D"));
				const monthIndex = parseInt(currentDate.format("M"));

				const currentDateYmd = currentDate.format("YYYY-MM-DD");
				const isTargetDate = (currentDateYmd === targetEndDateYmd);

				const isValidDate = isValidProjectDate(currentDate);
				const isValidPrevDate = isValidProjectDate(prevDate);

				if (currentPeriod && !currentPeriod?.EndDay) {
					if ((!isValidDate || (monthIndex !== parseInt(prevDate.format("M")))) && isValidPrevDate) {
						currentPeriod.EndDay = parseInt(prevDate.format("D"));
					}
					else if (isTargetDate) {
						currentPeriod.EndDay = dayIndex;
					}
				}

				if (isValidDate && (!currentPeriod || currentPeriod.EndDay)) {

					const nextPeriod = {
						Month: monthIndex,
						StartDay: dayIndex,
						EndDay: (isTargetDate ? dayIndex : null)
					};

					if ((nextPeriod.Month !== currentPeriod?.Month) ||
						(nextPeriod.EndDay !== currentPeriod?.EndDay)) {

						periods.unshift(nextPeriod);
					}

				}

				prevDate = currentDate.clone();
				currentDate = currentDate.clone().add(1, "day");

			}

			for (const period of periods) {

				const firstDow = new moment(`${targetYear}-${period.Month.toString().padStart(2, "0")}-01`).isoWeekday();

				const conflicts = spans.filter(span => {
					return (
						(span.Month === period.Month) &&
						(span.StartDay <= period.StartDay) &&
						(span.EndDay >= period.EndDay)
					);
				});

				const span = {
					Project: project,
					ProjectStartDate: startDateMoment,
					ProjectEndDate: endDateMoment,
					Month: period.Month,
					StartDay: period.StartDay,
					EndDay: period.EndDay,
					Row: (period.Month + 1),
					RowLine: conflicts.length,
					ColStart: ((period.StartDay + (firstDow - 1)) + 1),
					ColEnd: ((period.EndDay + (firstDow - 1)) + 2)
				};

				spans.push(span);

			}

			const projectSpans = Object.groupBy(spans.filter(i => (i.Project === project)), i => i.Month);

			for (const monthlyProjectSpans of Object.values(projectSpans)) {

				const maxRowLine = Math.max(...monthlyProjectSpans.map(i => i.RowLine));

				monthlyProjectSpans.forEach(i => {
					i.RowLine = maxRowLine;
				});

			}

		}

		return spans;

	}, [
		projects,
		isValidProjectDate,
		targetYear,
		viewStartDate,
		viewEndDate
	]);


	/**
	 * Render!
	 */
	return (
		<div
			className={scss.calendar}
			style={{
				"--rsh-year-planner-calendar-row-height": `${rowHeight}rem`,
				"--rsh-year-planner-calendar-row-height-project-span-multiplier": rowHeightProjectSpanMultiplier,
				"--rsh-year-planner-calendar-col-count": daysToRender.length
			}}>
			<div
				className={`${scss.header__day} ${scss.header__date} ${scss.header__year}`}>
				<Icon
					name="arrow left"
					onClick={() => onChangeTargetYear((targetYear - 1))} />
				<span>{targetYear}</span>
				<Icon
					name="arrow right"
					onClick={() => onChangeTargetYear((targetYear + 1))} />
			</div>
			{
				daysToRender.map((i, key) => {

					return (
						<div
							className={`${scss.header__day} ${((key === (daysToRender.length - 1)) ? scss.last : "")}`.trim()}
							key={key}>
							{weekdays[(key % 7)]}
						</div>
					);

				})
			}
			{
				months.map((month, key) => {
					return (
						<div
							className={`${scss.lbl__month} ${((key === (months.length - 1)) ? scss.last : "")}`.trim()}
							key={key}
							style={{
								gridColumn: 1,
								gridRow: (key + 2)
							}}>
							{month}
						</div>
					);
				})
			}
			{
				projectSpans.map((span, key) => {
					return (
						<Link
							className={scss.project}
							key={key}
							style={{
								background: resolveCssColour(span.Project.Colour),
								gridColumn: `${span.ColStart} / ${span.ColEnd}`,
								gridRow: span.Row,
								/** The 1.2rem is the space occupied by the date label */
								marginTop: `${((span.RowLine * (rowHeightProjectSpanMultiplier * rowHeight)) + 1.2)}rem`
							}}
							title={`${(span.Project.Name || span.Project.Customer?.Name)}: ${span.ProjectStartDate.format("DD/MM/YYYY")} – ${span.ProjectEndDate.format("DD/MM/YYYY")} (${span.Project.DurationDays} ${pluralize("day", span.Project.DurationDays)})`}
							to={`/projects/${span.Project.Id}`}>
							<span><strong>{(span.Project.Name || span.Project.Customer?.Name)}</strong> — {span.Project.DurationDays} {pluralize("day", span.Project.DurationDays)}</span>
						</Link>
					);
				})
			}
			{
				months.map((m, month) => {

					let currentDay = 0;
					let hasReachedFirstWeekday = false;

					const monthMm = (month + 1).toString().padStart(2, "0");
					const firstDom = new moment(`${targetYear}-${monthMm}-01`);

					return daysToRender.map((i, day) => {

						let currentDate = null;
						let currentDateYmd = null;

						hasReachedFirstWeekday = (hasReachedFirstWeekday || ((day + 1) >= firstDom.isoWeekday()));

						if (hasReachedFirstWeekday) {
							currentDay++;
							currentDate = new moment(`${targetYear}-${monthMm}-${currentDay.toString().padStart(2, "0")}`);
							currentDateYmd = currentDate.format("YYYY-MM-DD");
						}

						const isValid = currentDate?.isValid();
						const isWeekend = (currentDate?.isoWeekday() >= 6);
						const holiday = (currentDate ? holidays.find(i => (i.Date === currentDateYmd)) : undefined);

						return (
							<div
								className={scss.day}
								key={`${month}-${day}`}
								style={{
									background: (
										!isValid ?
											"#eee" :
											(
												holiday ?
													"#8db9ca" :
													(
														isWeekend ?
															"#a4dbe8" :
															undefined
													)
											)
									),
									gridColumn: (day + 2),
									gridRow: (month + 2)
								}}
								title={holiday?.Name}>
								{
									isValid ?
										<span className={scss.dateLabel}>
											{currentDate?.format("D")}
										</span> :
										null
								}
							</div>
						);

					});

				})
			}
		</div>
	);

});

export default YearPlannerCalendar;
