import Flex from "./Flex.js";
import {memo, useCallback} from "react";
import {Checkbox} from "semantic-ui-react";

const NullableBooleanCheckboxSet = memo(({
	disabled,
	labelTrue,
	labelFalse,
	name,
	onChange,
	reportIntegers,
	value
}) => {

	const isNull = ((value === null) || (value === undefined));
	const isTrue = ((value === true) || (parseInt(value) === 1));
	const isFalse = ((value === false) || (parseInt(value) === 0));


	const handleChange = useCallback(newValue => {

		let reportValue = newValue;

		if (reportIntegers && (reportValue !== null)) {
			reportValue = (reportValue ? 1 : 0);
		}

		onChange(reportValue, name);

	}, [onChange, name, reportIntegers]);


	return (
		<Flex
			gap={0.5}>
			<div>
				<Checkbox
					checked={(isTrue || isNull)}
					disabled={(disabled || isTrue)}
					onChange={() => handleChange((!isNull ? null : false))}
					label={labelTrue} />
			</div>
			<div>
				<Checkbox
					checked={(isFalse || isNull)}
					disabled={(disabled || isFalse)}
					onChange={() => handleChange((!isNull ? null : true))}
					label={labelFalse} />
			</div>
		</Flex>
	);

});

export default NullableBooleanCheckboxSet;
