import View from "Views/View.js";
import {getPermissionUiName} from "Includes/Permission.js";
import {Link} from "react-router-dom";

const AccessDeniedView = ({permission}) => {

	return (
		<View>
			<h1>Access Denied</h1>
			<p>The {getPermissionUiName(permission)} permission is required to access this screen.</p>
			<Link to="/">Back to Home</Link>
		</View>
	);

};

export default AccessDeniedView;
