import QuantityInput from "./BuildItemRequirementQuantityInput.js";
import scss from "./BuildDatabaseSheetProductsSectionItem.module.scss";
import {getDurationLabel, getDurationLabelClockFormat} from "Includes/Duration.js";
import {memo, useCallback, useMemo, Fragment} from "react";
import {Table} from "semantic-ui-react";

const BuildDatabaseSheetProductsSectionItem = memo(({
	buildItems,
	buildProduct,
	requirements,
	totals,
	onSelect,
	onChangeQuantityRequirement,
	genericColumnsCount,
	workingMinsPerDay
}) => {

	const getBuildItemDuration = useCallback(buildItem => {
		const requirement = requirements?.[buildProduct.Id]?.[buildItem.Id];
		return ((totals?.[buildItem.Id]?.DurationMinutes || 0) * (requirement?.QuantityRequired || 0));
	}, [totals, requirements, buildProduct]);

	const totalDuration = useMemo(() => {
		return buildItems.reduce((a, b) => {
			return (a + getBuildItemDuration(b));
		}, 0);
	}, [getBuildItemDuration, buildItems]);

	return (
		<Fragment>
			<Table.Row>
				<Table.Cell
					className={scss.headerCell}
					content={buildProduct.BuildOrderIndex}
					textAlign="center" />
				<Table.Cell
					className={scss.headerCell}
					colSpan={(genericColumnsCount - 1)}>
					<strong>
						<a
							children={buildProduct.Name}
							onClick={onSelect} />
					</strong>
				</Table.Cell>
				{
					buildItems.map((buildItem, key) => {
						return (
							<Table.Cell
								key={key} />
						);
					})
				}
			</Table.Row>
			<Table.Row>
				<Table.Cell />
				<Table.Cell
					content="Quantity"
					colSpan={(genericColumnsCount - 1)} />
				{
					buildItems.map((buildItem, key) => {

						const requirement = requirements?.[buildProduct.Id]?.[buildItem.Id];

						return (
							<Table.Cell
								key={key}
								textAlign="center">
								<QuantityInput
									buildItem={buildProduct}
									requiredBuildItem={buildItem}
									onChange={onChangeQuantityRequirement}
									value={(requirement?.QuantityRequired || 0)} />
							</Table.Cell>
						);
					})

				}
			</Table.Row>
			<Table.Row>
				<Table.Cell />
				<Table.Cell
					content="Sub-Total"
					colSpan={(genericColumnsCount - 1)} />
				{
					buildItems.map((buildItem, key) => {
						return (
							<Table.Cell
								key={key}
								content={getDurationLabelClockFormat(getBuildItemDuration(buildItem))}
								textAlign="center" />
						);
					})
				}
			</Table.Row>
			<Table.Row>
				<Table.Cell />
				<Table.Cell
					content="Total"
					className={scss.cell__total}
					colSpan={(genericColumnsCount - 1)} />
				{
					buildItems.length &&
						<>
							<Table.Cell
								className={scss.cell__total}
								content={getDurationLabelClockFormat(totalDuration)}
								textAlign="center" />
							{
								new Array((buildItems.length - 1)).fill(null).map((i, key) => (
									<Table.Cell
										key={key} />
								))
							}
						</>
				}
			</Table.Row>
			<Table.Row>
				<Table.Cell />
				<Table.Cell
					className={scss.cell__total}
					content={`Total Working Days (${getDurationLabel(workingMinsPerDay)})`}
					colSpan={(genericColumnsCount - 1)} />
				{
					buildItems.length &&
						<>
							<Table.Cell
								className={scss.cell__totalDays}
								content={(totalDuration / workingMinsPerDay).toFixed(2)}
								textAlign="center" />
							{
								new Array((buildItems.length - 1)).fill(null).map((i, key) => (
									<Table.Cell
										key={key} />
								))
							}
						</>
				}
			</Table.Row>
		</Fragment>
	);

});

export default BuildDatabaseSheetProductsSectionItem;
