import api from "api.js";
import Flex from "Components/Flex.js";
import TaskStepForm from "./TaskStepForm.js";
import ToastStore from "Toasts/ToastStore.js";
import pluralize from "pluralize";
import scss from "./TaskForm.module.scss";
import useForm from "Hooks/useForm.js";
import {getDurationLabelClockFormat} from "Includes/Duration.js";
import {memo, useCallback, useMemo, useState} from "react";
import {Button, Form, Header} from "semantic-ui-react";

const TaskForm = memo(({
	task,
	formId,
	projectId,
	onSubmitted,
	onSubmittingChange
}) => {

	const form = useForm(
		useMemo(() => {

			const duration = getDurationLabelClockFormat((task?.Duration || 0)).split(":");

			return {
				Title: (task?.Title || ""),
				DurationHrs: (parseInt(duration?.[0]) || 0),
				DurationMins: (parseInt(duration?.[1]) || 0)
			};

		}, [task])
	);

	const defaultStep = useMemo(() => {
		return {
			Title: null
		};
	}, []);

	const [steps, setSteps] = useState((task?.Steps?.length ? task.Steps : [{...defaultStep}]));
	const [stepsAutofocusKey, setStepsAutofocusKey] = useState(0);

	const [isSubmitting, setIsSubmitting] = useState(false);


	const handleAddStep = useCallback(() => {
		setStepsAutofocusKey((stepsAutofocusKey + 1));
		setSteps([...steps, {...defaultStep}]);
	}, [steps, defaultStep, stepsAutofocusKey]);


	const handleChangeStep = useCallback((step, updatedProps) => {

		const stepIndex = steps.indexOf(step);

		if (stepIndex > -1) {
			const updatedSteps = [...steps];
			updatedSteps[stepIndex] = {...updatedSteps[stepIndex], ...updatedProps};
			setSteps(updatedSteps);
		}

	}, [steps]);


	const handleDeleteStep = useCallback(step => {

		const updatedSteps = steps.filter(s => (s !== step));

		if ((stepsAutofocusKey === 0) || !updatedSteps.length) {
			setStepsAutofocusKey((stepsAutofocusKey + 1));
		}

		if (!updatedSteps.length) {
			updatedSteps.push({...defaultStep});
		}

		setSteps(updatedSteps);

	}, [steps, defaultStep, stepsAutofocusKey]);


	const handleReorderStep = useCallback((step, diff) => {

		const updatedSteps = [...steps];
		const currentIndex = updatedSteps.indexOf(step);
		const newIndex = (currentIndex + diff);

		if ((newIndex < 0) ||
			(newIndex >= updatedSteps.length) ||
			(newIndex === currentIndex)) {

			return;
		}

		const currentAtIndex = updatedSteps[newIndex];
		updatedSteps[newIndex] = step;
		updatedSteps[currentIndex] = currentAtIndex;

		setSteps(updatedSteps);

	}, [steps]);


	const resolveCurrentDurationInput = useCallback(() => {
		const hrs = (parseInt(form.state.DurationHrs) || 0);
		const mins = (parseInt(form.state.DurationMins) || 0);
		return ((hrs * 60) + mins);
	}, [form.state]);


	const handleSubmitting = useCallback(submitting => {
		setIsSubmitting(submitting);
		onSubmittingChange(submitting);
	}, [onSubmittingChange]);


	const handleSubmit = useCallback(async e => {

		e.preventDefault();

		handleSubmitting(true);

		try {

			const stepsData = steps.filter(step => !!(step.Id || step.Title?.trim())).map((step, Index) => {

				const stepData = {
					...step,
					Index
				};

				/**
				 * Step ID required when updating an existing Task
				 * 
				 * (`null` = create as new Step)
				 */
				if (task) {
					stepData.Id = (step.Id || null);
				}

				return stepData;

			});

			const taskData = {
				Project: projectId,
				Title: form.state.Title,
				Duration: resolveCurrentDurationInput(),
				Steps: stepsData
			};

			/** Existing task = cannot change Project */
			if (task) {
				delete taskData.Project;
			}

			const result = await api({
				url: (!task ? `/tasks` : `/tasks/${task.Id}`),
				method: (!task ? "POST" : "PUT"),
				data: taskData
			}).then(({data}) => data);

			if (result.StepsSaveErrorsCount) {
				ToastStore.warning(`Not all task step operations completed successfully (${result.StepsSaveErrorsCount} ${pluralize("error", result.StepsSaveErrorsCount)}).`);
			}

			onSubmitted(result.Task, result.Steps);

		}
		catch (e) {
			ToastStore.error(e);
		}

		handleSubmitting(false);

	}, [
		form,
		task,
		steps,
		projectId,
		onSubmitted,
		handleSubmitting,
		resolveCurrentDurationInput
	]);


	return (
		<Form
			id={formId}
			onSubmit={handleSubmit}>
			<Flex
				gap={0.5}>
				<Form.Input
					autoFocus={true}
					disabled={isSubmitting}
					label="Title"
					placeholder="Title"
					name="Title"
					maxLength={255}
					required={true}
					onChange={form.updateStateFromSemanticInputChangeEvent}
					value={(form.state.Title || "")} />
				<Form.Field
					disabled={isSubmitting}>
					<label
						children="Duration" />
					<div
						className={scss.field__duration}>
						<Form.Input
							disabled={isSubmitting}
							placeholder="0"
							min={0}
							name="DurationHrs"
							onChange={form.updateStateFromSemanticInputChangeEvent}
							type="number"
							value={form.state.DurationHrs} />
						<span
							children="h" />
						<Form.Input
							disabled={isSubmitting}
							placeholder="0"
							min={0}
							name="DurationMins"
							onChange={form.updateStateFromSemanticInputChangeEvent}
							type="number"
							value={form.state.DurationMins} />
						<span
							children="m" />
					</div>
				</Form.Field>
				<Header
					className={scss.header}
					content="Task Steps"
					sub={true} />
				{
					steps.map((step, key) => {
						return (
							<TaskStepForm
								autoFocus={((stepsAutofocusKey > 0) && (key === (steps.length - 1)))}
								key={`${stepsAutofocusKey}${key}`}
								disabled={isSubmitting}
								onChange={handleChangeStep}
								onChangeOrder={handleReorderStep}
								onDelete={handleDeleteStep}
								step={step} />
						);
					})
				}
				<div>
					<Button
						basic={true}
						disabled={isSubmitting}
						content="Add another step"
						icon="add"
						onClick={handleAddStep}
						primary={true}
						size="small"
						type="button" />
				</div>
			</Flex>
		</Form>
	);

});

export default TaskForm;
