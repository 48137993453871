import api from "api.js";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useState} from "react";

const BuildCategoryDeletionDialog = memo(({
	buildCategoryId,
	open,
	onClose,
	onDeleted,
	productRangeMode
}) => {

	const [isSubmitting, setIsSubmitting] = useState(false);


	const handleSubmit = useCallback(async () => {

		setIsSubmitting(true);

		try {

			await api({
				url: `/build/categories/${buildCategoryId}`,
				method: "DELETE"
			});

			onDeleted(buildCategoryId);

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [buildCategoryId, onDeleted]);


	return (
		<Dialog
			open={open}
			onClose={onClose}
			onOk={handleSubmit}
			submitting={isSubmitting}
			size="tiny"
			title={`Delete ${(productRangeMode ? "Product Range" : "Product Category")}`}
			content={
				<>
					<p>Are you sure you want to delete this {(productRangeMode ? "Product Range" : "Product Category")}?</p>
					<p>All Build {(productRangeMode ? "Categories, " : "")}Items, Products, and Tasks in the {(productRangeMode ? "Product Range" : "category")} will be permanently deleted and removed from any current Project Revisions.</p>
					<p>No Tasks already added to the Production Schedule will be affected.</p>
				</>
			} />
	);

});

export default BuildCategoryDeletionDialog;
