import qs from "query-string";
import {useCallback, useMemo} from "react";
import {useLocation, useNavigate} from "react-router-dom";

const useQuery = defaults => {

	const {pathname, search} = useLocation();
	const navigate = useNavigate();


	const query = useMemo(() => {

		const query = qs.parse(search);

		for (const [key, value] of Object.entries((defaults || {}))) {
			if (query[key] === undefined) {
				query[key] = value;
			}
		}

		return query;

	}, [search, defaults]);


	const update = useCallback(query => {

		const currentQuery = qs.parse(search);
		const updatedQuery = {...currentQuery, ...query};
		const updatedQueryString = qs.stringify(updatedQuery);

		navigate(`${pathname}${(updatedQueryString ? `?${updatedQueryString}` : "")}`);

	}, [pathname, search, navigate]);


	const updateProp = useCallback((value, prop) => {
		update({[prop]: value});
	}, [update]);


	const updatePage = useCallback(Page => {
		update({Page});
	}, [update]);


	const updateSortOrder = useCallback(SortOrder => {
		update({
			SortOrder,
			SortDirection: (
				(SortOrder === query.SortOrder) ?
					((query.SortDirection === "Asc") ? "Desc" : "Asc") :
					"Asc"
			)
		});
	}, [update, query]);


	const updateWithPageReset = useCallback(query => {
		update({
			...query,
			Page: 1
		});
	}, [update]);


	const resolveBooleanValue = useCallback(prop => {
		return (parseInt(query[prop]) === 1);
	}, [query]);


	const toggleBooleanValue = useCallback(prop => {
		updateWithPageReset({
			...query,
			[prop]: ((parseInt(query[prop]) !== 1) ? 1 : 0)
		});
	}, [updateWithPageReset, query]);


	return useMemo(() => {
		return {
			update,
			updatePage,
			updateProp,
			updateSortOrder,
			updateWithPageReset,
			resolveBooleanValue,
			toggleBooleanValue,
			value: query
		};
	}, [
		query,
		update,
		updatePage,
		updateProp,
		updateSortOrder,
		updateWithPageReset,
		resolveBooleanValue,
		toggleBooleanValue
	]);

};

export default useQuery;
