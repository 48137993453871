import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import Panes from "Components/Panes.js";
import DetailsPane from "./UserDetailsPane.js";
import PermissionsPane from "./UserPermissionsPane.js";
import View from "Views/View.js";
import {useCallback} from "react";
import {useParams} from "react-router-dom";

const UserView = () => {

	const Id = parseInt(useParams().Id);

	const fetch = useAsync(useCallback(() => {
		return api({
			url: `/users/${Id}`
		}).then(({data}) => data);
	}, [Id]));

	return (
		<View
			loading={fetch.loading}
			error={fetch.error}
			onErrorRetry={fetch.call}>
			<Panes>
				<DetailsPane
					user={fetch.result}
					onUpdatedUser={fetch.setResult} />
				<PermissionsPane
					user={fetch.result} />
			</Panes>
		</View>
	);

};

export default UserView;
