import Flex from "Components/Flex.js";
import Hidden from "Components/Hidden.js";
import Picker from "Components/Picker.js";
import scss from "./TaskAssignmentControl.module.scss";
import {memo, useCallback, useEffect, useState} from "react";
import {Button, Icon, Input, Label} from "semantic-ui-react";

const TaskAssignmentControl = memo(({
	assignment,
	disabled,
	onChange,
	onCreate,
	onDelete,
	onToggleCompletionStatus,
	staffMembers
}) => {

	/**
	 * State
	 *
	 * @type {Object}
	 */
	const [state, setState] = useState(assignment);


	/**
	 * Adding a new assignment.
	 *
	 * @return {void}
	 */
	const handleAddNew = useCallback(() => {
		onCreate?.(state);
	}, [state, onCreate]);


	/**
	 * Property changed.
	 *
	 * @param {Object} updatedState
	 * @return {void}
	 */
	const handleChange = useCallback(updatedState => {
		setState(updatedState);
		onChange?.(assignment, updatedState);
	}, [assignment, onChange]);


	/**
	 * Staff member changed.
	 *
	 * @param {Object} StaffMember
	 * @return {void}
	 */
	const handleChangeStaffMember = useCallback(StaffMember => {
		handleChange({...state, StaffMember});
	}, [state, handleChange]);


	/**
	 * Schedule date changed.
	 *
	 * @param {Event} e
	 * @param {String} options.value YYYY-MM-DD
	 * @return {void}
	 */
	const handleChangeScheduleDate = useCallback((e, {value}) => {

		/**
		 * We don't support clearing the schedule date so just 
		 * ignore this event if the user cleared it using the 
		 * native browser control (which we can't disable)
		 */
		if (value) {
			handleChange({...state, ScheduleDate: value});
		}

	}, [state, handleChange]);


	/**
	 * Deleting the assignment.
	 *
	 * @return {void}
	 */
	const handleDelete = useCallback(() => {
		onDelete?.(assignment);
	}, [assignment, onDelete]);


	/**
	 * Toggling the assignment's completion status.
	 * 
	 * @return {void}
	 */
	const handleToggleCompletionStatus = useCallback(() => {
		onToggleCompletionStatus?.(assignment);
	}, [assignment, onToggleCompletionStatus]);


	/**
	 * Ensure we update the state when the active assignment changes
	 */
	useEffect(() => {
		setState(assignment);
	}, [assignment]);


	/**
	 * Render!
	 */
	return (
		<Flex
			className={scss.root}
			gap={0.25}>
			<Picker
				disabled={disabled}
				label="Staff Member"
				onChange={handleChangeStaffMember}
				options={staffMembers}
				value={state?.StaffMember} />
			<Input
				disabled={disabled}
				onChange={handleChangeScheduleDate}
				type="date"
				placeholder="Date"
				value={(state?.ScheduleDate || "")} />
			<Flex
				alignItems="center"
				columnar={true}
				gap={0.25}>
				<Hidden hidden={!assignment}>
					<Button
						basic={true}
						disabled={disabled}
						icon="delete"
						negative={true}
						onClick={handleDelete}
						title="Unassign" />
					<Icon
						disabled={disabled}
						className={scss.btn__complete}
						color={(assignment?.Complete ? "green" : "grey")}
						name={(assignment?.Complete ? "check circle outline" : "circle outline")}
						onClick={(!disabled ? handleToggleCompletionStatus : undefined)}
						size="big"
						title={(assignment?.Complete ? "Completed" : "Mark Complete")} />
				</Hidden>
				<Hidden hidden={!!assignment}>
					<Button
						disabled={(disabled || !state?.StaffMember?.Id || !state?.ScheduleDate)}
						icon="check"
						onClick={handleAddNew}
						primary={true}
						title="Create Assignment" />
				</Hidden>
				{
					assignment?.Conflicts?.AnnualLeave &&
						<Label
							color="pink"
							icon="exclamation"
							content="Annual Leave"
							size="tiny" />
				}
				{
					assignment?.Conflicts?.Overscheduled &&
						<Label
							color="orange"
							icon="exclamation"
							content="Overscheduled"
							size="tiny" />
				}
			</Flex>
		</Flex>
	);

});

export default TaskAssignmentControl;
