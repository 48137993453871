export default [
	{
		label: "Home",
		icon: "home",
		uri: "/"
	},
	{
		label: "Production Schedule",
		icon: "calendar alternate",
		permission: "ProductionSchedule",
		uri: "/schedule"
	},
	{
		label: "Year Planner",
		icon: "calendar alternate outline",
		permission: "ProductionSchedule",
		uri: "/planner"
	},
	{
		label: "Product Database",
		icon: "crop",
		permission: "BuildDatabaseManagement",
		uri: "/build"
	},
	{
		label: "Customer Dashboard",
		icon: "address book outline",
		permission: "CustomerManagement",
		uri: "/customers"
	},
	{
		label: "Project Dashboard",
		icon: "file alternate outline",
		permission: "ProjectManagement",
		uri: "/projects"
	},
	{
		label: "Staff Members",
		icon: "address card outline",
		permission: "StaffManagement",
		uri: "/staff"
	},
	{
		label: "User Accounts",
		icon: "user circle outline",
		permission: "Administrator",
		uri: "/users"
	},
	{
		label: "System Settings",
		icon: "settings",
		permission: "Administrator",
		uri: "/system"
	}
];
