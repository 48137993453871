import CustomerContactForm from "./CustomerContactForm.js";
import DetailsPanel from "Components/DetailsPanel.js";
import {memo} from "react";

const CustomerViewDetailsPaneContact = memo(({
	contact,
	disabled,
	onDelete,
	onSaved
}) => {

	const isNew = !contact.Id;

	return (
		<DetailsPanel
			defaultOpen={true}
			label={(isNew ? "New Contact" : (contact.ContactName || "(Unnamed Contact)"))}>
			<CustomerContactForm
				contact={contact}
				disabled={disabled}
				onDelete={onDelete}
				onSaved={onSaved}
				readOnly={true} />
		</DetailsPanel>
	);

});

export default CustomerViewDetailsPaneContact;
