import scss from "./InlineInput.module.scss";
import {memo} from "react";
import {Form} from "semantic-ui-react";

const InlineInput = memo(props => {

	const isTextarea = (props.rows && (props.rows > 1));

	const Component = (
		!isTextarea ?
			Form.Input :
			Form.TextArea
	);

	return (
		<Component
			className={scss.input}
			inline={true}
			size="small"
			transparent={(!isTextarea ? true : undefined)}
			{...props} />
	);

});

export default InlineInput;
