import dUpdate from "Dispatchers/dUpdate.js";
import {memo, useEffect} from "react";
import {useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";

/**
 * Navigate when the Redux store's `targetUri` value changes
 *
 * This mechanism allows navigation changes to be initiated 
 * globally, from outside the React tree.
 */
const targetUriListener = memo(() => {

	const location = useLocation();
	const navigate = useNavigate();
	const targetUri = useSelector(store => store.targetUri);

	useEffect(() => {

		if (targetUri) {

			/**
			 * We must clear the store value so we don't get directed 
			 * back to the target URI again, after the next navigation!
			 */
			dUpdate({targetUri: null});

			/**
			 * Navigate to the target URI now
			 */
			navigate(targetUri);

		}

	}, [navigate, location, targetUri]);

	return null;

});

export default targetUriListener;
