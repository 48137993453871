import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import useQuery from "Hooks/useQuery.js";
import BooleanStateIcon from "Components/BooleanStateIcon.js";
import DataTable from "Components/DataTable.js";
import NullableBooleanCheckboxSet from "Components/NullableBooleanCheckboxSet.js";
import UriButton from "Components/UriButton.js";
import View from "Views/View.js";
import useViewport from "Hooks/useViewport.js";
import {useCallback, useMemo} from "react";
import {Link} from "react-router-dom";

const StaffMembersView = () => {

	const {width: viewportWidth} = useViewport();


	const query = useQuery(
		useMemo(() => ({
			Limit: 25,
			Page: 1,
			Archived: 0,
			ProductionScheduleDisplay: null,
			Search: "",
			SortOrder: "Name",
			SortDirection: "Asc"
		}), [])
	);


	const fetch = useAsync(useCallback(() => {
		return api({
			url: `/staff`,
			params: query.value
		}).then(({data}) => data);
	}, [query.value]));


	const fields = useMemo(() => {
		return [
			{
				id: "Name",
				label: "Name",
				render: i => <Link children={i.Name} to={`/staff/${i.Id}`} />,
				sortable: true
			},
			{
				id: "Title",
				label: "Title",
				render: i => i.Title,
				hidden: (viewportWidth <= 640)
			},
			{
				id: "Archived",
				label: "Archived",
				title: "Archived Staff Members are leavers who no longer show in the system.",
				render: i => (
					<BooleanStateIcon
						useDashAsFalse={true}
						value={i.Archived} />
				),
				renderFilters(filterValue, onUpdateFilterValue) {
					return (
						<NullableBooleanCheckboxSet
							labelTrue="Archived"
							labelFalse="Non-archived"
							onChange={onUpdateFilterValue}
							reportIntegers={true}
							value={filterValue} />
					);
				},
				align: "center",
				filterable: true,
				defaultFilterValue: null,
				width: "7.5rem"
			},
			{
				id: "ProductionScheduleDisplay",
				label: "Displayed",
				title: "Whether the Staff Member appears on the Production Schedule.",
				render: i => (
					<BooleanStateIcon
						value={i.ProductionScheduleDisplayEnabled} />
				),
				renderFilters(filterValue, onUpdateFilterValue) {
					return (
						<NullableBooleanCheckboxSet
							labelTrue="Displayed on the Production Schedule"
							labelFalse="Hidden on the Production Schedule"
							onChange={onUpdateFilterValue}
							reportIntegers={true}
							value={filterValue} />
					);
				},
				align: "center",
				filterable: true,
				defaultFilterValue: null,
				sortable: true,
				width: "7.5rem"
			}
		];
	}, [viewportWidth]);


	return (
		<View>
			<DataTable
				actions={
					<UriButton
						content="New"
						icon="add"
						primary={true}
						size="small"
						uri="/staff/new" />
				}
				controls={
					<>
						<Link
							children="Manage Departments"
							to="/departments" />
						{ /** Here only to force a gap/spacing */ }
						<span />
					</>
				}
				data={fetch}
				fields={fields}
				label="Staff Members"
				query={query}
				searchable={true}
				sortable={true}
				stickyHeader={true} />
		</View>
	);

};

export default StaffMembersView;
