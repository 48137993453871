import api from "api.js";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useState} from "react";

const ProjectBuildRevisionDeletionDialog = memo(({
	open,
	onClose,
	onDeleted,
	revisionId
}) => {

	const [isSubmitting, setIsSubmitting] = useState(false);


	const handleSubmit = useCallback(async () => {

		setIsSubmitting(true);

		try {

			await api({
				url: `/projects/build/revisions/${revisionId}`,
				method: "DELETE"
			});

			onClose();
			onDeleted(revisionId);

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [onClose, onDeleted, revisionId]);


	return (
		<Dialog
			title="Delete Project Revision"
			content="Are you sure you want to permanently delete this Project Revision?"
			size="tiny"
			submitting={isSubmitting}
			open={open}
			onClose={onClose}
			onOk={handleSubmit} />
	);

});

export default ProjectBuildRevisionDeletionDialog;
