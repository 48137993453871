import DepartmentPicker from "Departments/DepartmentPicker.js";
import Dialog from "Components/Dialog.js";
import Flex from "Components/Flex.js";
import SubduedStateLabel from "Components/SubduedStateLabel.js";
import pluralize from "pluralize";
import {memo, useCallback, useMemo} from "react";
import {Button, Checkbox, Header} from "semantic-ui-react";

const ProductionScheduleViewSettingsDialog = memo(({
	open,
	onClose,
	config,
	onChangeConfig,
	binCount,
	staffMembers,
	staffConfig,
	onSetStaffMemberVisibility
}) => {

	const handleToggle = useCallback(value => {
		onChangeConfig({
			...config,
			[value]: !config[value]
		});
	}, [config, onChangeConfig]);


	const hiddenStaffMembers = useMemo(() => {
		return (staffMembers || []).filter(sm => {
			return (staffConfig?.[sm.Id]?.Visible === false);
		});
	}, [staffMembers, staffConfig]);


	const handleStaffMemberDepartmentsFilterChange = useCallback(depts => {
		onChangeConfig({
			...config,
			StaffMemberDepartmentsFilter: depts
		});
	}, [config, onChangeConfig]);


	return (
		<Dialog
			open={open}
			onClose={onClose}
			size="tiny"
			title="View Settings">
			<Flex>
				<Checkbox
					checked={!config.HideCompletedTasksInBins}
					onChange={() => handleToggle("HideCompletedTasksInBins")}
					label={`Show completed tasks in ${pluralize("bin", binCount)}`} />
				<Flex gap={0.25}>
					<Header
						content="Filter Displayed Staff Members by Department"
						sub={true} />
					<DepartmentPicker
						clearable={true}
						multiple={true}
						label="Department"
						onChange={handleStaffMemberDepartmentsFilterChange}
						value={(config.StaffMemberDepartmentsFilter || [])} />
				</Flex>
				<Flex gap={0.25}>
					<Header
						content="Hidden Staff Members"
						sub={true} />
					{
						hiddenStaffMembers.length ?
							hiddenStaffMembers.map((sm, key) => {
								return (
									<Flex
										alignItems="center"
										columnar={true}
										gap={0.25}
										key={key}>
										<Button
											basic={true}
											icon="close"
											negative={true}
											onClick={() => onSetStaffMemberVisibility(sm, true)}
											size="mini" />
										{sm.Name}
									</Flex>
								);
							}) :
							<SubduedStateLabel
								content="You haven't hidden any Staff Members." />
					}
				</Flex>
			</Flex>
		</Dialog>
	);

});

export default ProductionScheduleViewSettingsDialog;
