import api from "api.js";
import BuildItemTypes from "BuildDatabase/BuildItemTypes.js";
import Flex from "Components/Flex.js";
import LabelledCheckbox from "Components/LabelledCheckbox.js";
import ToastStore from "Toasts/ToastStore.js";
import useForm from "Hooks/useForm.js";
import {memo, useCallback, useRef, useState} from "react";
import {Divider, Form, Ref} from "semantic-ui-react";

const BuildItemForm = memo(({
	buildItem,
	defaultBuildCategory,
	formId,
	onSubmitted,
	onSubmittingChange
}) => {

	const form = useForm({
		Name: null,
		BuildOrderIndex: 1,
		BuildItemType: BuildItemTypes.Component,
		...buildItem
	});

	const [addAnother, setAddAnother] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const nameInputRef = useRef();

	const handleSubmit = useCallback(async e => {

		e.preventDefault();

		setIsSubmitting(true);
		onSubmittingChange?.(true);

		try {

			const result = await api({
				url: (!buildItem?.Id ? `/build/items` : `/build/items/${buildItem.Id}`),
				method: (!buildItem?.Id ? "POST" : "PUT"),
				data: {
					Name: form.state.Name,
					BuildOrderIndex: form.state.BuildOrderIndex,
					BuildCategory: (buildItem?.BuildCategory || defaultBuildCategory.Id),
					BuildItemType: BuildItemTypes.getCaseNameByValue(form.state.BuildItemType)
				}
			}).then(({data}) => data);

			onSubmitted?.(result, buildItem?.Id, addAnother);

			if (addAnother) {

				form.setState({
					...form.state,
					Name: ""
				});

				setTimeout(() => {
					nameInputRef.current?.querySelector?.("input")?.focus?.();
				});

			}

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);
		onSubmittingChange?.(false);

	}, [
		form,
		onSubmitted,
		onSubmittingChange,
		defaultBuildCategory,
		buildItem,
		addAnother
	]);


	/**
	 * Toggle whether to "add another".
	 */
	const handleToggleAddAnother = useCallback(() => {
		setAddAnother(!addAnother);
	}, [addAnother]);


	/**
	 * Toggle whether the item is a Product.
	 */
	const handleToggleProductStatus = useCallback(checked => {
		form.updateProp(
			(checked ? BuildItemTypes.Product : BuildItemTypes.Item),
			"BuildItemType"
		);
	}, [form]);


	/**
	 * Toggle whether the item is a selectable Project component.
	 */
	const handleToggleProjectSelectionEnabled = useCallback(checked => {
		form.updateProp(
			(checked ? BuildItemTypes.Item : BuildItemTypes.Component),
			"BuildItemType"
		);
	}, [form]);


	return (
		<Form
			id={formId}
			onSubmit={handleSubmit}>
			<Flex gap={0.5}>
				<Ref
					innerRef={nameInputRef}>
					<Form.Input
						autoFocus={true}
						disabled={isSubmitting}
						label="Name"
						name="Name"
						onChange={form.updateStateFromSemanticInputChangeEvent}
						placeholder="Name"
						required={true}
						value={(form.state.Name || "")} />
				</Ref>
				<Form.Input
					disabled={isSubmitting}
					label="Build Priority"
					min={0}
					name="BuildOrderIndex"
					onChange={form.updateStateFromSemanticInputChangeEvent}
					placeholder="Build Priority"
					required={true}
					type="number"
					value={(form.state.BuildOrderIndex || "")} />
				<Divider
					fitted={true}
					hidden={true} />
				<LabelledCheckbox
					label="This item is a Product"
					caption="When checked, this item will appear in the Category's Products list and will be configured to reference other Project Items, instead of defining its own Tasks. This setting can't be changed after creation."
					disabled={(isSubmitting || !!buildItem?.Id)}
					onChange={handleToggleProductStatus}
					value={(form.state.BuildItemType === BuildItemTypes.Product)} />
				<LabelledCheckbox
					label="Allow selecting this item when creating Projects"
					caption="When checked, you can select this item as a direct Project component; otherwise, this item can only be included in a Project through a reference from a Product. This setting does not apply to Products because these items are always selectable as Project components."
					disabled={(isSubmitting || (form.state.BuildItemType === BuildItemTypes.Product))}
					onChange={handleToggleProjectSelectionEnabled}
					value={(form.state.BuildItemType !== BuildItemTypes.Component)} />
				{
					!buildItem &&
						<>
							<Divider
								fitted={true}
								hidden={true} />
							<LabelledCheckbox
								label="Add another Item after this one?"
								onChange={handleToggleAddAnother}
								value={addAnother} />
						</>
				}
			</Flex>
		</Form>
	);

});

export default BuildItemForm;
