const Colours = [
	{
		label: "Yellow",
		value: "f6be00"
	},
	{
		label: "Orange",
		value: "ea7600"
	},
	{
		label: "Grey",
		value: "8c8279"
	},
	{
		label: "Sky Blue",
		value: "a4dbe8"
	},
	{
		label: "Blue",
		value: "3255a4"
	},
	{
		label: "Light Green",
		value: "bbc592"
	},
	{
		label: "Light Red",
		value: "ed3c31"
	},
	{
		label: "Light Purple",
		value: "c6b0bc"
	},
	{
		label: "Light Blue",
		value: "8db9ca"
	},
	{
		label: "Bright Green",
		value: "b5bd00"
	},
	{
		label: "Bright Red",
		value: "d50032"
	},
	{
		label: "Bright Pink",
		value: "ac145a"
	},
	{
		label: "Bright Blue",
		value: "0097a9"
	},
	{
		label: "Mid Green",
		value: "8f993e"
	},
	{
		label: "Mid Red",
		value: "93272c"
	},
	{
		label: "Mid Purple",
		value: "5d0778"
	},
	{
		label: "Mid Blue",
		value: "0d2855"
	},
	{
		label: "Stone",
		value: "d6d2c4"
	},
	{
		label: "Dark Green",
		value: "555d25"
	},
	{
		label: "Dark Red",
		value: "651d32"
	},
	{
		label: "Dark Purple",
		value: "4b384c"
	},
	{
		label: "Dark Blue",
		value: "003d4c"
	},
	{
		label: "Dark Brown",
		value: "4e3629"
	}
];

export default Colours;
