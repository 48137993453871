import api from "api.js";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import moment from "moment";
import useForm from "Hooks/useForm.js";
import {memo, useCallback, useMemo, useState} from "react";
import {Form} from "semantic-ui-react";

const StaffMemberAnnualLeaveDialog = memo(({
	open,
	onClose,
	onSubmitted,
	period,
	staffMemberId
}) => {

	const isNew = !period?.Id;
	const today = useMemo(() => (new moment()).format("YYYY-MM-DD"), []);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const form = useForm({
		StartDate: period?.StartDate,
		EndDate: period?.EndDate,
		UnassignTasks: true
	});


	const handleSubmit = useCallback(async e => {

		e.preventDefault();

		setIsSubmitting(true);

		try {

			const result = await api({
				url: (!period ? `/staff/${staffMemberId}/leave` : `/staff/leave/${period.Id}`),
				method: (!period ? "POST" : "PUT"),
				data: {...form.state}
			});

			onClose();
			onSubmitted(result);

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [form.state, period, staffMemberId, onClose, onSubmitted]);


	return (
		<Dialog
			open={open}
			onClose={onClose}
			okButtonForm={form.id}
			okButtonType="submit"
			title="Annual Leave"
			size="small"
			submitting={isSubmitting}>
			<Form
				id={form.id}
				onSubmit={handleSubmit}>
				<Form.Input
					disabled={isSubmitting}
					label="Start Date"
					placeholder="Start Date"
					min={(isNew ? today : undefined)}
					max={form.state.EndDate}
					name="StartDate"
					onChange={form.updateStateFromNativeInputChangeEvent}
					required={true}
					type="date"
					value={(form.state.StartDate || "")} />
				<Form.Input
					disabled={isSubmitting}
					label="End Date"
					placeholder="End Date"
					min={(form.state.StartDate || (isNew ? today : undefined))}
					name="EndDate"
					onChange={form.updateStateFromNativeInputChangeEvent}
					required={true}
					type="date"
					value={(form.state.EndDate || "")} />
				<Form.Checkbox
					checked={form.state.UnassignTasks}
					disabled={isSubmitting}
					label="Upon save, unassign all the Staff Member's tasks that are scheduled within the leave period"
					name="UnassignTasks"
					onChange={form.handleCheckboxToggle} />
			</Form>
		</Dialog>
	);

});

export default StaffMemberAnnualLeaveDialog;
