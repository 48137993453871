import CustomerDiscoveryMethod from "./CustomerDiscoveryMethod.js";
import EnumPicker from "Components/EnumPicker.js";
import {memo} from "react";

const CustomerDiscoveryMethodPicker = memo(props => {

	return (
		<EnumPicker
			enumClass={CustomerDiscoveryMethod}
			label="How did you hear about us?"
			noIcons={true}
			{...props} />
	);

});

export default CustomerDiscoveryMethodPicker;
