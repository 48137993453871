import DateControl from "./ProductionScheduleViewDateControl.js";
import scss from "./ProductionScheduleViewDateControls.module.scss";
import {memo, useCallback} from "react";

const ProductionScheduleViewDateControls = memo(({date, onChange}) => {

	const handleMoveDays = useCallback(dayDiff => {
		const newDate = date.clone().add(dayDiff, "days");
		onChange(newDate);
	}, [date, onChange]);

	return (
		<div className={scss.root}>
			<DateControl
				dayDiff={7}
				label="Week"
				onMoveDays={handleMoveDays} />
			<DateControl
				dayDiff={28}
				label="Month"
				onMoveDays={handleMoveDays} />
		</div>
	);

});

export default ProductionScheduleViewDateControls;
