import {memo} from "react";
import scss from "./EmptyStateLabel.module.scss";

const EmptyStateLabel = memo(({
	className,
	label,
	style
}) => {

	return (
		<p
			className={`${scss.label} ${(className || "")}`.trim()}
			style={style}>
			{(label || "(Not Set)")}
		</p>
	);

});

export default EmptyStateLabel;
