import api from "api.js";
import BuildCategoryPicker from "./BuildCategoryPicker.js";
import Flex from "Components/Flex.js";
import LabelledCheckbox from "Components/LabelledCheckbox.js";
import ToastStore from "Toasts/ToastStore.js";
import useForm from "Hooks/useForm.js";
import useValue from "Hooks/useValue.js";
import {memo, useCallback, useMemo, useRef, useState} from "react";
import {Form, Ref} from "semantic-ui-react";

const BuildCategoryForm = memo(({
	buildCategory,
	disableAddAnother,
	disableParentEditing,
	formId,
	onSubmitted,
	onSubmittingChange,
	parentId,
	productRangeMode
}) => {

	const form = useForm({...buildCategory});
	const nameInputRef = useRef();

	const addAnother = useValue(false);
	const [isSubmitting, setIsSubmitting] = useState(false);


	const handleSubmit = useCallback(async e => {

		e.preventDefault();

		setIsSubmitting(true);
		onSubmittingChange?.(true);

		try {

			const result = await api({
				url: (!buildCategory?.Id ? `/build/categories` : `/build/categories/${buildCategory.Id}`),
				method: (!buildCategory?.Id ? "POST" : "PUT"),
				data: {
					Name: form.state.Name,
					Parent: (form.state.Parent?.Id || form.state.Parent || parentId || null)
				}
			}).then(({data}) => data);

			onSubmitted?.(result, buildCategory?.Id, addAnother.value);

			if (addAnother.value) {

				form.setState({
					...form.state,
					Name: ""
				});

				setTimeout(() => {
					nameInputRef.current?.querySelector?.("input")?.focus?.();
				});

			}

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);
		onSubmittingChange?.(false);

	}, [
		form,
		onSubmitted,
		onSubmittingChange,
		buildCategory,
		parentId,
		addAnother
	]);


	const disabledParentCategoryIds = useMemo(() => {
		return [buildCategory?.Id];
	}, [buildCategory]);


	return (
		<Form
			id={formId}
			onSubmit={handleSubmit}>
			<Flex gap={0.5}>
				{
					(!productRangeMode && !disableParentEditing) &&
						<Form.Field>
							<label
								children="Parent" />
							<BuildCategoryPicker
								clearable={true}
								disabled={isSubmitting}
								disabledIds={disabledParentCategoryIds}
								label="Parent"
								name="Parent"
								onChange={form.updateProp}
								parentId={parentId}
								reportIds={true}
								value={((form.state.Parent !== parentId) ? form.state.Parent : null)} />
							<p><small>Leave blank to make the category a top-level category.</small></p>
						</Form.Field>
				}
				<Ref
					innerRef={nameInputRef}>
					<Form.Input
						autoFocus={true}
						disabled={isSubmitting}
						label="Name"
						name="Name"
						onChange={form.updateStateFromSemanticInputChangeEvent}
						placeholder="Name"
						required={true}
						value={(form.state.Name || "")} />
				</Ref>
				{
					(!buildCategory && !disableAddAnother) &&
						<LabelledCheckbox
							label={`Add another ${(productRangeMode ? "Product Range" : "Product Category")} after this one`}
							onChange={addAnother.toggleBoolean}
							value={addAnother.value} />
				}
			</Flex>
		</Form>
	);

});

export default BuildCategoryForm;
