import useViewport from "Hooks/useViewport.js";
import scss from "./AppBar.module.scss";
import useAppMenuItems from "Hooks/useAppMenuItems.js";
import {memo, useCallback} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Button, Dropdown, Icon, Menu, MenuItem} from "semantic-ui-react";

const AppBar = memo(() => {

	const menuItems = useAppMenuItems();

	const location = useLocation();
	const navigate = useNavigate();
	const viewport = useViewport();

	const isMobile = (viewport.width <= 1480);
	const useSelect = (viewport.width <= 640);

	const handleNavigateProfile = useCallback(() => {
		navigate("/profile");
	}, [navigate]);

	return (
		<nav
			className={scss.root}>
			<img
				alt="Red Sky Shepherd's Huts"
				className={scss.home__icon}
				onClick={() => navigate("/")}
				src="/logo.png" />
			{
				!useSelect ?
					<Menu
						className={scss.menu}>
						{
							menuItems.map((i, key) => {
								return (
									<MenuItem
										className={scss.menuItem}
										key={key}
										link={true}
										onClick={() => navigate(i.uri)}
										tabIndex="0"
										title={i.label}>
										<Icon
											className={(isMobile ? scss.noMargin : undefined)}
											name={i.icon} />
										{(!isMobile && i.label)}
									</MenuItem>
								);
							})
						}
					</Menu> :
					<Dropdown
						className={scss.dropdown}
						fluid={true}
						onChange={(e, {value}) => navigate(value)}
						options={
							menuItems.map((i, key) => {
								return {
									key,
									text: i.label,
									icon: i.icon,
									value: i.uri
								};
							})
						}
						placeholder="Go to..."
						selection={true}
						selectOnBlur={false}
						value={(location.pathname || "/")} />
			}
			<Button
				className={scss.profileMenu}
				icon="user"
				onClick={handleNavigateProfile}
				title="Profile & Logout" />
		</nav>
	);

});

export default AppBar;
