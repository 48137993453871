import api from "api.js";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useState} from "react";

const UserArchiveDialog = memo(({
	open,
	onArchived,
	onClose,
	user
}) => {

	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleSubmit = useCallback(async () => {

		setIsSubmitting(true);

		try {

			await api({
				url: `/users/${user.Id}/archive`,
				method: "POST"
			});

			onArchived(user);
			onClose();

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [user, onArchived, onClose]);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			onOk={handleSubmit}
			submitting={isSubmitting}
			title="Archive User"
			content="Are you sure you want to archive this User?"
			size="tiny" />
	);

});

export default UserArchiveDialog;
