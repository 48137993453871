import api from "api.js";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useState} from "react";

const DepartmentArchiveDialog = memo(({
	open,
	onClose,
	onArchived,
	departmentId
}) => {

	const [isSubmitting, setIsSubmitting] = useState(false);


	const handleSubmit = useCallback(async () => {

		setIsSubmitting(true);

		try {

			await api({
				url: `/departments/${departmentId}/archive`,
				method: "POST"
			});

			onArchived(departmentId);
			onClose();

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [departmentId, onArchived, onClose]);


	return (
		<Dialog
			open={open}
			onClose={onClose}
			onOk={handleSubmit}
			submitting={isSubmitting}
			size="tiny"
			title="Archive Department"
			content="Are you sure you want to archive this department?" />
	);

});

export default DepartmentArchiveDialog;
