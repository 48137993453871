import api from "api.js";
import useForm from "Hooks/useForm.js";
import Checkbox from "Components/LabelledCheckbox.js";
import Flex from "Components/Flex.js";
import SubduedStateLabel from "Components/SubduedStateLabel.js";
import ToastStore from "Toasts/ToastStore.js";
import UriButton from "Components/UriButton.js";
import View from "Views/View.js";
import {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Divider, Form} from "semantic-ui-react";

const UserCreationView = () => {

	const navigate = useNavigate();

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [hasPasswordConfirmationError, setHasPasswordConfirmationError] = useState(false);


	const form = useForm({
		Username: "",
		Name: "",
		Password: "",
		PasswordConfirmation: "",
		Active: true
	}, useCallback(() => {
		setHasPasswordConfirmationError(false);
	}, []));


	const handleActiveToggle = useCallback(() => {
		form.setState({
			...form.state,
			Active: !form.state.Active
		});
	}, [form]);


	const handleSubmit = useCallback(async () => {

		if (form.state.Password !== form.state.PasswordConfirmation) {
			setHasPasswordConfirmationError(true);
			return;
		}

		setIsSubmitting(true);

		try {

			const user = await api({
				url: `/users`,
				method: "POST",
				data: {
					Username: form.state.Username,
					Name: form.state.Name,
					Password: form.state.Password,
					Active: (form.state.Active ? 1 : 0)
				}
			}).then(({data}) => data);

			navigate(`/users/${user.Id}`);

		}
		catch (e) {
			if (e.response?.status === 409) {
				ToastStore.error(`The username you've entered already exists.`);
			}
			else ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [form, navigate]);


	useEffect(() => {
		if (hasPasswordConfirmationError) {
			ToastStore.error("Your password inputs do not match.");
		}
	}, [hasPasswordConfirmationError]);


	return (
		<View
			label="New User"
			narrow={true}>
			<Form
				onSubmit={handleSubmit}>
				<SubduedStateLabel
					content="You can assign permissions to the new User after initial creation." />
				<Divider
					hidden={true} />
				<Form.Input
					autoFocus={true}
					disabled={isSubmitting}
					label="Username"
					maxLength={255}
					name="Username"
					onChange={form.updateStateFromSemanticInputChangeEvent}
					placeholder="Username"
					required={true}
					value={(form.state.Username || "")} />
				<Form.Input
					disabled={isSubmitting}
					label="Name"
					maxLength={255}
					name="Name"
					onChange={form.updateStateFromSemanticInputChangeEvent}
					placeholder="Name"
					required={true}
					value={(form.state.Name || "")} />
				<Form.Input
					disabled={isSubmitting}
					label="Password"
					error={hasPasswordConfirmationError}
					maxLength={32}
					minLength={8}
					name="Password"
					onChange={form.updateStateFromSemanticInputChangeEvent}
					placeholder="Password (8-32 characters)"
					required={true}
					type="password"
					value={(form.state.Password || "")} />
				<Form.Input
					disabled={isSubmitting}
					label="Password — Confirmation"
					error={hasPasswordConfirmationError}
					maxLength={32}
					minLength={8}
					name="PasswordConfirmation"
					onChange={form.updateStateFromSemanticInputChangeEvent}
					placeholder="Repeat the password entered above"
					required={true}
					type="password"
					value={(form.state.PasswordConfirmation || "")} />
				<Divider
					hidden={true} />
				<Checkbox
					disabled={isSubmitting}
					name="Active"
					label="Create as an Active user"
					caption="Users must be Active to login and use the system."
					onChange={handleActiveToggle}
					value={form.state.Active} />
				<Divider
					hidden={true} />
				<Flex
					columnar={true}
					gap={0.25}>
					<Button
						content="Save"
						disabled={isSubmitting}
						loading={isSubmitting}
						icon="check"
						primary={true}
						type="submit" />
					<UriButton
						content="Cancel"
						disabled={isSubmitting}
						uri="/users" />
				</Flex>
			</Form>
		</View>
	);

};

export default UserCreationView;
