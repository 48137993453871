import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import AnnualLeavePane from "./AnnualLeave/StaffMemberAnnualLeavePane.js";
import DepartmentsPane from "./Departments/StaffMemberDepartmentsPane.js";
import DetailsPane from "./StaffMemberDetailsPane.js";
import Panes from "Components/Panes.js";
import TabPanel from "Components/TabPanel.js";
import View from "Views/View.js";
import {useCallback} from "react";
import {useParams} from "react-router-dom";

const StaffMemberView = () => {

	const Id = parseInt(useParams().Id);

	const fetch = useAsync(useCallback(() => {
		return api({
			url: `/staff/${Id}`
		}).then(({data}) => data);
	}, [Id]));

	return (
		<View
			loading={fetch.loading}
			error={fetch.error}
			onErrorRetry={fetch.call}>
			<Panes>
				<DetailsPane
					staffMember={fetch.result}
					onChangeStaffMember={fetch.setResult} />
				<TabPanel
					tabs={[
						{
							label: "Annual Leave",
							render: () => (
								<AnnualLeavePane
									staffMemberId={Id}
									preventMutation={(fetch.result?.Archived !== false)} />
							)
						},
						{
							label: "Departments",
							render: () => (
								<DepartmentsPane
									staffMemberId={Id}
									preventMutation={(fetch.result?.Archived !== false)} />
							)
						}
					]} />
			</Panes>
		</View>
	);

};

export default StaffMemberView;
