import api from "api.js";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useState} from "react";

const BuildTaskDeletionDialog = memo(({
	taskId,
	open,
	onClose,
	onDeleted
}) => {

	const [isSubmitting, setIsSubmitting] = useState(false);


	const handleSubmit = useCallback(async () => {

		setIsSubmitting(true);

		try {

			await api({
				url: `/build/tasks/${taskId}`,
				method: "DELETE"
			});

			onDeleted(taskId);

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [onDeleted, taskId]);


	return (
		<Dialog
			open={open}
			onClose={onClose}
			onOk={handleSubmit}
			submitting={isSubmitting}
			size="tiny"
			title="Delete Build Task"
			content={
				<>
					<p>Are you sure you want to delete this Build Task?</p>
					<p>It will be permanently removed from all Project Items and any current Project Revisions that reference it.</p>
					<p>No tasks already added to the Production Schedule will be affected.</p>
				</>
			} />
	);

});

export default BuildTaskDeletionDialog;
