import api from "api.js";
import Input from "./BuildSheetInput.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useState} from "react";

const BuildItemRequirementQuantityInput = memo(({
	buildItem,
	requiredBuildItem,
	onChange,
	value
}) => {

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [inputValue, setInputValue] = useState(value);


	const handleChange = useCallback((e, {value}) => {
		setInputValue(value);
	}, []);


	const handleSubmit = useCallback(async () => {

		setIsSubmitting(true);

		try {

			const buildItemId = buildItem.Id;
			const requiredBuildItemId = requiredBuildItem.Id;
			const qtyRequired = parseInt(inputValue);

			await api({
				url: `/build/items/requirements`,
				method: "POST",
				data: {
					BuildItem: buildItemId,
					RequiredBuildItem: requiredBuildItemId,
					QuantityRequired: qtyRequired
				}
			});

			onChange(qtyRequired, buildItemId, requiredBuildItemId);

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [buildItem, requiredBuildItem, onChange, inputValue]);


	return (
		<Input
			disabled={isSubmitting}
			min={0}
			onBlur={handleSubmit}
			onChange={handleChange}
			type="number"
			value={inputValue} />
	);

});

export default BuildItemRequirementQuantityInput;
