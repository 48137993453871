import Enum from "Includes/Enum.js";

/**
 * Customer status enum
 *
 * @package RedSky
 * @subpackage Customers
 * @author Heron Web Ltd
 * @copyright Red Sky Shepherds Huts Limited
 */
class CustomerStatus extends Enum {

	static Active = 1;

	static Closed = 2;

	static getColour(name) {
		return {
			Active: "blue",
			Closed: "red"
		}[name];
	}

}

export default CustomerStatus;
