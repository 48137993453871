import api from "api.js";
import EnumChip from "Components/EnumChip.js";
import Flex from "Components/Flex.js";
import ProjectAssigneeType from "Projects/ProjectAssigneeType.js";
import ProjectSchedulingDialog from "Projects/ProjectSchedulingDialog.js";
import ProjectStatus from "Projects/ProjectStatus.js";
import SubduedStateLabel from "Components/SubduedStateLabel.js";
import ToastStore from "Toasts/ToastStore.js";
import moment from "moment";
import pluralize from "pluralize";
import scss from "./YearPlannerProjectsSidebarItem.module.scss";
import useValue from "Hooks/useValue.js";
import {resolveCssColour} from "Includes/Colour.js";
import {memo, useCallback, useState} from "react";
import {Link} from "react-router-dom";
import {Icon} from "semantic-ui-react";

const YearPlannerProjectsSidebarItem = memo(({
	project,
	onScheduleChange
}) => {

	const assigneeString = [
		project.AssigneeType.Label,
		(
			(project.AssigneeType.Id === ProjectAssigneeType.RedSky) ?
				project.AssignedStaffMember?.Name :
				(
					(project.AssigneeType.Id === ProjectAssigneeType.Customer) ?
						project.AssignedCustomerContact?.ContactName :
						project.AssigneeNotes
				)
		)
	].filter(v => v).map((i, key) => ((key > 0) ? `(${i})` : i)).join(" ");


	const datesString = [
		(project.ProductionStartDate ? (new moment(project.ProductionStartDate)).format("DD/MM/YYYY") : undefined),
		(project.ProductionEndDate ? (new moment(project.ProductionEndDate)).format("DD/MM/YYYY") : undefined)
	].filter(v => v).join(" – ");


	const schedulingDialogOpen = useValue(false);
	const [draggedOver, setDraggedOver] = useState(false);


	const handleDragStart = useCallback(e => {

		const dataTransfer = {
			Project: project?.Id
		};

		e.dataTransfer.setData("text/plain", JSON.stringify(dataTransfer));
		e.dataTransfer.effectAllowed = "move";

	}, [project]);


	const handleDragOver = useCallback(e => {
		e.preventDefault();
		setDraggedOver(true);
	}, []);


	const handleDragLeave = useCallback(e => {
		e.preventDefault();
		setDraggedOver(false);
	}, []);


	const handleDrop = useCallback(async e => {

		e.preventDefault();
		setDraggedOver(false);

		/**
		 * `text/plain` is used instead of `application/json` because 
		 * it's the only way to get this working in Android Chrome :/
		 */
		const dataTransfer = e.dataTransfer.getData("text/plain");
		if (!dataTransfer) return;

		/**
		 * The ID of the Project that will now have *our* start date
		 */
		const targetProjectId = JSON.parse(dataTransfer)?.Project;
		if (!targetProjectId || (targetProjectId === project.Id)) return;

		/**
		 * Production start date to assign to the target Project
		 */
		const prodSchedDate = project.ProductionStartDate;
		if (!prodSchedDate) return;

		try {

			await api({
				url: `/projects/${targetProjectId}/production_schedule_date`,
				method: "PUT",
				data: {
					Date: prodSchedDate
				}
			});

			onScheduleChange(prodSchedDate, targetProjectId);

		}
		catch (e) {
			ToastStore.error(e);
		}

	}, [project, onScheduleChange]);


	return (
		<div>
			<div
				className={scss.root}
				draggable={true}
				onDragStart={handleDragStart}
				onDragOver={handleDragOver}
				onDragLeave={handleDragLeave}
				onDrop={handleDrop}
				style={{
					opacity: (draggedOver ? "0.3" : undefined),
					transition: "opacity 0.2s ease-in-out"
				}}>
				<EnumChip
					enumClass={ProjectStatus}
					valueCase={project.Status.Name} />
				<div
					className={scss.detailsContainer}>
					<strong>
						<Link
							children={(project.Name || project.Customer?.Name)}
							className={scss.projectName}
							style={{
								background: resolveCssColour(project.Colour)
							}}
							to={`/projects/${project.Id}`} />
					</strong>
					{
						project.Customer ?
							<Link
								children={project.Customer.Name}
								className={scss.customerName}
								to={`/customers/${project.Customer.Id}`} /> :
							<span>(Internal Project)</span>
					}
					{
						project.SubheaderNotes &&
							<SubduedStateLabel
								className={scss.subheaderNotes}
								content={project.SubheaderNotes} />
					}
					<Flex
						columnar={true}
						gap={0.2}>
						<Icon
							name="user"
							color="grey"
							size="small"
							title="Assignee"
							style={{paddingTop: "0.3em"}} />
						{assigneeString}
					</Flex>
					<Flex
						columnar={true}
						gap={0.2}>
						<Icon
							name="tag"
							color="grey"
							size="small"
							title="Phase"
							style={{paddingTop: "0.3em"}} />
						{project.Phase.Label}
					</Flex>
					<span>
						<strong>{project.DurationDays} {pluralize("day", project.DurationDays)}</strong> &bull; <a onClick={schedulingDialogOpen.setTrue}>{(datesString || "(Unscheduled)")}</a>
					</span>
				</div>
			</div>
			{
				schedulingDialogOpen.value &&
					<ProjectSchedulingDialog
						open={schedulingDialogOpen.value}
						onClose={schedulingDialogOpen.setFalse}
						onScheduled={onScheduleChange}
						project={project} />
			}
		</div>
	);

});

export default YearPlannerProjectsSidebarItem;
