import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import EnumChip from "Components/EnumChip.js";
import ProjectStatus from "./ProjectStatus.js";
import Select from "Components/Select.js";
import {memo, useCallback, useMemo} from "react";

const ProjectPhasePicker = memo(props => {

	const fetch = useAsync(useCallback(() => {
		return api({
			url: `/projects/phases`
		}).then(({data}) => data);
	}, []));

	const options = useMemo(() => {
		return (fetch.result || [])
			.filter(phase => {
				return (
					!props.projectStatus ||
					phase.ProjectStatuses.includes(props.projectStatus)
				);
			})
			.map(phase => {
				return {
					text: phase.Label,
					value: phase.Name,
					icon: (
						props.withProjectStatusChips ?
							<>
								{
									phase.ProjectStatuses.map((status, key) => {
										return (
											<EnumChip
												enumClass={ProjectStatus}
												key={key}
												size="mini"
												valueCase={status} />
										);
									})
								}
							</> :
							null
					)
				};
			});
	}, [
		fetch.result,
		props.projectStatus,
		props.withProjectStatusChips
	]);

	const propsToForward = {...props};
	delete propsToForward.projectStatus;
	delete propsToForward.withProjectStatusChips;

	return (
		<Select
			label="Project Phase"
			loading={fetch.loading}
			options={options}
			{...propsToForward} />
	);

});

export default ProjectPhasePicker;
