import scss from "./ButtonGrid.module.scss";
import {Button} from "semantic-ui-react";
import {memo} from "react";
import {useNavigate} from "react-router-dom";

const ButtonGrid = memo(({items}) => {

	const navigate = useNavigate();

	return (
		<div className={scss.root}>
			{
				items?.map((i, key) => {
					return (
						<Button
							content={i.label}
							icon={i.icon}
							key={key}
							onClick={() => navigate(i.uri)}
							primary={true}
							size="massive" />
					);
				})
			}
		</div>
	);

});

export default ButtonGrid;
