import api from "api.js";
import useForm from "Hooks/useForm.js";
import Flex from "Components/Flex.js";
import ToastStore from "Toasts/ToastStore.js";
import UriButton from "Components/UriButton.js";
import View from "Views/View.js";
import {useCallback, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Divider, Form} from "semantic-ui-react";

const DepartmentCreationView = () => {

	const navigate = useNavigate();
	const [isSubmitting, setIsSubmitting] = useState(false);


	const form = useForm({
		Name: null,
		Notes: null
	});


	const handleSubmit = useCallback(async () => {

		setIsSubmitting(true);

		try {

			const result = await api({
				url: `/departments`,
				method: "POST",
				data: {
					Name: form.state.Name,
					Notes: (form.state.Notes || null)
				}
			}).then(({data}) => data);

			navigate(`/departments/${result.Id}`);

		}
		catch (e) {
			if (e.response?.status === 409) {
				ToastStore.error("The Department name you've entered already exists.");
			}
			else ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [form, navigate]);


	return (
		<View
			label="New Department"
			narrow={true}>
			<Form
				onSubmit={handleSubmit}>
				<Form.Input
					autoFocus={true}
					disabled={isSubmitting}
					label="Name"
					placeholder="Name"
					name="Name"
					maxLength={255}
					required={true}
					onChange={form.updateStateFromSemanticInputChangeEvent}
					value={(form.state.Name || "")} />
				<Form.TextArea
					disabled={isSubmitting}
					label="Notes"
					placeholder="Notes"
					name="Notes"
					maxLength={65535}
					onChange={form.updateStateFromSemanticInputChangeEvent}
					value={(form.state.Notes || "")} />
				<Divider
					hidden={true} />
				<Flex
					columnar={true}
					gap={0.25}>
					<Button
						content="Save"
						icon="check"
						disabled={isSubmitting}
						loading={isSubmitting}
						primary={true}
						type="submit" />
					<UriButton
						content="Cancel"
						disabled={isSubmitting}
						uri="/departments" />
				</Flex>
			</Form>
		</View>
	);

};

export default DepartmentCreationView;
