import Enum from "Includes/Enum.js";

/**
 * Build item types enum
 *
 * @package RedSky
 * @subpackage BuildDatabase
 * @author Heron Web Ltd
 * @copyright Red Sky Shepherds Huts Limited
 */
class BuildItemTypes extends Enum {

	static Product = 1;

	static Item = 2;

	static Component = 3;

}

export default BuildItemTypes;
