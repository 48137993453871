import api from "api.js";
import useForm from "Hooks/useForm.js";
import CustomerCreationDialog from "Customers/CustomerCreationDialog.js";
import CustomerPicker from "Customers/CustomerPicker.js";
import Flex from "Components/Flex.js";
import SubduedStateLabel from "Components/SubduedStateLabel.js";
import ToastStore from "Toasts/ToastStore.js";
import useAsync from "Hooks/useAsync.js";
import useValue from "Hooks/useValue.js";
import {memo, useCallback, useEffect, useState} from "react";
import {Divider, Form} from "semantic-ui-react";

const ProjectCreationForm = memo(({
	customerId,
	formId,
	onCreated,
	onSubmitting
}) => {

	const customerCreationDialogOpen = useValue(false);
	const [isSubmitting, setIsSubmitting] = useState(false);


	const form = useForm({
		Customer: (customerId || null),
		Name: null,
		SubheaderNotes: null,
		Notes: null
	});


	const handleChangeCustomer = useCallback(Customer => {
		form.setState({
			...form.state,
			Customer
		});
	}, [form]);
	const handleSubmit = useCallback(async e => {

		e.preventDefault();

		setIsSubmitting(true);

		try {

			const project = await api({
				url: `/projects`,
				method: "POST",
				data: {
					Customer: (form.state.Customer?.Id || form.state.Customer || null),
					Name: form.state.Name,
					SubheaderNotes: form.state.SubheaderNotes,
					Notes: form.state.Notes
				}
			}).then(({data}) => data);

			onCreated(project);

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [onCreated, form.state]);


	const suggestedProjectName = useAsync(useCallback(() => {
		if ((form.state.Customer || customerId)) {
			return api({
				url: `/customers/${(form.state.Customer?.Id || customerId)}/project_name_suggestion`
			}).then(({data}) => data.Name);
		}
		else return null;
	}, [form.state.Customer, customerId]));


	useEffect(() => {
		if (suggestedProjectName.result) {
			form.updateProp(suggestedProjectName.result, "Name");
			suggestedProjectName.setResult(null);
		}
	}, [form, suggestedProjectName]);


	useEffect(() => {
		onSubmitting(isSubmitting);
	}, [onSubmitting, isSubmitting]);


	return (
		<div>
			<SubduedStateLabel
				content="Please enter the following details to create a new Project." />
			<Divider
				hidden={true} />
			<Form
				id={formId}
				onSubmit={handleSubmit}>
				{
					!customerId &&
						<Form.Field>
							<label
								children="Customer" />
							<Flex
								gap={0.25}>
								<CustomerPicker
									autoFocus={true}
									clearable={true}
									disabled={isSubmitting}
									label="Customer"
									name="Customer"
									onChange={handleChangeCustomer}
									value={form.state.Customer} />
								<SubduedStateLabel
									content="Leave blank for internal Projects." />
								<div
									style={{marginTop: "-1rem"}}>
									<a onClick={(!isSubmitting ? customerCreationDialogOpen.setTrue : () => null)}>New Customer</a>
								</div>
							</Flex>
						</Form.Field>
				}
				<Form.Input
					autoFocus={!!customerId}
					disabled={isSubmitting}
					label="Project Name"
					name="Name"
					maxLength={255}
					onChange={form.updateStateFromSemanticInputChangeEvent}
					placeholder="(Unnamed Project)"
					required={!form.state.Customer}
					value={(form.state.Name || "")} />
				<Form.Input
					disabled={isSubmitting}
					label="Subheader Notes"
					name="SubheaderNotes"
					maxLength={255}
					onChange={form.updateStateFromSemanticInputChangeEvent}
					placeholder="E.g. 8 x 20, 8 x 18"
					value={(form.state.SubheaderNotes || "")} />
				<Form.TextArea
					disabled={isSubmitting}
					label="Notes"
					name="Notes"
					maxLength={65535}
					onChange={form.updateStateFromSemanticInputChangeEvent}
					placeholder="Notes"
					value={(form.state.Notes || "")} />
			</Form>
			{
				customerCreationDialogOpen.value &&
					<CustomerCreationDialog
						open={customerCreationDialogOpen.value}
						onClose={customerCreationDialogOpen.setFalse}
						onCreated={handleChangeCustomer} />
			}
		</div>
	);

});

export default ProjectCreationForm;
