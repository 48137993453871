import scss from "./ProductionScheduleViewStaffMemberControl.module.scss";
import {memo} from "react";
import {Icon} from "semantic-ui-react";

const ProductionScheduleViewStaffMemberControl = memo(({
	className,
	disabled,
	isCollapsed,
	onIncrementDisplayIndex,
	onSetVisibility,
	onToggleCollapsed,
	staffMember
}) => {

	return (
		<div
			className={`${scss.root} ${(className || "")}`.trim()}
			style={{
				background: (!isCollapsed ? "var(--production-schedule-staff-row-expanded-background)" : undefined)
			}}>
			<div className={scss.controls}>
				<Icon
					className={scss.icon}
					disabled={disabled}
					name="arrow up"
					onClick={() => onIncrementDisplayIndex(staffMember, -1)} />
				<Icon
					className={scss.icon}
					disabled={disabled}
					name="eye"
					onClick={() => onSetVisibility(staffMember, false)} />
				<Icon
					className={scss.icon}
					disabled={disabled}
					name="arrow down"
					onClick={() => onIncrementDisplayIndex(staffMember, 1)} />
			</div>
			<div>
				<div>
					<strong
						className={scss.staffMemberLabel}
						onClick={() => onToggleCollapsed(staffMember.Id)}
						tabIndex="0"
						title={(!isCollapsed ? "Expand" : "Collapse")}>
						{staffMember.Name}
					</strong>
				</div>
				<div>{staffMember.Title}</div>
			</div>
		</div>
	);

});

export default ProductionScheduleViewStaffMemberControl;
