import EnumPicker from "Components/EnumPicker.js";
import ProjectAssigneeType from "./ProjectAssigneeType.js";
import {memo} from "react";

const ProjectAssigneeTypePicker = memo(props => {

	return (
		<EnumPicker
			enumClass={ProjectAssigneeType}
			label="Assignee Type"
			noIcons={true}
			{...props} />
	);

});

export default ProjectAssigneeTypePicker;
