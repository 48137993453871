import api from "api.js";
import scss from "./CustomerView.module.scss";
import useAsync from "Hooks/useAsync.js";
import usePermissions from "Hooks/usePermissions.js";
import BuildRevisionsPane from "Projects/BuildRevisions/ProjectBuildRevisionsPane.js";
import DetailsPane from "./CustomerViewDetailsPane.js";
import Panes from "Components/Panes.js";
import ProjectsPane from "./CustomerViewProjectsPane.js";
import View from "Views/View.js";
import {memo, useCallback, useMemo, useState} from "react";
import {useParams} from "react-router-dom";

const CustomerView = memo(() => {

	const {Id} = useParams();
	const {hasPermission} = usePermissions();

	const [activeProjectId, setActiveProjectId] = useState(null);
	const [activeProjectBuildRevisionId, setActiveProjectBuildRevisionId] = useState(null);

	const customerFetch = useAsync(useCallback(() => {
		return api({url: `/customers/${Id}`}).then(({data}) => data);
	}, [Id]));


	const activeProject = useMemo(() => {

		if (!customerFetch.result?.Projects?.length) {
			return null;
		}

		if (activeProjectId) {
			return customerFetch.result.Projects.find(({Id}) => (Id === activeProjectId));
		}

		else {
			return (
				customerFetch.result.Projects.find(p => {
					return (p.Status.Name === "Live");
				}) ||
				customerFetch.result.Projects[0]
			);
		}

	}, [activeProjectId, customerFetch]);


	const handleProjectCreate = useCallback(project => {

		customerFetch.setResult({
			...customerFetch.result,
			Projects: [...customerFetch.result.Projects, project]
		});

		setActiveProjectId(project.Id);

	}, [customerFetch]);


	const handleProjectDelete = useCallback(project => {

		if (activeProjectId === project.Id) {
			setActiveProjectId(null);
		}

		customerFetch.setResult({
			...customerFetch.result,
			Projects: customerFetch.result.Projects.filter(p => (p.Id !== project.Id))
		});

	}, [activeProjectId, customerFetch]);


	const handleProjectUpdate = useCallback(updatedProject => {

		const updatedProjects = [...customerFetch.result.Projects];

		const currentProject = updatedProjects.find(p => (p.Id === updatedProject.Id));
		const currentProjectIndex = updatedProjects.indexOf(currentProject);

		if (currentProjectIndex >= 0) {
			updatedProjects[currentProjectIndex] = updatedProject;
		}

		customerFetch.setResult({
			...customerFetch.result,
			Projects: updatedProjects
		});

	}, [customerFetch]);


	const handleRevisionApproved = useCallback(approvedProject => {
		handleProjectUpdate(approvedProject);
	}, [handleProjectUpdate]);


	const projectsOrderedMostRecentFirst = useMemo(() => {
		return [...(customerFetch.result?.Projects || [])].reverse();
	}, [customerFetch.result?.Projects]);


	return (
		<View
			loading={customerFetch.loading}
			error={customerFetch.error}
			onErrorRetry={customerFetch.call}>
			<Panes
				className={scss.panes}>
				<DetailsPane
					customer={customerFetch.result}
					onUpdateCustomer={customerFetch.setResult} />
				{
					hasPermission("ProjectManagement") &&
						<>
							<ProjectsPane
								activeProject={activeProject}
								customerId={customerFetch.result?.Id}
								projects={projectsOrderedMostRecentFirst}
								onCreateProject={handleProjectCreate}
								onDeleteProject={handleProjectDelete}
								onSelectProject={setActiveProjectId}
								onUpdateProject={handleProjectUpdate} />
							<BuildRevisionsPane
								project={activeProject}
								onApproveRevision={handleRevisionApproved}
								onSelectRevision={setActiveProjectBuildRevisionId}
								selectedRevisionId={activeProjectBuildRevisionId} />
						</>
				}
			</Panes>
		</View>
	);

});

export default CustomerView;
