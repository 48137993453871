import api from "api.js";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useState} from "react";

const RemoveStaffMemberFromDepartmentDialog = memo(({
	open,
	onClose,
	onRemoved,
	departmentId,
	staffMemberId
}) => {

	const [isSubmitting, setIsSubmitting] = useState(false);


	const handleSubmit = useCallback(async () => {

		setIsSubmitting(true);

		try {

			await api({
				url: `/departments/${departmentId}/staff/${staffMemberId}`,
				method: "DELETE"
			});

			onRemoved(departmentId, staffMemberId);
			onClose();

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [departmentId, staffMemberId, onClose, onRemoved]);


	return (
		<Dialog
			open={open}
			onClose={onClose}
			onOk={handleSubmit}
			submitting={isSubmitting}
			size="tiny"
			title="Remove Staff Member from Department"
			content="Are you sure you want to remove this Staff Member from this Department?" />
	);

});

export default RemoveStaffMemberFromDepartmentDialog;
