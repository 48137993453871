import Dialog from "Components/Dialog.js";
import ProjectCreationForm from "./ProjectCreationForm.js";
import {memo, useCallback, useState} from "react";

const ProjectCreationDialog = memo(({
	customerId,
	open,
	onClose,
	onCreated
}) => {

	const formId = "form_project_creation_dialog";
	const [isSubmitting, setIsSubmitting] = useState(false);


	const handleCreated = useCallback(project => {
		onClose();
		onCreated(project);
	}, [onClose, onCreated]);


	return (
		<Dialog
			disabled={isSubmitting}
			submitting={isSubmitting}
			open={open}
			onClose={onClose}
			okButtonForm={formId}
			okButtonType="submit"
			size="tiny"
			title="New Project">
			<ProjectCreationForm
				customerId={customerId}
				formId={formId}
				onCreated={handleCreated}
				onSubmitting={setIsSubmitting} />
		</Dialog>
	);

});

export default ProjectCreationDialog;
