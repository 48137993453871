import CustomerCreationForm from "./CustomerCreationForm.js";
import View from "Views/View.js";
import {useNavigate} from "react-router-dom";

const CustomerCreationView = () => {

	const navigate = useNavigate();

	return (
		<View
			label="New Customer"
			narrow={true}>
			<CustomerCreationForm
				onCreated={customer => navigate(`/customers/${customer.Id}`)} />
		</View>
	);

};

export default CustomerCreationView;
