import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import useForm from "Hooks/useForm.js";
import Flex from "Components/Flex.js";
import Loadable from "Components/Loadable.js";
import SubduedStateLabel from "Components/SubduedStateLabel.js";
import ToastStore from "Toasts/ToastStore.js";
import View from "Views/View.js";
import scss from "./SystemSettingsView.module.scss";
import {useCallback, useEffect, useState} from "react";
import {Button, Form} from "semantic-ui-react";

const SystemSettingsView = () => {

	/**
	 * Form state
	 */
	const form = useForm();

	/**
	 * Fetch the settings data
	 */
	const fetch = useAsync(useCallback(() => {
		return api({url: `/settings`}).then(({data}) => data);
	}, []));

	/**
	 * Submitting?
	 */
	const [isSubmitting, setIsSubmitting] = useState(false);


	/**
	 * Changed the "working hours per day" input value.
	 * 
	 * (We have to convert this to minutes for storage.)
	 */
	const handleChangeWorkingHoursPerDay = useCallback(e => {

		let val = e.target.value;

		if (val !== "") {
			val = (parseFloat(e.target.value) * 60);
		}
		else val = null;

		form.setState({
			...form.state,
			WorkingMinutesPerDay: val
		});

	}, [form]);


	/**
	 * Submitting!
	 */
	const handleSubmit = useCallback(async () => {

		setIsSubmitting(true);

		try {

			await api({
				url: `/settings`,
				method: "PUT",
				data: {...form.state}
			});

			ToastStore.success("Settings saved.");

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [form.state]);


	/**
	 * Update the form state when our data loads
	 */
	useEffect(() => {
		if (fetch.result && !form.state) {
			form.setState({...fetch.result});
		}
	}, [fetch.result, form]);


	/**
	 * Render!
	 */
	return (
		<View
			narrow={true}
			label="System Settings">
			<Flex
				gap={0.5}>
				<SubduedStateLabel
					content="These settings apply generally across the application." />
				<Loadable
					loading={fetch.loading}
					error={fetch.error}
					onErrorRetry={fetch.call}>
					<Form
						onSubmit={handleSubmit}>
						<Flex
							gap={1}>
							<div
								className={scss.input}>
								<SubduedStateLabel
									content="This date is used as the fallback Production Start Date when scheduling a Project to the Production Schedule (e.g. when scheduling the very first Project)." />
							</div>
							<div
								className={scss.input}>
								<Form.Input
									disabled={isSubmitting}
									label="Working Hours Per Day"
									placeholder="Working Hours Per Day"
									onChange={handleChangeWorkingHoursPerDay}
									required={true}
									min={0}
									step={0.5}
									type="number"
									value={((form.state?.WorkingMinutesPerDay !== null) ? (Math.round(((form.state?.WorkingMinutesPerDay || 0) / 60) * 100) / 100) : "")} />
								<SubduedStateLabel
									content="This value sets the maximum total Task duration that can be scheduled to each Staff Member per day, after all tea/lunch breaks and rests. It is overridden by per-Staff Member values." />
							</div>
							<Button
								className={scss.btn__submit}
								content="Save"
								disabled={isSubmitting}
								icon="check"
								loading={isSubmitting}
								primary={true}
								type="submit" />
						</Flex>
					</Form>
				</Loadable>
			</Flex>
		</View>
	);

};

export default SystemSettingsView;
