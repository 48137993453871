import scss from "./DetailsPanel.module.scss";
import {memo} from "react";
import {Header} from "semantic-ui-react";

const DetailsPanel = memo(props => {

	return (
		<details open={props.defaultOpen}>
			<summary className={scss.summary}>
				<Header
					className={scss.header}
					content={props.label}
					sub={true} />
			</summary>
			<div className={scss.content}>
				{props.children}
			</div>
		</details>
	);

});

export default DetailsPanel;
