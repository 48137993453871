import api from "api.js";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useState} from "react";
import {useNavigate} from "react-router-dom";

const CustomerDeletionDialog = memo(({
	customerId,
	open,
	onClose
}) => {

	const navigate = useNavigate();

	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleDelete = useCallback(async () => {

		setIsSubmitting(true);

		try {

			await api({
				url: `/customers/${customerId}`,
				method: "DELETE"
			});

			navigate(`/customers`);

			onClose();
			ToastStore.success("Customer deleted successfully.");

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [customerId, navigate, onClose]);

	return (
		<Dialog
			onClose={onClose}
			onOk={handleDelete}
			open={open}
			submitting={isSubmitting}
			size="tiny"
			title="Delete Customer"
			content={
				<>
					<p>Are you sure you want to delete this Customer?</p>
					<p>The Customer, their Projects, and all other associated data will be permanently deleted.</p>
				</>
			} />
	);

});

export default CustomerDeletionDialog;
