import api from "api.js";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useState} from "react";

const BuildTaskStepDeletionDialog = memo(({
	open,
	onClose,
	onDeleted,
	stepId
}) => {

	const [isSubmitting, setIsSubmitting] = useState(false);


	const handleSubmit = useCallback(async () => {

		setIsSubmitting(true);

		try {

			await api({
				url: `/build/tasks/steps/${stepId}`,
				method: "DELETE"
			});

			onDeleted(stepId);

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [stepId, onDeleted]);


	return (
		<Dialog
			open={open}
			onClose={onClose}
			onOk={handleSubmit}
			submitting={isSubmitting}
			size="tiny"
			title="Delete Task Step"
			content={
				<>
					<p>Are you sure you want to delete this Task Step?</p>
					<p>It will be removed from any current Project Revisions that reference it.</p>
					<p>No Tasks already added to the Production Schedule will be affected.</p>
				</>
			} />
	);

});

export default BuildTaskStepDeletionDialog;
