import api from "api.js";
import DepartmentPicker from "Departments/DepartmentPicker.js";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useState} from "react";

const AddStaffMemberToDepartmentDialog = memo(({
	open,
	onAdded,
	onClose,
	staffMemberId
}) => {

	const [department, setDepartment] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);


	const handleSubmit = useCallback(async () => {

		if (!department) {
			ToastStore.error("Please select a Department.");
			return;
		}

		setIsSubmitting(true);

		try {

			await api({
				url: `/departments/${department.Id}/staff/${staffMemberId}`,
				method: "POST"
			});

			onAdded(department);
			onClose();

		}
		catch (e) {
			if (e.response?.status === 409) {
				ToastStore.error("The Staff Member is already in the selected Department.");
			}
			else ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [department, staffMemberId, onAdded, onClose]);


	return (
		<Dialog
			noScrolling={true}
			open={open}
			onClose={onClose}
			onOk={handleSubmit}
			submitting={isSubmitting}
			size="tiny"
			title="Add Staff Member to Department">
			<DepartmentPicker
				fluid={true}
				onChange={setDepartment}
				value={department?.Id} />
		</Dialog>
	);

});

export default AddStaffMemberToDepartmentDialog;
