import ErrorFallback from "./ErrorFallback.js";
import {memo} from "react";
import * as Sentry from "@sentry/react";

const ErrorBoundary = memo(({children, fallback, onCatch, withRefreshButton}) => {

	return (
		<Sentry.ErrorBoundary
			fallback={((fallback !== null) ? (fallback || <ErrorFallback withRefreshButton={withRefreshButton} />) : null)}
			onError={onCatch}>
			{children}
		</Sentry.ErrorBoundary>
	);

});

export default ErrorBoundary;
