import {useCallback, useMemo} from "react";
import {useSelector} from "react-redux";

const usePermissions = () => {

	const permissions = useSelector(store => (store.permissions || []));

	const hasPermission = useCallback(permission => {
		return (
			permissions.includes(permission) ||
			permissions.includes("Administrator")
		);
	}, [permissions]);

	return useMemo(() => {
		return {
			permissions,
			hasPermission
		};
	}, [
		permissions,
		hasPermission
	]);

};

export default usePermissions;
