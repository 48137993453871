import CustomerType from "./CustomerType.js";
import EnumPicker from "Components/EnumPicker.js";
import {memo} from "react";

const CustomerTypePicker = memo(props => {

	return (
		<EnumPicker
			enumClass={CustomerType}
			label="Customer Type"
			{...props} />
	);

});

export default CustomerTypePicker;
