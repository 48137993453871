import Flex from "Components/Flex.js";
import scss from "./Pane.module.scss";
import {memo} from "react";
import {Header, Segment} from "semantic-ui-react";

const Pane = memo(({children, label, actions}) => {

	return (
		<Segment
			className={scss.root}>
			<Flex
				className={scss.content}
				gap={0.5}>
				{
					(label || actions) &&
						<div
							className={scss.header}>
							{
								label &&
									<Header
										className={scss.header__label}
										content={label}
										sub={true} />
							}
							{
								actions &&
									<div>
										{actions}
									</div>
							}
						</div>
				}
				{children}
			</Flex>
		</Segment>
	);

});

export default Pane;
