import api from "api.js";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useState} from "react";

const StaffMemberArchiveDialog = memo(({
	open,
	onClose,
	onArchived,
	staffMemberId
}) => {

	const [isSubmitting, setIsSubmitting] = useState(false);


	const handleSubmit = useCallback(async () => {

		setIsSubmitting(true);

		try {

			await api({
				url: `/staff/${staffMemberId}/archive`,
				method: "POST"
			});

			onArchived(staffMemberId);
			onClose();

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [onArchived, onClose, staffMemberId]);


	return (
		<Dialog
			open={open}
			onClose={onClose}
			onOk={handleSubmit}
			submitting={isSubmitting}
			size="tiny"
			title="Archive Staff Member"
			content="Are you sure you want to archive this staff member?" />
	);

});


export default StaffMemberArchiveDialog;
