import Flex from "Components/Flex.js";
import ProjectCreationForm from "./ProjectCreationForm.js";
import UriButton from "Components/UriButton.js";
import View from "Views/View.js";
import {useCallback, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Divider} from "semantic-ui-react";

const ProjectCreationView = () => {

	const formId = "form_project_creation_view";

	const navigate = useNavigate();
	const [isSubmitting, setIsSubmitting] = useState(false);


	const handleCreated = useCallback(project => {
		navigate(`/projects/${project.Id}`);
	}, [navigate]);


	return (
		<View
			label="New Project"
			narrow={true}>
			<ProjectCreationForm
				formId={formId}
				onCreated={handleCreated}
				onSubmitting={setIsSubmitting} />
			<Divider
				hidden={true} />
			<Flex
				columnar={true}
				gap={0.25}>
				<Button
					icon="check"
					content="Save"
					form={formId}
					disabled={isSubmitting}
					loading={isSubmitting}
					primary={true}
					type="submit" />
				<UriButton
					disabled={isSubmitting}
					content="Cancel"
					uri="/projects" />
			</Flex>
		</View>
	);

};

export default ProjectCreationView;
