import api from "api.js";
import CustomerStatusPicker from "./CustomerStatusPicker.js";
import CustomerTypePicker from "./CustomerTypePicker.js";
import Flex from "Components/Flex.js";
import SubduedStateLabel from "Components/SubduedStateLabel.js";
import ToastStore from "Toasts/ToastStore.js";
import UriButton from "Components/UriButton.js";
import useForm from "Hooks/useForm.js";
import {memo, useCallback, useState} from "react";
import {Button, Divider, Form} from "semantic-ui-react";

const CustomerCreationForm = memo(({
	formId,
	hideControls,
	onCreated,
	onSubmitting
}) => {

	const form = useForm({
		Name: "",
		Type: "Business",
		Status: "Active"
	});

	const [isSubmitting, setIsSubmitting] = useState(false);


	const handleSubmittingChange = useCallback(isSubmitting => {
		onSubmitting?.(isSubmitting);
		setIsSubmitting(isSubmitting);
	}, [onSubmitting]);


	const handleSubmit = useCallback(async e => {

		e.preventDefault();

		handleSubmittingChange(true);

		try {

			const result = await api({
				url: `/customers`,
				method: "POST",
				data: {...form.state}
			}).then(({data}) => data);

			onCreated(result);

		}
		catch (e) {
			if (e.response?.status === 409) {
				ToastStore.error("The Customer name you've entered already exists.");
			}
			else ToastStore.error(e);
		}

		handleSubmittingChange(false);

	}, [form, onCreated, handleSubmittingChange]);


	return (
		<Form
			id={(formId || form.id)}
			onSubmit={handleSubmit}>
			<SubduedStateLabel
				content="Please enter the following essential details to create a new customer." />
			<SubduedStateLabel
				content="You can complete the customer's full profile, including addresses and contact details, after initial creation." />
			<Divider hidden={true} />
			<Form.Input
				autoFocus={true}
				disabled={isSubmitting}
				label="Customer Name"
				name="Name"
				maxLength={255}
				onChange={form.updateStateFromSemanticInputChangeEvent}
				placeholder="Customer Name"
				required={true}
				value={(form.state.Name || "")} />
			<CustomerTypePicker
				disabled={isSubmitting}
				label="Customer Type"
				name="Type"
				onChange={form.updateProp}
				required={true}
				value={form.state.Type} />
			<CustomerStatusPicker
				disabled={isSubmitting}
				label="Customer Status"
				name="Status"
				onChange={form.updateProp}
				required={true}
				value={form.state.Status} />
			{
				!hideControls &&
					<>
						<Divider hidden={true} />
						<Flex
							columnar={true}
							gap={0.25}>
							<Button
								icon="check"
								content="Save"
								disabled={isSubmitting}
								loading={isSubmitting}
								primary={true} />
							<UriButton
								disabled={isSubmitting}
								content="Cancel"
								uri="/customers" />
						</Flex>
					</>
			}
		</Form>
	);

});

export default CustomerCreationForm;
