import api from "api.js";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useState} from "react";
import {useNavigate} from "react-router-dom";

const DepartmentDeletionDialog = memo(({
	open,
	onClose,
	departmentId
}) => {

	const navigate = useNavigate();

	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleDelete = useCallback(async () => {

		setIsSubmitting(true);

		try {

			await api({
				url: `/departments/${departmentId}`,
				method: "DELETE"
			});

			navigate(`/departments`);

			onClose();
			ToastStore.success("Department deleted successfully.");

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [departmentId, navigate, onClose]);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			onOk={handleDelete}
			submitting={isSubmitting}
			size="tiny"
			title="Delete Department"
			content="Are you sure you want to permanently delete this Department?" />
	);

});

export default DepartmentDeletionDialog;
