import Enum from "Includes/Enum.js";

/**
 * Project assignee type definitions
 * 
 * @package RedSky
 * @subpackage Projects
 * @author Heron Web Ltd
 * @copyright Red Sky Shepherds Huts Limited
 */
class ProjectAssigneeType extends Enum {

	static RedSky = 1;

	static Customer = 2;

	static Other = 3;

	static getLabel(name) {
		return ({
			RedSky: "Red Sky"
		}[name] || name);
	}

}

export default ProjectAssigneeType;
