import {memo} from "react";

const Flex = memo(props => {

	return (
		<div
			className={props.className}
			style={{
				alignContent: props.alignContent,
				alignItems: props.alignItems,
				alignSelf: props.alignSelf,
				display: (!props.hidden ? "flex" : "none"),
				flexBasis: props.flexBasis,
				flexDirection: (!props.columnar ? "column" : "row"),
				flexWrap: (props.wrap ? "wrap" : undefined),
				gap: `${(((props.gap !== undefined) ? props.gap : 1) * 1.5)}rem`,
				justifyContent: props.justifyContent,
				justifyItems: props.justifyItems,
				justifySelf: props.justifySelf,
				margin: props.margin,
				maxHeight: props.maxHeight,
				maxWidth: props.maxWidth,
				...props.style
			}}>
			{props.children}
		</div>
	);

});

export default Flex;
