import * as Sentry from "@sentry/react";

const initSentry = () => {

	const dsn = process.env.REACT_APP_SENTRY_DSN;

	if (dsn) {
		Sentry.init({
			dsn,
			release: process.env.REACT_APP_RELEASE_ID
		});
	}

};

export default initSentry;
