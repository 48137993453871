import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import Picker from "Components/Picker.js";
import {memo, useCallback} from "react";
import {Label} from "semantic-ui-react";

const ProjectBuildRevisionPicker = memo(props => {

	const fetch = useAsync(useCallback(() => {
		return (
			props.project ?
				api({
					url: `/projects/${props.project}/build/revisions`
				}).then(({data}) => data) :
				(props.revisions || [])
		);
	}, [props.project, props.revisions]));

	const propsToForward = {...props};
	delete propsToForward.project;
	delete propsToForward.revisions;

	return (
		<Picker
			loading={fetch.loading}
			error={fetch.error}
			label="Project Revision"
			noResultsMessage="No Project Revisions available."
			options={(fetch.result || [])}
			getOptionLabel={rev => `Project Revision #${rev.RevisionIndex}`}
			getOptionChip={rev => {
				return (
					<Label
						content={(rev.Active ? "Active" : "Superseded")}
						color={(rev.Active ? "blue" : "grey")}
						size="tiny" />
				);
			}}
			{...propsToForward} />
	);

});

export default ProjectBuildRevisionPicker;
