import Store from "App/Redux/Store.js";

const dAuth = result => {
	Store.dispatch({
		type: "update",
		data: {
			auth: (result?.auth || {}),
			permissions: (result?.permissions || []),
			user: (result?.user || {})
		}
	});
};

export default dAuth;
