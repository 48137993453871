import scss from "./ProductionScheduleViewDateControl.module.scss";
import {memo} from "react";
import {Icon} from "semantic-ui-react";

const ProductionScheduleViewDateControl = memo(({
	label,
	dayDiff,
	onMoveDays,
	controlsHidden
}) => {

	return (
		<div className={scss.root}>
			{
				!controlsHidden &&
					<Icon
						className={scss.icon}
						name="arrow left"
						onClick={() => onMoveDays(-dayDiff)} />
			}
			<span>{label}</span>
			{
				!controlsHidden &&
					<Icon
						className={scss.icon}
						name="arrow right"
						onClick={() => onMoveDays(dayDiff)} />
			}
		</div>
	);

});

export default ProductionScheduleViewDateControl;
