import api from "api.js";
import ArchiveDialog from "./StaffMemberArchiveDialog.js";
import DeletionDialog from "./StaffMemberDeletionDialog.js";
import DetailsPanel from "Components/DetailsPanel.js";
import Flex from "Components/Flex.js";
import PaneHeader from "Components/PaneHeader.js";
import SubduedStateLabel from "Components/SubduedStateLabel.js";
import ToastStore from "Toasts/ToastStore.js";
import scss from "./StaffMemberDetailsPane.module.scss";
import useForm from "Hooks/useForm.js";
import useValue from "Hooks/useValue.js";
import {memo, useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Button, Form, Label, Message, Segment} from "semantic-ui-react";

const StaffMemberDetailsPane = memo(({
	staffMember,
	onChangeStaffMember
}) => {

	const archiveDialogOpen = useValue(false);
	const deletionDialogOpen = useValue(false);
	const [isUnarchiving, setIsUnarchiving] = useState(false);

	const [hasEditChanges, setHasEditChanges] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);


	const form = useForm({
		...staffMember
	}, useCallback(() => {
		setHasEditChanges(true);
	}, []));


	const handleArchive = useCallback(() => {
		onChangeStaffMember({
			...staffMember,
			Archived: true
		});
	}, [staffMember, onChangeStaffMember]);


	const handleUnarchive = useCallback(async () => {

		setIsUnarchiving(true);

		try {

			await api({
				url: `/staff/${staffMember.Id}/unarchive`,
				method: "POST"
			});

			onChangeStaffMember({
				...staffMember,
				Archived: false
			});

			ToastStore.success("Restore completed successfully.");

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsUnarchiving(false);

	}, [staffMember, onChangeStaffMember]);


	const handleChangeWorkingHoursPerDay = useCallback(e => {

		let val = e.target.value;
		if (val === "") val = null;
		else val = (parseFloat(e.target.value) * 60);

		form.setState({
			...form.state,
			WorkingMinutesPerDay: val
		});

		setHasEditChanges(true);

	}, [form]);


	const handleSubmit = useCallback(async e => {

		e.preventDefault();

		setIsSubmitting(true);

		try {

			const result = await api({
				url: `/staff/${staffMember.Id}`,
				method: "PUT",
				data: {
					Name: form.state.Name,
					Title: form.state.Title,
					WorkingMinutesPerDay: form.state.WorkingMinutesPerDay,
					ProjectAssigneeEnabled: !!form.state.ProjectAssigneeEnabled,
					ProductionScheduleDisplayEnabled: !!form.state.ProductionScheduleDisplayEnabled
				}
			}).then(({data}) => data);

			setHasEditChanges(false);
			onChangeStaffMember(result);

		}
		catch (e) {
			if (e.response?.status === 409) {
				ToastStore.error("The Staff Member name you've entered already exists.");
			}
			else ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [form.state, staffMember, onChangeStaffMember]);


	useEffect(() => {
		if (staffMember.Id !== form.state.Id) {
			form.setState({...staffMember});
			setHasEditChanges(false);
		}
	}, [form, staffMember]);


	return (
		<Segment>
			<Form
				onSubmit={handleSubmit}>
				<PaneHeader
					buttonDisabled={(!hasEditChanges || isSubmitting)}
					buttonIcon="check"
					buttonLabel="Save Changes"
					buttonType={(!staffMember.Archived ? "submit" : undefined)}
					chip={
						staffMember.Archived &&
							<Label
								content="Archived"
								color="orange" />
					}
					label={staffMember.Name} />
				<Flex
					gap={0.5}>
					{
						staffMember.Archived &&
							<Message
								className={scss.message}
								header="Archived staff member"
								content="Details cannot be edited."
								warning={true}
								visible={true} />
					}
					<DetailsPanel
						defaultOpen={true}
						label="Personal Details">
						<Form.Input
							disabled={isSubmitting}
							readOnly={staffMember.Archived}
							label="Name"
							maxLength={255}
							name="Name"
							onChange={form.updateStateFromSemanticInputChangeEvent}
							placeholder="Name"
							required={true}
							value={form.state.Name} />
						<Form.Input
							disabled={isSubmitting}
							readOnly={staffMember.Archived}
							label="Title"
							maxLength={255}
							name="Title"
							onChange={form.updateStateFromSemanticInputChangeEvent}
							placeholder="Title"
							required={true}
							value={form.state.Title} />
						<Form.Checkbox
							checked={form.state.ProjectAssigneeEnabled}
							disabled={isSubmitting}
							readOnly={staffMember.Archived}
							label="Include in Project Assignee options"
							name="ProjectAssigneeEnabled"
							onChange={form.handleCheckboxToggle} />
					</DetailsPanel>
					<DetailsPanel
						defaultOpen={true}
						label="Production Schedule">
						<Form.Checkbox
							checked={form.state.ProductionScheduleDisplayEnabled}
							disabled={isSubmitting}
							readOnly={staffMember.Archived}
							label="Display on the Production Schedule"
							name="ProductionScheduleDisplayEnabled"
							onChange={form.handleCheckboxToggle} />
						<Form.Input
							disabled={isSubmitting}
							readOnly={staffMember.Archived}
							label="Working Hours Per Day"
							placeholder="Working Hours Per Day"
							onChange={handleChangeWorkingHoursPerDay}
							min={0}
							step={0.5}
							type="number"
							value={((form.state.WorkingMinutesPerDay !== null) ? (Math.round((((form.state.WorkingMinutesPerDay || 0) * 100) / 60)) / 100) : "")} />
						<SubduedStateLabel
							className={scss.input__caption}
							content={<>The maximum total Task duration that can be scheduled to this Staff Member per day, after all tea/lunch breaks and rests. Leave blank to use the <Link to="/system">system default value</Link>.</>} />
					</DetailsPanel>
					<DetailsPanel
						defaultOpen={staffMember.Archived}
						label="Archive / Delete">
						<Flex
							gap={0.5}>
							<div>
								{
									!staffMember.Archived ?
										<Button
											basic={true}
											content="Archive this Staff Member"
											disabled={(isSubmitting || isUnarchiving)}
											icon="archive"
											negative={true}
											onClick={archiveDialogOpen.setTrue}
											size="tiny"
											type="button" /> :
										<>
											<SubduedStateLabel
												content="This staff member has been archived." />
											<Button
												basic={true}
												content="Restore"
												disabled={(isSubmitting || isUnarchiving)}
												loading={isUnarchiving}
												icon="redo"
												onClick={handleUnarchive}
												size="tiny"
												type="button" />
										</>
								}
							</div>
							<div>
								<Button
									basic={true}
									content="Delete this Staff Member"
									disabled={(isSubmitting || isUnarchiving)}
									icon="trash"
									negative={true}
									onClick={deletionDialogOpen.setTrue}
									size="tiny"
									type="button" />
							</div>
						</Flex>
					</DetailsPanel>
				</Flex>
			</Form>
			<ArchiveDialog
				open={archiveDialogOpen.value}
				onClose={archiveDialogOpen.setFalse}
				onArchived={handleArchive}
				staffMemberId={staffMember.Id} />
			<DeletionDialog
				open={deletionDialogOpen.value}
				onClose={deletionDialogOpen.setFalse}
				staffMemberId={staffMember.Id} />
		</Segment>
	);

});

export default StaffMemberDetailsPane;
