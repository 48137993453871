import Dialog from "Components/Dialog.js";
import TaskForm from "./TaskForm.js";
import {memo, useCallback, useState} from "react";

const TaskEditorDialog = memo(({
	open,
	onClose,
	onSubmitted,
	project,
	task
}) => {

	const formId = "rsh_task_editor_dialog_form";
	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleSubmit = useCallback(task => {
		onClose();
		onSubmitted(task);
	}, [onClose, onSubmitted]);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			okButtonForm={formId}
			okButtonType="submit"
			submitting={isSubmitting}
			size="small"
			title={(task?.Title || `${(project.Name || project.Customer.Name)} — New Task`)}>
			<TaskForm
				task={task}
				formId={formId}
				projectId={(task?.Project?.Id || task?.Project || project.Id)}
				onSubmitted={handleSubmit}
				onSubmittingChange={setIsSubmitting} />
		</Dialog>
	);

});

export default TaskEditorDialog;
