/**
 * Utility method to sort Build Database resources in a 
 * consistent way that matches the backend's default sort
 * 
 * @param {Array<Object>} resources
 * @return {Array<Object>}
 */
const sortBuildResources = resources => {
	return [...(resources || [])].sort((a, b) => {
		if (a.BuildOrderIndex === b.BuildOrderIndex) {
			if (a.Id === b.Id) {
				const ain = resources.indexOf(a);
				const bin = resources.indexOf(b);
				return ((ain > bin) ? 1 : -1);
			}
			else return ((a.Id > b.Id) ? 1 : -1);
		}
		else return ((a.BuildOrderIndex > b.BuildOrderIndex) ? 1 : -1);
	});
};

export {sortBuildResources};
