import {memo, useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "semantic-ui-react";

const UriButton = memo(props => {

	const navigate = useNavigate();
	const {uri} = props;

	const handleClick = useCallback(e => {
		e.preventDefault();
		navigate(uri);
	}, [navigate, uri]);

	return (
		<Button
			{...props}
			onClick={handleClick}
			type="button" />
	);

});

export default UriButton;
