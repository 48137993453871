import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import BuildRevisionsPane from "./BuildRevisions/ProjectBuildRevisionsPane.js";
import Panes from "Components/Panes.js";
import ProjectDetailsPane from "./ProjectDetailsPane.js";
import View from "Views/View.js";
import {memo, useCallback, useState} from "react";
import {useParams} from "react-router-dom";

const ProjectView = memo((() => {

	const {Id} = useParams();
	const [selectedBuildRevisionId, setSelectedBuildRevisionId] = useState(null);


	const projectFetch = useAsync(useCallback(() => {
		return api({url: `/projects/${Id}`}).then(({data}) => data);
	}, [Id]));


	const handleRevisionApproved = useCallback(approvedProject => {
		projectFetch.setResult(approvedProject);
	}, [projectFetch]);


	return (
		<View
			loading={projectFetch.loading}
			error={projectFetch.error}
			onErrorRetry={projectFetch.call}>
			<Panes>
				<ProjectDetailsPane
					onUpdateProject={projectFetch.setResult}
					project={projectFetch.result} />
				<BuildRevisionsPane
					project={projectFetch.result}
					onApproveRevision={handleRevisionApproved}
					onSelectRevision={setSelectedBuildRevisionId}
					selectedRevisionId={selectedBuildRevisionId} />
			</Panes>
		</View>
	);

}));

export default ProjectView;
