import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import BuildRevisionApprovalDialog from "./ProjectBuildRevisionApprovalDialog.js";
import BuildRevisionDeletionDialog from "./ProjectBuildRevisionDeletionDialog.js";
import BuildRevisionBuildItemLinkCreationDialog from "./ProjectBuildRevisionBuildItemLinkCreationDialog.js";
import BuildRevisionBuildItemLinkDeletionDialog from "./ProjectBuildRevisionBuildItemLinkDeletionDialog.js";
import CorrespondenceCard from "./Correspondence/CorrespondenceCard.js";
import CorrespondenceEditorDialog from "./Correspondence/CorrespondenceEditorDialog.js";
import DataTable from "Components/DataTable.js";
import DetailsPanel from "Components/DetailsPanel.js";
import Flex from "Components/Flex.js";
import Loadable from "Components/Loadable.js";
import PaneHeader from "Components/PaneHeader.js";
import ProjectStatus from "Projects/ProjectStatus.js";
import scss from "./ProjectsBuildRevisionsPaneContent.module.scss";
import ToastStore from "Toasts/ToastStore.js";
import useValue from "Hooks/useValue.js";
import {memo, useCallback, useEffect, useMemo, useState} from "react";
import {Button, Form, Input, Label} from "semantic-ui-react";

const ProjectsBuildRevisionsPaneContent = memo(({
	project,
	revision,
	onApproved,
	onCreated,
	onDeleted,
	onMakeActive,
	onUpdated
}) => {

	const approvalDialogOpen = useValue(false);
	const deletionDialogOpen = useValue(false);
	const newBuildItemDialogOpen = useValue(false);
	const buildItemLinkDeletionTarget = useValue(null);

	const correspondenceEditorDialogOpen = useValue(false);
	const sortCorrespondenceOldestFirst = useValue(false);

	const [editState, setEditState] = useState({...revision});
	const [editStateBuildItems, setEditStateBuildItems] = useState(null);

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isSubmittingLinkChange, setIsSubmittingLinkChange] = useState(false);

	const isMutable = (project.Status.Id === ProjectStatus.Created);

	const buildItems = useAsync(useCallback(() => {
		return api({
			url: `/projects/build/revisions/${revision.Id}/items`
		}).then(({data}) => {

			const editMap = {};

			for (const link of data.ItemLinks) {
				editMap[link.Id] = link;
			}

			setEditStateBuildItems(editMap);

			return data;

		});
	}, [revision.Id]));


	const correspondenceFetch = useAsync(useCallback(() => {
		return api({
			url: `/projects/build/revisions/${revision.Id}/correspondence`
		}).then(({data}) => data);
	}, [revision.Id]));


	const handleApproved = useCallback(approvedProject => {
		approvalDialogOpen.setFalse();
		onApproved?.(approvedProject);
	}, [approvalDialogOpen, onApproved]);


	const handleBuildItemLinkCreated = useCallback((link, buildItem) => {

		const updatedItems = [...(buildItems.result.Items || [])];

		if (!updatedItems.find(i => (i.Id === buildItem.Id))) {
			updatedItems.push(buildItem);
		}

		buildItems.setResult({
			...buildItems.result,
			ItemLinks: [
				...(buildItems.result.ItemLinks || []),
				link
			],
			Items: updatedItems
		});

		setEditStateBuildItems({
			...editStateBuildItems,
			[link.Id]: link
		});

	}, [buildItems, editStateBuildItems]);


	const handleBuildItemLinkDeleted = useCallback(linkId => {

		buildItems.setResult({
			...buildItems.result,
			ItemLinks: buildItems.result.ItemLinks.filter(i => (i.Id !== linkId))
		});

		buildItemLinkDeletionTarget.setNull();

	}, [buildItems, buildItemLinkDeletionTarget]);


	const handleBuildItemLinkQtyChange = useCallback((e, {name, value}) => {
		setEditStateBuildItems({
			...editStateBuildItems,
			[name]: {
				...editStateBuildItems[name],
				Quantity: value
			}
		});
	}, [editStateBuildItems]);


	const handleBuildItemLinkQtySave = useCallback(async e => {

		const linkId = parseInt(e.target.name);

		const link = buildItems.result.ItemLinks.find(i => (i.Id === linkId));
		const updatedQty = (parseInt(editStateBuildItems[linkId].Quantity) || 0);

		/** This is here so we can e.g. transform "" back to 0, etc. */
		setEditStateBuildItems({
			...editStateBuildItems,
			[linkId]: {
				...editStateBuildItems[linkId],
				Quantity: updatedQty
			}
		});

		if (updatedQty === 0) {
			ToastStore.error("Quantity must be greater than 0.");
		}

		if (!link || !updatedQty || (updatedQty === link.Quantity)) {
			return;
		}

		setIsSubmittingLinkChange(true);

		try {

			await api({
				url: `/projects/build/revisions/items/${linkId}`,
				method: "PUT",
				data: {
					BuildItem: link.BuildItem,
					Quantity: updatedQty
				}
			});

			const updatedLinks = [...buildItems.result.ItemLinks];
			const linkIndex = updatedLinks.indexOf(link);
			updatedLinks[linkIndex] = {...link, Quantity: updatedQty};

			buildItems.setResult({
				...buildItems.result,
				ItemLinks: updatedLinks
			});

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmittingLinkChange(false);

	}, [buildItems, editStateBuildItems]);


	const handleClone = useCallback(async () => {

		setIsSubmitting(true);

		try {

			const result = await api({
				url: `/projects/build/revisions/${revision.Id}/clone`,
				method: "POST"
			}).then(({data}) => data);

			onCreated(result.Revision);

			if (!result.AllItemLinksCloned) {
				ToastStore.warning("Project Revision copied successfully, but not all Project Item links could be included.");
			}

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [revision, onCreated]);


	const handleMakeActive = useCallback(async () => {

		setIsSubmitting(true);

		try {

			await api({
				url: `/projects/build/revisions/${revision.Id}`,
				method: "PUT",
				data: {
					Active: true,
					Notes: revision.Notes
				}
			});

			onMakeActive(revision.Id);

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [revision, onMakeActive]);


	const handleSaveNotes = useCallback(async () => {

		setIsSubmitting(true);

		try {

			const Notes = (editState.Notes || null);

			await api({
				url: `/projects/build/revisions/${revision.Id}`,
				method: "PUT",
				data: {
					Active: revision.Active,
					Notes
				}
			});

			onUpdated({...revision, Notes}, revision);

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [revision, editState, onUpdated]);


	const handleSetBuildItemLinkDeletionTarget = useCallback(link => {
		buildItemLinkDeletionTarget.set(link);
	}, [buildItemLinkDeletionTarget]);


	const handleUpdateEditState = useCallback((e, {name, value}) => {
		setEditState({
			...editState,
			[name]: value
		});
	}, [editState]);


	const buildItemsMap = useMemo(() => {

		const map = {};

		for (const buildItem of (buildItems.result?.Items || [])) {
			map[buildItem.Id] = buildItem;
		}

		return map;

	}, [buildItems.result?.Items]);


	const buildItemsTableFields = useMemo(() => {
		return [
			{
				label: "",
				render: i => (
					<Button
						basic={true}
						disabled={(isSubmitting || isSubmittingLinkChange)}
						icon="trash alternate"
						onClick={() => handleSetBuildItemLinkDeletionTarget(i)}
						size="mini" />
				),
				width: "3.8rem",
				align: "center",
				hidden: !isMutable
			},
			{
				label: "Qty.",
				render: i => (
					<Input
						className={scss.input__qty}
						disabled={(isSubmitting || isSubmittingLinkChange)}
						name={i.Id}
						min={1}
						onBlur={handleBuildItemLinkQtySave}
						onChange={handleBuildItemLinkQtyChange}
						readOnly={!isMutable}
						transparent={true}
						type="number"
						value={editStateBuildItems?.[i.Id]?.Quantity} />
				),
				width: "4.5rem",
				align: "center"
			},
			{
				label: "Item",
				render(i) {
					return (buildItemsMap[i.BuildItem]?.Name || "(Unknown)");
				},
				cellStyles: {
					display: "flex",
					alignItems: "center"
				}
			}
		];
	}, [
		buildItemsMap,
		editStateBuildItems,
		handleBuildItemLinkQtyChange,
		handleBuildItemLinkQtySave,
		handleSetBuildItemLinkDeletionTarget,
		isMutable,
		isSubmitting,
		isSubmittingLinkChange
	]);


	useEffect(() => {
		setEditState({...revision});
	}, [revision]);


	return (
		<Flex
			gap={0.5}>
			<PaneHeader
				actions={
					isMutable &&
						<>
							<Button
								basic={true}
								disabled={isSubmitting}
								icon="copy outline"
								onClick={handleClone}
								size="mini"
								title="Copy" />
							<Button
								basic={true}
								disabled={isSubmitting}
								icon="trash alternate"
								onClick={deletionDialogOpen.setTrue}
								size="mini"
								title="Delete" />
						</>
				}
				buttonDisabled={isSubmitting}
				chip={
					<Label
						content={(revision.Active ? "Active" : "Superseded")}
						color={(revision.Active ? "blue" : "grey")} />
				}
				label={`Project Revision #${revision.RevisionIndex}`} />
			{
				isMutable &&
					<div
						className={scss.actions}>
						<Button
							basic={true}
							disabled={isSubmitting}
							content={(revision.Active ? "Approve this Project Revision" : "Make this Project Revision the active one")}
							icon={(revision.Active ? "checked calendar" : "arrow circle up")}
							onClick={(revision.Active ? approvalDialogOpen.setTrue : handleMakeActive)}
							primary={true} />
					</div>
			}
			<DetailsPanel
				defaultOpen={true}
				label="Project Items">
				<Flex
					gap={0.5}>
					<DataTable
						data={buildItems}
						dataObjectsField="ItemLinks"
						emptyMessage="No Project Items"
						fields={buildItemsTableFields} />
					{
						isMutable &&
							<Button
								basic={true}
								className={scss.btn}
								disabled={isSubmitting}
								content="Add Project Item"
								icon="add"
								onClick={newBuildItemDialogOpen.setTrue}
								primary={true}
								size="tiny" />
					}
				</Flex>
			</DetailsPanel>
			<DetailsPanel
				defaultOpen={true}
				label="Notes">
				<Form
					onSubmit={handleSaveNotes}>
					<Flex
						gap={0.5}>
						<Form.TextArea
							className={scss.textarea__notes}
							disabled={isSubmitting}
							maxLength={65535}
							name="Notes"
							onChange={handleUpdateEditState}
							placeholder="Notes"
							value={(editState.Notes || "")} />
						<Button
							basic={true}
							className={scss.btn}
							disabled={isSubmitting}
							content="Save"
							icon="check"
							primary={true}
							size="tiny"
							type="submit" />
					</Flex>
				</Form>
			</DetailsPanel>
			<DetailsPanel
				defaultOpen={true}
				label="Correspondence">
				<Flex>
					<div
						className={scss.correspondence__controls}>
						<Button
							basic={true}
							content="Toggle Sort Order"
							icon="sort"
							onClick={sortCorrespondenceOldestFirst.toggleBoolean}
							size="tiny" />
						<Button
							basic={true}
							primary={true}
							disabled={(correspondenceFetch.loading || isSubmitting)}
							content="Add Correspondence Record"
							icon="add"
							size="tiny"
							onClick={correspondenceEditorDialogOpen.setTrue} />
					</div>
					<Loadable
						loading={correspondenceFetch.loading}
						error={correspondenceFetch.error}
						onErrorRetry={correspondenceFetch.call}
						emptyMessage="No records stored."
						empty={!correspondenceFetch.result?.length}>
						{
							(!sortCorrespondenceOldestFirst.value ? correspondenceFetch.result : [...(correspondenceFetch.result || [])].reverse())?.map((correspondence, key) => (
								<CorrespondenceCard
									correspondence={correspondence}
									project={project}
									revision={revision}
									key={key}
									onDeleted={correspondenceFetch.call}
									onEdited={correspondenceFetch.call} />
							))
						}
					</Loadable>
				</Flex>
			</DetailsPanel>
			{
				approvalDialogOpen.value &&
					<BuildRevisionApprovalDialog
						revisionId={revision.Id}
						open={approvalDialogOpen.value}
						onClose={approvalDialogOpen.setFalse}
						onApproved={handleApproved} />
			}
			{
				deletionDialogOpen.value &&
					<BuildRevisionDeletionDialog
						revisionId={revision.Id}
						open={deletionDialogOpen.value}
						onClose={deletionDialogOpen.setFalse}
						onDeleted={onDeleted} />
			}
			{
				newBuildItemDialogOpen.value &&
					<BuildRevisionBuildItemLinkCreationDialog
						revisionId={revision.Id}
						open={newBuildItemDialogOpen.value}
						onClose={newBuildItemDialogOpen.setFalse}
						onCreated={handleBuildItemLinkCreated} />
			}
			{
				buildItemLinkDeletionTarget.value &&
					<BuildRevisionBuildItemLinkDeletionDialog
						linkId={buildItemLinkDeletionTarget.value?.Id}
						open={!!buildItemLinkDeletionTarget.value}
						onClose={buildItemLinkDeletionTarget.setNull}
						onDeleted={handleBuildItemLinkDeleted} />
			}
			{
				correspondenceEditorDialogOpen.value &&
					<CorrespondenceEditorDialog
						project={project}
						revision={revision}
						open={correspondenceEditorDialogOpen.value}
						onClose={correspondenceEditorDialogOpen.setFalse}
						onSaved={correspondenceFetch.call} />
			}
		</Flex>
	);

});

export default ProjectsBuildRevisionsPaneContent;
