import api from "api.js";
import BuildItemTypes from "BuildDatabase/BuildItemTypes.js";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useState} from "react";

const BuildItemDeletionDialog = memo(({
	buildItem,
	open,
	onClose,
	onDeleted
}) => {

	const isProduct = (buildItem?.BuildItemType === BuildItemTypes.Product);
	const [isSubmitting, setIsSubmitting] = useState(false);


	const handleSubmit = useCallback(async () => {

		setIsSubmitting(true);

		try {

			const buildItemId = buildItem.Id;

			await api({
				url: `/build/items/${buildItemId}`,
				method: "DELETE"
			});

			onDeleted(buildItemId);

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [buildItem, onDeleted]);


	return (
		<Dialog
			open={open}
			onClose={onClose}
			onOk={handleSubmit}
			submitting={isSubmitting}
			size="tiny"
			title={`Delete Build ${(!isProduct ? "Item" : "Product")}`}
			content={
				<>
					<p>Are you sure you want to delete this Build {(!isProduct ? "Item" : "Product")}?</p>
					{(isProduct && <p>The Product will be removed from any current Project revisions that reference it.</p>)}
					{(!isProduct && <p>The Project Item will be removed from all Products and current Project Revisions.</p>)}
					<p>Existing tasks already added to the Production Schedule will not be affected.</p>
				</>
			} />
	);

});

export default BuildItemDeletionDialog;
