import api from "api.js";
import Dialog from "Components/Dialog.js";
import StaffMemberPicker from "StaffMembers/StaffMemberPicker.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useState} from "react";

const AddStaffMemberToDepartmentDialog = memo(({
	open,
	onAdded,
	onClose,
	departmentId
}) => {

	const [staffMember, setStaffMember] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);


	const handleSubmit = useCallback(async () => {

		if (!staffMember) {
			ToastStore.error("Please select a Staff Member.");
			return;
		}

		setIsSubmitting(true);

		try {

			await api({
				url: `/departments/${departmentId}/staff/${staffMember.Id}`,
				method: "POST"
			});

			onAdded(staffMember);
			onClose();

		}
		catch (e) {
			if (e.response?.status === 409) {
				ToastStore.error("The selected Staff Member is already in the Department.");
			}
			else ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [departmentId, staffMember, onAdded, onClose]);


	return (
		<Dialog
			noScrolling={true}
			open={open}
			onClose={onClose}
			onOk={handleSubmit}
			submitting={isSubmitting}
			size="tiny"
			title="Add Staff Member to Department">
			<StaffMemberPicker
				fluid={true}
				onChange={setStaffMember}
				value={staffMember} />
		</Dialog>
	);

});

export default AddStaffMemberToDepartmentDialog;
