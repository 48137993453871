import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import Picker from "Components/Picker.js";
import {memo, useCallback} from "react";

const BuildTaskGroupPicker = memo(props => {

	const {buildCategoryId} = props;

	const groupsFetch = useAsync(useCallback(() => {
		return api({
			url: `/build/tasks/groups`,
			params: {BuildCategory: buildCategoryId}
		}).then(({data}) => data);
	}, [buildCategoryId]));

	return (
		<Picker
			clearable={props.clearable}
			disabled={props.disabled}
			error={groupsFetch.error}
			label={props.label}
			loading={groupsFetch.loading}
			placeholder={props.placeholder}
			name={props.name}
			onChange={props.onChange}
			options={(groupsFetch.result || [])}
			reportIds={props.reportIds}
			value={props.value} />
	);

});

export default BuildTaskGroupPicker;
