import Dialog from "Components/Dialog.js";
import SubduedStateLabel from "./SubduedStateLabel.js";
import debounce from "lodash.debounce";
import scss from "./DataTable.module.scss";
import {memo, useCallback, useEffect, useMemo, useState} from "react";
import {Header, Icon, Input, Loader, Pagination, Table} from "semantic-ui-react";

const DataTable = memo(({
	actions,
	classNameTable,
	controls,
	data,
	dataObjectsField,
	emptyMessage,
	fields,
	label,
	noPagination,
	query,
	searchable,
	sortable,
	stickyHeader
}) => {

	const dataItems = (((!noPagination || dataObjectsField) ? data.result?.[(dataObjectsField || "Objects")] : data.result) || []);
	const isReady = (!data.loading && !data.error && !!dataItems.length);


	const [activeFilterField, setActiveFilterField] = useState(null);
	const [searchInputValue, setSearchInputValue] = useState((query?.value.Search || ""));


	const handleActiveFieldFilterValueUpdate = useCallback(value => {
		query.updateWithPageReset({
			[activeFilterField.id]: value
		});
	}, [activeFilterField, query]);


	const handleCloseFieldFiltersDialog = useCallback(() => {
		setActiveFilterField(null);
	}, []);


	const handleSetActiveFilterField = useCallback((e, field) => {
		e.preventDefault();
		e.stopPropagation();
		setActiveFilterField(field);
	}, []);


	const paginationPage = (parseInt(query?.value.Page) || 0);
	const paginationPageCount = Math.ceil(((data.result?.CountAvailable || 0) / (parseInt(query?.value.Limit) || 1)));
	const paginationDisabled = (data.loading || (paginationPageCount === 0));


	const handlePageChange = useCallback((e, {activePage}) => {
		query.updatePage(activePage);
	}, [query]);


	const handleSearchChange = useMemo(() => {
		return debounce(value => {
			query.updateWithPageReset({Search: (value || undefined)});
		}, 300);
	}, [query]);


	const handleSearchInputValueChange = useCallback((e, {value}) => {
		setSearchInputValue(value);
		handleSearchChange(value);
	}, [handleSearchChange]);


	useEffect(() => {
		setSearchInputValue((query?.value.Search || ""));
	}, [query]);


	const gridColumns = (fields?.filter(f => !f.hidden)?.map(field => (field.width || "1fr"))?.join(" ") || "1fr");

	return (
		<div
			className={scss.container}>
			{
				(label || searchable) &&
					<div
						className={scss.toolbar}>
						<Header
							className={scss.header}
							content={label} />
						<div
							className={scss.controls}>
							{controls}
							<div
								className={scss.actions}>
								{
									searchable &&
										<Input
											loading={data.loading}
											readOnly={data.loading}
											icon="search"
											onChange={handleSearchInputValueChange}
											placeholder="Search..."
											size="small"
											value={searchInputValue} />
								}
								{actions}
							</div>
						</div>
					</div>
			}
			<div
				className={`${scss.table} ${(stickyHeader ? scss.stickyHeader : "")} ${(classNameTable || "")}`.trim()}>
				<Table
					compact={true}
					fixed={true}
					sortable={sortable}
					striped={true}
					unstackable={true}>
					<Table.Header>
						<Table.Row
							style={{
								gridTemplateColumns: gridColumns
							}}>
							{
								fields?.map((field, key) => {

									if (field.hidden) {
										return null;
									}

									return (
										<Table.HeaderCell
											key={key}
											content={
												<>
													{field.label}
													{
														field.filterable &&
															<Icon
																className={scss.icon__filter}
																color={((query?.value[field.id] !== field.defaultFilterValue) ? "blue" : undefined)}
																name="filter"
																onClick={e => handleSetActiveFilterField(e, field)}
																size="small"
																tabIndex="0" />
													}
												</>
											}
											onClick={(field.sortable ? () => query.updateSortOrder(field.id) : undefined)}
											sorted={(
												(query?.value.SortOrder === field.id) ?
													(
														(query?.value.SortDirection !== "Desc") ?
															"ascending" :
															"descending"
													) :
													undefined
											)}
											textAlign={field.align}
											title={field.title} />
									);

								})
							}
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{
							isReady ?
								dataItems.map((i, key) => {
									return (
										<Table.Row
											key={key}
											style={{
												gridTemplateColumns: gridColumns
											}}>
											{
												fields?.map((field, key) => {

													if (field.hidden) {
														return null;
													}

													return (
														<Table.Cell
															key={key}
															textAlign={field.align}
															style={field.cellStyles}>
															{field.render(i)}
														</Table.Cell>
													);

												})
											}
										</Table.Row>
									);
								}) :
								<Table.Row
									error={!!data.error}
									style={{
										justifyContent: "center",
										justifyItems: "center"
									}}>
									<Table.Cell
										content={
											data.loading ?
												<Loader
													active={true}
													inline={true}
													size="tiny" /> :
												(
													data.error ?
														"Error." :
														<SubduedStateLabel
															content={(emptyMessage || "No results.")} />
												)
										}
										textAlign="center" />
								</Table.Row>
						}
					</Table.Body>
				</Table>
			</div>
			{
				(query && !noPagination) &&
					<Pagination
						className={scss.pagination}
						activePage={paginationPage}
						boundaryRange={0}
						disabled={paginationDisabled}
						firstItem={null}
						lastItem={null}
						nextItem={{
							content: ">",
							disabled: ((paginationPage === paginationPageCount) || paginationDisabled)
						}}
						prevItem={{
							content: "<",
							disabled: ((paginationPage === 1) || paginationDisabled)
						}}
						onPageChange={handlePageChange}
						size="tiny"
						totalPages={paginationPageCount} />
			}
			{
				activeFilterField &&
					<Dialog
						open={!!activeFilterField}
						onClose={handleCloseFieldFiltersDialog}
						size="tiny"
						title={`Filter by ${activeFilterField.label}`}>
						{activeFilterField.renderFilters(query?.value[activeFilterField.id], handleActiveFieldFilterValueUpdate)}
					</Dialog>
			}
		</div>
	);

});

export default DataTable;
