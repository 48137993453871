import scss from "./View.module.scss";
import Loadable from "Components/Loadable.js";
import PaddedDiv from "Components/PaddedDiv.js";
import {Container, Header, Segment} from "semantic-ui-react";

const View = ({
	children,
	label,
	narrow,
	loading,
	error,
	onErrorRetry
}) => {

	const content = (
		<PaddedDiv>
			{
				label &&
					<Header
						className={scss.header}
						content={label} />
			}
			{children}
		</PaddedDiv>
	);

	return (
		<Loadable
			loading={loading}
			error={error}
			onErrorRetry={onErrorRetry}>
			{
				narrow ?
					<Container
						className={scss.narrowView}
						text={true}>
						<Segment>
							{content}
						</Segment>
					</Container> :
					content
			}
		</Loadable>
	);

};

export default View;
