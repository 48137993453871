import {useSelector} from "react-redux";

const useAuth = () => {

	const auth = useSelector(store => store.auth);

	return {
		auth,
		isAuthed: !!auth.token
	};

};

export default useAuth;
