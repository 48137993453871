import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import Picker from "Components/Picker.js";
import {memo, useCallback} from "react";

const StaffMemberPicker = memo(props => {

	const fetch = useAsync(useCallback(() => {
		return api({
			url: `/staff`,
			params: {
				Limit: 100,
				Archived: 0,
				ProjectAssigneeEnabled: (props.projectAssigneeEnabled ? 1 : undefined)
			}
		}).then(({data}) => data.Objects);
	}, [props.projectAssigneeEnabled]));

	return (
		<Picker
			{...props}
			label="Staff Member"
			options={(fetch.result || [])}
			loading={fetch.loading}
			error={fetch.error} />
	);

});

export default StaffMemberPicker;
