import BodyStyle from "Includes/BodyStyle.js";

/**
 * Production Schedule view configuration
 *
 * Supplies global settings to the Production Schedule view.
 *
 * @package RedSky
 * @subpackage ProductionSchedule
 * @author Heron Web Ltd
 * @copyright Red Sky Shepherds Huts Limited
 */
const ProductionScheduleViewConfig = {

	/**
	 * Number of bin columns to render
	 *
	 * @type {Integer}
	 */
	binCount: parseInt(BodyStyle.getPropertyValue("--production-schedule-bin-count")),

	/**
	 * Bins sidebar width in rem
	 *
	 * @type {Number} rem
	 */
	binsSidebarWidth: 4.5,

	/**
	 * Breakpoint at which the mobile UX is used below
	 * 
	 * MUST match the value in `ProductionScheduleView.module.scss`
	 *
	 * @type {Integer}
	 */
	breakpointPx: parseInt(BodyStyle.getPropertyValue("--production-schedule-breakpoint")),

	/**
	 * Width of columns in the view in rem
	 * 
	 * @type {Number} rem
	 */
	columnWidth: parseFloat(BodyStyle.getPropertyValue("--production-schedule-column-width")),

	/**
	 * Number of day columns to render in the view
	 * 
	 * @type {Number}
	 */
	daysToRenderCount: 5,

	/**
	 * Gap width between components in rem
	 *
	 * @type {Number} rem
	 */
	gap: 1.5

};

export default ProductionScheduleViewConfig;
