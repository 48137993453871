import axios from "axios";
import dReset from "Dispatchers/dReset.js";
import moment from "moment";
import ReduxStore from "./App/Redux/Store.js";
import ToastStore from "Toasts/ToastStore.js";

/**
 * Auth error reason codes given by the API that we 
 * handle using the invalid/expired session flow
 */
const AUTH_SESSION_INVALID_REASONS = ["TokenInvalid", "UserDeactivated"];


/**
 * Session expiration handler.
 *
 * We notify the user and then initiate a state reset (to effect a logout).
 */
const handleSessionExpiration = () => {

	ToastStore.error(
		"You've been logged out because your session has expired.",
		{
			header: "Session expired"
		}
	);

	dReset({targetUri: null});

};


/**
 * Response error interceptor.
 *
 * Enables us to handle response errors encountered by Axios.
 *
 * We use this to detect and handle auth issues.
 */
const responseErrorInterceptor = e => {

	const response = e?.response;

	/**
	 * An auth issue occurred
	 */
	if (response?.status === 403) {

		/**
		 * API deposits information about the issue in this property
		 */
		const authReason = response.data?.["auth.reason"];

		/**
		 * Access denied due to the user lacking the required permission
		 */
		if (authReason === "AccessDenied") {
			ToastStore.error(
				`You don't have permission to access "${e.config.method.toUpperCase()} ${e.config.url}".`,
				{
					header: "Access denied"
				}
			);
		}

		/**
		 * Session is now invalid
		 * 
		 * (We assume it's been expired/voided and handle it accordingly.)
		 */
		else if (AUTH_SESSION_INVALID_REASONS.includes(authReason)) {
			handleSessionExpiration();
		}

	}

	/**
	 * Rethrow the error so the code that made the 
	 * network request gets to know about it
	 */
	throw e;

};


/**
 * Make an API request.
 *
 * A thin wrapper around Axios that applies our default configuration.
 */
const request = request => {

	const auth = ReduxStore.getState().auth;

	/**
	 * The user's authentication has expired
	 *
	 * We abort sending the request and invoke the expiration handler now.
	 *
	 * Note that we then throw an error afterwards so that the code that 
	 * made the network request aborts its own flow correctly!
	 */
	if (auth?.token && (new moment(auth.expiry) < new moment())) {
		handleSessionExpiration();
		throw new Error("Authenticated session expired.");
	}

	/**
	 * Create the Axios instance for this request
	 */
	const axiosInstance = axios.create({
		baseURL: process.env.REACT_APP_API_URL_BASE,
		headers: {
			"Authorization": auth?.token,
			"Content-Type": "application/json"
		},
		timeout: 30000
	});

	/**
	 * Apply our interceptors
	 *
	 * (The success interceptor is `undefined` as we don't need it.)
	 */
	axiosInstance.interceptors.response.use(undefined, responseErrorInterceptor);

	/**
	 * Send the request!
	 */
	return axiosInstance.request(request);

};

export default request;
