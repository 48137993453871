import scss from "./ToastProvider.module.scss";
import {memo, useEffect, useState} from "react";
import {Message} from "semantic-ui-react";

const ToastProvider = memo(({store}) => {

	const [toasts, setToasts] = useState([]);

	useEffect(() => {
		store.getToasts = () => toasts;
		store.setToasts = setToasts;
	}, [store, toasts]);

	return (
		<div className={scss.root}>
			{
				toasts.map((toast, key) => (
					<Message
						key={key}
						content={toast.message}
						header={toast.header}
						icon={toast.icon}
						error={(toast.type === "error")}
						info={(toast.type === "info")}
						success={(toast.type === "success")}
						warning={(toast.type === "warning")}
						onDismiss={() => store.remove(toast)} />
				))
			}
		</div>
	);

});

export default ToastProvider;
