export default {
	401: {
		PasswordInvalid: "You've entered your password incorrectly.",
		TotpInvalid: "The code you've entered is invalid."
	},
	403: "Your user account has been deactivated.",
	404: "Unknown user account.",
	500: "Internal server error.",
	default: "Unhandled error."
};
