import {useCallback, useEffect, useState} from "react";

export default () => {

	const getViewportDimensions = useCallback(() => {
		const {innerWidth: width, innerHeight: height} = window;
		return {width, height};
	}, []);

	const [state, setState] = useState(getViewportDimensions());

	const updateState = useCallback(() => {
		setState(getViewportDimensions());
	}, [getViewportDimensions]);

	useEffect(() => {
		window.addEventListener("resize", updateState);
		return () => window.removeEventListener("resize", updateState);
	}, [updateState]);

	return state;

};
