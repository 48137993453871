import api from "api.js";
import CustomerContactDialog from "./CustomerContactDialog.js";
import CustomerContactDeletionDialog from "./CustomerContactDeletionDialog.js";
import Flex from "Components/Flex.js";
import InlineInput from "Components/InlineInput.js";
import ToastStore from "Toasts/ToastStore.js";
import scss from "./CustomerContactForm.module.scss";
import useValue from "Hooks/useValue.js";
import {memo, useCallback, useEffect, useState} from "react";
import {Button, Dropdown, Form} from "semantic-ui-react";

const CustomerContactForm = memo(({
	contact,
	customerId,
	disabled,
	formId,
	gap,
	hideControls,
	onDelete,
	onSaved,
	onSubmitting,
	readOnly
}) => {

	const editDialogOpen = useValue(false);
	const deletionDialogOpen = useValue(false);

	const [editState, setEditState] = useState({...contact});
	const [isSubmitting, setIsSubmitting] = useState(false);


	const handleIsSubmitting = useCallback(isSubmitting => {
		setIsSubmitting(isSubmitting);
		onSubmitting?.(isSubmitting);
	}, [onSubmitting]);


	const handleChange = useCallback((e, {value, name}) => {
		setEditState({
			...editState,
			[name]: (value || null)
		});
	}, [editState]);


	const handleChangePrimaryContact = useCallback((e, {checked}) => {
		setEditState({
			...editState,
			PrimaryContact: checked
		});
	}, [editState]);


	const handleSubmit = useCallback(async e => {

		e.preventDefault();

		handleIsSubmitting(true);

		try {

			const result = await api({
				url: (!contact ? `/customers/${customerId}/contacts` : `/customers/contacts/${contact.Id}`),
				method: (!contact ? "POST" : "PUT"),
				data: editState
			}).then(({data}) => data);

			onSaved(result, contact);

		}
		catch (e) {
			ToastStore.error(e);
		}

		handleIsSubmitting(false);

	}, [contact, customerId, editState, handleIsSubmitting, onSaved]);


	useEffect(() => {
		setEditState({...contact});
	}, [contact]);


	return (
		<>
			<Form
				id={formId}
				onSubmit={handleSubmit}>
				<Flex
					gap={(gap || 0)}>
					<InlineInput
						autoFocus={true}
						disabled={(disabled || isSubmitting)}
						label="Name"
						placeholder="Contact Name"
						maxLength={255}
						required={true}
						name="ContactName"
						onChange={handleChange}
						readOnly={readOnly}
						value={(editState?.ContactName || "")} />
					<InlineInput
						disabled={(disabled || isSubmitting)}
						label="Label"
						placeholder="Title/Position/Role etc."
						maxLength={255}
						name="ContactDescription"
						onChange={handleChange}
						readOnly={readOnly}
						value={(editState?.ContactDescription || "")} />
					<InlineInput
						disabled={(disabled || isSubmitting)}
						label="Email"
						placeholder="Email Address"
						maxLength={255}
						name="EmailAddress"
						onChange={handleChange}
						readOnly={readOnly}
						type="email"
						value={(editState?.EmailAddress || "")} />
					<InlineInput
						disabled={(disabled || isSubmitting)}
						label="Phone"
						placeholder="Phone Number"
						maxLength={16}
						minLength={11}
						name="PhoneNumber"
						onChange={handleChange}
						pattern="^(\+[1-9]{1,2} ?)?\d{1,5} ?\d{6,6}$"
						readOnly={readOnly}
						type="tel"
						value={(editState?.PhoneNumber || "")} />
					<InlineInput
						disabled={(disabled || isSubmitting)}
						label="Notes"
						placeholder="Notes"
						maxLength={65535}
						name="Notes"
						onChange={handleChange}
						readOnly={readOnly}
						rows={3}
						value={(editState?.Notes || "")} />
					<div
						className={scss.controls}>
						<Form.Checkbox
							checked={(editState?.PrimaryContact || false)}
							className={scss.checkbox__primaryContact}
							disabled={(disabled || isSubmitting)}
							name="PrimaryContact"
							onChange={handleChangePrimaryContact}
							readOnly={readOnly}
							label="Primary Contact?" />
						{
							!hideControls &&
								<Button.Group
									className={scss.save}
									size="mini">
									<Button
										basic={true}
										content="Edit"
										disabled={(disabled || isSubmitting)}
										onClick={editDialogOpen.setTrue}
										primary={true}
										icon="pencil"
										type="button" />
									<Dropdown
										basic={true}
										button={true}
										className={`button icon primary ${scss.dropdown}`}
										disabled={(disabled || isSubmitting)}
										floating={true}
										options={[
											{
												active: false,
												key: "delete",
												icon: "trash alternate",
												onClick: deletionDialogOpen.setTrue,
												text: "Delete"
											}
										]}
										trigger={<></>} />
								</Button.Group>
						}
					</div>
				</Flex>
			</Form>
			{
				editDialogOpen.value &&
					<CustomerContactDialog
						contact={contact}
						open={editDialogOpen.value}
						onClose={editDialogOpen.setFalse}
						onSavedContact={onSaved} />
			}
			{
				deletionDialogOpen.value &&
					<CustomerContactDeletionDialog
						contactId={contact?.Id}
						open={deletionDialogOpen.value}
						onClose={deletionDialogOpen.setFalse}
						onDeleted={onDelete} />
			}
		</>
	);

});

export default CustomerContactForm;
