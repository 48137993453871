import Select from "./Select.js";
import {memo} from "react";
import {Icon} from "semantic-ui-react";

const EnumPicker = memo(({
	clearable,
	disabled,
	disabledOptions,
	enumClass,
	hiddenOptions,
	label,
	name,
	noIcons,
	onChange,
	placeholder,
	required,
	size,
	value
}) => {

	return (
		<Select
			clearable={clearable}
			disabled={disabled}
			label={label}
			name={name}
			onChange={onChange}
			options={
				enumClass.cases
					.filter(i => {
						return !hiddenOptions?.includes(i);
					})
					.map(i => {
						return {
							text: (enumClass.getLabel?.(i) || i),
							disabled: disabledOptions?.includes(i),
							value: i,
							icon: (
								!noIcons ?
									(
										enumClass.getIcon?.(i) ||
										<Icon
											name="dot circle"
											color={enumClass.getColour?.(i)} />
									) :
									undefined
							)
						};
					})
			}
			placeholder={placeholder}
			required={required}
			size={size}
			value={value} />
	);

});

export default EnumPicker;
