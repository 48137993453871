import initialState from "./State.js";

const Reducer = (state, action) => {

	if (action.type === "update") {
		state = {...state, ...action.data};
	}

	else if (action.type === "reset") {
		state = {...initialState, targetUri: action.targetUri};
	}

	return state;

};

export default Reducer;
