import scss from "./ProductionScheduleViewProjectBinsSidebarItem.module.scss";
import {resolveCssColour} from "Includes/Colour.js";
import {memo} from "react";
import {Label} from "semantic-ui-react";

const ProductionScheduleViewProjectBinsSidebarItem = memo(({
	project,
	taskCount,
	onClick
}) => {

	const projectNameInitials = (project.Name || project.Customer?.Name || "#").split(" ").filter(i => i.toLowerCase().match(/^[a-z]+$/));

	const label = [
		((projectNameInitials.length > 1) ? projectNameInitials[0]?.[0] : undefined),
		((projectNameInitials.length > 2) ? projectNameInitials[1]?.[0] : undefined),
		projectNameInitials[(projectNameInitials.length - 1)]?.[0]
	].filter(i => i).join("").toUpperCase();

	return (
		<Label
			className={scss.label}
			onClick={onClick}
			size="big"
			style={{
				background: resolveCssColour(project.Colour)
			}}
			title={(project.Name || project.Customer?.Name)}>
			{label}
			{
				(taskCount > 0) &&
					<div
						children={((taskCount > 99) ? `+` : taskCount)}
						className={scss.badge} />
			}
		</Label>
	);

});

export default ProductionScheduleViewProjectBinsSidebarItem;
