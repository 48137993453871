import scss from "./PaneHeader.module.scss";
import {memo} from "react";
import {Button, Header, Icon} from "semantic-ui-react";

const PaneHeader = memo(({
	actions,
	buttonDisabled,
	buttonIcon,
	buttonLabel,
	buttonType,
	chip,
	icon,
	iconSize,
	innerRef,
	label,
	labelSize,
	labelSub,
	onButtonClick,
	onLabelClick
}) => {

	return (
		<div
			className={scss.root}
			ref={innerRef}>
			<div
				className={`${scss.main} ${(onLabelClick ? scss.clickable : "")}`.trim()}>
				{chip}
				{
					icon &&
						<Icon
							className={scss.icon}
							fitted={true}
							name={icon}
							link={!!onLabelClick}
							onClick={onLabelClick}
							size={iconSize} />
				}
				<div>
					<Header
						className={scss.label}
						content={label}
						onClick={onLabelClick}
						size={labelSize} />
					{
						labelSub &&
							<Header
								className={scss.label}
								color="grey"
								content={labelSub}
								sub={true} />
					}
				</div>
			</div>
			<div>
				{actions}
				{
					(onButtonClick || buttonType) &&
						<Button
							basic={true}
							disabled={buttonDisabled}
							icon={(buttonIcon || "check")}
							onClick={onButtonClick}
							size="mini"
							title={buttonLabel}
							type={(buttonType || "button")} />
				}
			</div>
		</div>
	);

});

export default PaneHeader;
