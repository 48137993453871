import Colours from "Resources/Colours.js";
import Picker from "Components/Picker.js";
import {memo, useCallback} from "react";

const ColourPicker = memo(props => {

	const renderChip = useCallback(colour => {

		const dimensions = `0.8em`;

		return (
			<div
				style={{
					background: `#${colour.value}`,
					display: "inline-block",
					marginRight: "0.5em",
					width: dimensions,
					height: dimensions
				}} />
		);

	}, []);

	return (
		<Picker
			label="Colour"
			{...props}
			getOptionId={colour => colour.value}
			getOptionLabel={colour => colour.label}
			getOptionChip={colour => renderChip(colour)}
			options={Colours} />
	);

});

export default ColourPicker;
