import api from "api.js";
import Dialog from "Components/Dialog.js";
import Flex from "Components/Flex.js";
import SubduedStateLabel from "Components/SubduedStateLabel.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useState} from "react";
import {Form, Message} from "semantic-ui-react";

const ProjectSchedulingDialog = memo(({
	open,
	onClose,
	onScheduled,
	project
}) => {

	const [value, setValue] = useState(project.ProductionStartDate);
	const [isSubmitting, setIsSubmitting] = useState(false);


	const handleInputValueChange = useCallback((e, {value}) => {
		setValue(value);
	}, []);


	const handleSubmit = useCallback(async () => {

		if (!value) {
			ToastStore.error("Please select a date.", "error");
			return;
		}

		setIsSubmitting(true);

		try {

			await api({
				url: `/projects/${project.Id}/production_schedule_date`,
				method: "PUT",
				data: {
					Date: value
				}
			});

			onClose();
			onScheduled(value, project);

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [project, onClose, onScheduled, value]);


	return (
		<Dialog
			disabled={isSubmitting}
			submitting={isSubmitting}
			open={open}
			onClose={onClose}
			onOk={handleSubmit}
			size="tiny"
			title={`Production Schedule: ${(project.Name || project.Customer?.Name)}`}>
			<Form
				onSubmit={handleSubmit}>
				<Flex
					gap={0.5}>
					<Flex
						gap={0}
						style={{marginBottom: "1rem"}}>
						<SubduedStateLabel
							content="This facility allows you to manually set the production start date for this Project. All Projects that then overlap this one will be automatically rescheduled after it." />
						<SubduedStateLabel
							content="If the date you select is a weekend or Bank Holiday, then the Project will be scheduled to the next working day after the selected date." />
					</Flex>
					<Form.Input
						disabled={isSubmitting}
						label="Start Date"
						onChange={handleInputValueChange}
						required={true}
						type="date"
						value={(value || "")} />
					<Message
						info={true}>
						<SubduedStateLabel
							content="Note: Projects are always scheduled based on the duration of their currently associated Tasks. Projects that have had Tasks added or removed since they were last scheduled may change duration when rescheduled." />
					</Message>
				</Flex>
			</Form>
		</Dialog>
	);

});

export default ProjectSchedulingDialog;
