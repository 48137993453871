import api from "api.js";
import useUser from "Hooks/useUser.js";
import useValue from "Hooks/useValue.js";
import Checkbox from "Components/LabelledCheckbox.js";
import DetailsPanel from "Components/DetailsPanel.js";
import Flex from "Components/Flex.js";
import PaneHeader from "Components/PaneHeader.js";
import SubduedStateLabel from "Components/SubduedStateLabel.js";
import ToastStore from "Toasts/ToastStore.js";
import UserArchiveDialog from "./UserArchiveDialog.js";
import UserDeletionDialog from "./UserDeletionDialog.js";
import UserPwcDialog from "./UserPwcDialog.js";
import UserTotpDisableDialog from "./UserTotpDisableDialog.js";
import moment from "moment";
import scss from "./UserDetailsPane.module.scss";
import {memo, useCallback, useState} from "react";
import {Link} from "react-router-dom";
import {Button, Divider, Form, Icon, Label, Message, Segment} from "semantic-ui-react";

const UserDetailsPane = memo(({user, onUpdatedUser}) => {

	const authUser = useUser();
	const isAuthUser = (user.Id === authUser.Id);

	const archiveDialogOpen = useValue(false);
	const deletionDialogOpen = useValue(false);
	const pwcDialogOpen = useValue(false);
	const totpDisableDialogOpen = useValue(false);

	const [editState, setEditState] = useState({...user});
	const [isSubmittingEdits, setIsSubmittingEdits] = useState(false);

	const [hasEditChanges, setHasEditChanges] = useState(false);
	const [isUnarchiving, setIsUnarchiving] = useState(false);


	const handleArchived = useCallback(() => {
		onUpdatedUser({...user, Archived: true});
	}, [user, onUpdatedUser]);


	const handleTotpDisabled = useCallback(() => {
		onUpdatedUser({...user, TotpEnabled: false});
	}, [user, onUpdatedUser]);


	const handleUnarchive = useCallback(async () => {

		setIsUnarchiving(true);

		try {

			await api({
				url: `/users/${user.Id}/unarchive`,
				method: "POST"
			});

			onUpdatedUser({
				...user,
				Archived: false
			});

			ToastStore.success("User restored successfully.");

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsUnarchiving(false);

	}, [user, onUpdatedUser]);


	const handleEditChange = useCallback((e, {name, value}) => {

		setEditState({
			...editState,
			[name]: (value || null)
		});

		setHasEditChanges(true);

	}, [editState]);


	const handleSubmitEdits = useCallback(async () => {

		setIsSubmittingEdits(true);

		try {

			const result = await api({
				url: `/users/${user.Id}`,
				method: "PUT",
				data: {
					Username: editState.Username,
					Name: editState.Name
				}
			}).then(({data}) => data);

			onUpdatedUser(result);
			setHasEditChanges(false);

		}
		catch (e) {
			if (e.response?.status === 409) {
				ToastStore.error("The username you've entered already exists.");
			}
			else ToastStore.error(e);
		}

		setIsSubmittingEdits(false);

	}, [editState, onUpdatedUser, user]);


	const handleToggleActive = useCallback(async () => {

		setIsSubmittingEdits(true);

		try {

			await api({
				url: `/users/${user.Id}/${(!user.Active ? "activate" : "deactivate")}`,
				method: "POST"
			});

			ToastStore.success(`User successfully ${(!user.Active ? "set active" : "set inactive")}.`);

			onUpdatedUser({...user, Active: !user.Active});

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmittingEdits(false);

	}, [user, onUpdatedUser]);


	return (
		<Segment>
			<Form
				onSubmit={handleSubmitEdits}>
				<PaneHeader
					buttonDisabled={(isSubmittingEdits || !hasEditChanges)}
					buttonIcon="check"
					buttonLabel="Save"
					buttonType="submit"
					chip={
						<Label
							className={scss.chip}
							content={(user.Archived ? "Archived" : (user.Active ? "Active" : "Inactive"))}
							color={(user.Archived ? "orange" : (user.Active ? "blue" : "grey"))}
							title={(user.Archived ? "Blocked from logging in and using the system; hidden in selection lists." : (user.Active ? "Active" : "Blocked from logging in and using the system."))} />
					}
					label={user.Name}
					labelSub={
						<span style={{textTransform: "none"}}>
							{user.Username}
						</span>
					} />
				<Flex
					gap={0.5}>
					{
						user.Archived &&
							<Message
								className={scss.message}
								header="Archived user"
								content="Changes cannot be made."
								visible={true}
								warning={true} />
					}
					<DetailsPanel
						defaultOpen={true}
						label="User Details">
						<Form.Input
							disabled={isSubmittingEdits}
							label="Username"
							placeholder="Username"
							maxLength={255}
							name="Username"
							onChange={handleEditChange}
							readOnly={user.Archived}
							required={true}
							value={editState.Username} />
						<Form.Input
							disabled={isSubmittingEdits}
							label="Name"
							placeholder="Name"
							maxLength={255}
							name="Name"
							onChange={handleEditChange}
							readOnly={user.Archived}
							required={true}
							value={editState.Name} />
						<Divider
							hidden={true} />
						<Checkbox
							disabled={(isSubmittingEdits || isAuthUser)}
							label="Active User?"
							caption="Active users can login and use the system."
							onChange={handleToggleActive}
							value={user.Active} />
						<Divider
							hidden={true} />
						<Flex
							columnar={true}
							gap={0.25}>
							<Icon
								color={(user.TotpEnabled ? "green" : "red")}
								name={(user.TotpEnabled ? "check" : "close")} />
							<div>
								<p
									style={{
										marginBottom: (user.TotpEnabled ? "0.5em" : undefined)
									}}>
									<strong>Two-Factor Authentication {(user.TotpEnabled ? "enabled" : "disabled")}</strong>
								</p>
								{
									user.TotpEnabled &&
										(
											isAuthUser ?
												<Link
													children="Disable on your Profile screen"
													to="/profile" /> :
												<Button
													basic={true}
													content="Disable Two-Factor Authentication"
													onClick={totpDisableDialogOpen.setTrue}
													size="mini" />
										)
								}
							</div>
						</Flex>
					</DetailsPanel>
				</Flex>
				<Divider
					section={true}
					style={{marginTop: "0.5em"}} />
				<Flex
					columnar={true}
					gap={0.25}
					wrap={true}>
					{
						!user.Archived ?
							<Button
								basic={true}
								disabled={(isSubmittingEdits || isAuthUser)}
								onClick={archiveDialogOpen.setTrue}
								content="Archive"
								icon="archive"
								color="red"
								size="mini"
								type="button" /> :
							<Button
								basic={true}
								content="Unarchive"
								disabled={isUnarchiving}
								loading={isUnarchiving}
								onClick={handleUnarchive}
								icon="redo"
								color="blue"
								size="mini"
								type="button" />
					}
					<Button
						basic={true}
						disabled={(isSubmittingEdits || isAuthUser || isUnarchiving)}
						content="Delete"
						onClick={deletionDialogOpen.setTrue}
						icon="trash"
						color="red"
						size="mini"
						type="button" />
					<Button
						basic={true}
						disabled={(isSubmittingEdits || isUnarchiving)}
						onClick={pwcDialogOpen.setTrue}
						content="Set Password"
						icon="key"
						color="brown"
						size="mini"
						type="button" />
				</Flex>
				<SubduedStateLabel
					content={`Created ${(new moment(user.CreationTime)).format("DD/MM/YYYY @ HH:mm")}`} />
			</Form>
			{
				archiveDialogOpen.value &&
					<UserArchiveDialog
						open={archiveDialogOpen.value}
						onArchived={handleArchived}
						onClose={archiveDialogOpen.setFalse}
						user={user} />
			}
			{
				deletionDialogOpen.value &&
					<UserDeletionDialog
						open={deletionDialogOpen.value}
						onClose={deletionDialogOpen.setFalse}
						userId={user?.Id} />
			}
			{
				pwcDialogOpen.value &&
					<UserPwcDialog
						open={pwcDialogOpen.value}
						onClose={pwcDialogOpen.setFalse}
						user={user} />
			}
			{
				totpDisableDialogOpen.value &&
					<UserTotpDisableDialog
						open={totpDisableDialogOpen.value}
						onDisabled={handleTotpDisabled}
						onClose={totpDisableDialogOpen.setFalse}
						user={user} />
			}
		</Segment>
	);

});

export default UserDetailsPane;
