import EnumChip from "Components/EnumChip.js";
import Pane from "Components/Pane.js";
import Picker from "Components/Picker.js";
import ProjectCreationDialog from "Projects/ProjectCreationDialog.js";
import ProjectDetailsPane from "Projects/ProjectDetailsPane.js";
import ProjectStatus from "Projects/ProjectStatus.js";
import SubduedStateLabel from "Components/SubduedStateLabel.js";
import scss from "./CustomerViewProjectsPane.module.scss";
import useValue from "Hooks/useValue.js";
import {memo} from "react";
import {Button, Divider} from "semantic-ui-react";

const CustomerViewProjectsPane = memo(({
	activeProject,
	customerId,
	projects,
	onCreateProject,
	onDeleteProject,
	onSelectProject,
	onUpdateProject
}) => {


	const newProjectDialogOpen = useValue(false);


	return (
		<Pane
			label="Projects">
			<div className={scss.header}>
				<Picker
					label="Project"
					noResultsMessage="No Projects available."
					onChange={onSelectProject}
					options={projects}
					getOptionLabel={project => (project.Name || "(Unnamed Project)")}
					getOptionChip={project => <EnumChip enumClass={ProjectStatus} size="tiny" valueCase={project.Status} />}
					reportIds={true}
					value={activeProject?.Id} />
				<Button
					basic={true}
					icon="add"
					onClick={newProjectDialogOpen.setTrue}
					title="New Project" />
			</div>
			<Divider />
			<div
				className={scss.content}>
				{
					activeProject ?
						<ProjectDetailsPane
							embedded={true}
							hideCustomerDetails={true}
							onDeleteProject={onDeleteProject}
							onUpdateProject={onUpdateProject}
							project={activeProject} /> :
						<SubduedStateLabel
							content={(!projects?.length ? "This Customer has no Projects." : "No Project selected.")} />
				}
			</div>
			{
				newProjectDialogOpen.value &&
					<ProjectCreationDialog
						customerId={customerId}
						open={newProjectDialogOpen.value}
						onClose={newProjectDialogOpen.setFalse}
						onCreated={onCreateProject} />
			}
		</Pane>
	);

});

export default CustomerViewProjectsPane;
