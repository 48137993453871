import {useCallback, useEffect, useRef, useState} from "react";

const useAsync = fn => {

	/**
	 * Call ID
	 *
	 * This is incremented each time the function is called.
	 *
	 * We only update our state afterwards when the call ID
	 * has not changed in the meantime, ensuring that multiple
	 * overlapping calls only cause the state to be updated once,
	 * using the results from the last call.
	 */
	const callId = useRef(0);

	/**
	 * State details
	 */
	const [result, setResult] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);


	/**
	 * Caller.
	 *
	 * Calls the user-defined function and handles the result.
	 *
	 * @async
	 * @return {void}
	 */
	const call = useCallback(async () => {


		/**
		 * Increment the call ID
		 */
		callId.current++;

		/**
		 * This is our call ID for this call
		 */
		const thisCallId = callId.current;


		/**
		 * We are calling
		 */
		setError(null);
		setLoading(true);


		/**
		 * Make the call now
		 */
		try {

			const result = await fn();

			/** Only update state if no later call has arrived */
			if (thisCallId === callId.current) {
				setResult(result);
			}

		}
		catch (e) {

			/** Only update state if no later call has arrived */
			if (thisCallId === callId.current) {
				setError(e);
				setResult(null);
			}

		}


		if (thisCallId === callId.current) {
			setLoading(false);
		}


	}, [fn]);


	/**
	 * Reset the state.
	 *
	 * @return {void}
	 */
	const reset = useCallback(() => {
		setResult(null);
		setError(null);
		setLoading(true);
	}, []);


	/**
	 * Automatically call whenever the caller changes
	 *
	 * (I.e. first invocation, then when the user-defined function changes.)
	 */
	useEffect(() => {
		call();
	}, [call]);


	/**
	 * Build our output
	 */
	return {
		call,
		result,
		setResult,
		error,
		loading,
		reset,
		settled: (!loading && !error),
		empty: !result?.length
	};

};

export default useAsync;
