import api from "api.js";
import pluralize from "pluralize";
import scss from "./ProjectBuildRevisionApprovalDialog.module.scss";
import useAsync from "Hooks/useAsync.js";
import ColourPicker from "Components/ColourPicker.js";
import Dialog from "Components/Dialog.js";
import Flex from "Components/Flex.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useState, Fragment} from "react";
import {Divider} from "semantic-ui-react";

const ProjectBuildRevisionApprovalDialog = memo(({
	revisionId,
	open,
	onClose,
	onApproved
}) => {

	const [colourSelection, setColourSelection] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);


	const activelyUsedColoursFetch = useAsync(useCallback(() => {
		return api({url: `/projects/active/colours`}).then(({data}) => data);
	}, []));


	const handleSubmit = useCallback(async () => {

		const errorMsgs = [];

		if (!colourSelection) {
			ToastStore.error("Please select a Colour to assign to the Project.");
			return;
		}

		setIsSubmitting(true);

		try {

			const result = await api({
				url: `/projects/build/revisions/${revisionId}/approve`,
				method: "POST",
				data: {
					AssignProjectColour: colourSelection.value
				}
			}).then(({data}) => data);

			if (result.Errors.ProjectSchedule) {
				errorMsgs.push("Failed to add the Project to the Production Schedule.");
			}
			if (result.Errors.TaskPopulation) {
				errorMsgs.push("Failed to populate all required Production Schedule tasks.");
			}

			if (errorMsgs.length) {
				ToastStore.warning(
					<>
						<p>The operation completed, but {errorMsgs.length} {pluralize("error", errorMsgs.length)} {pluralize("was", errorMsgs.length)} encountered:</p>
						<p>
							{
								errorMsgs.map((m, key) => (
									<Fragment key={key}>
										- {m}
										<br />
									</Fragment>
								))
							}
						</p>
					</>
				);
			}
			else ToastStore.success("Project successfully added to the Production Schedule.");

			onApproved(result.Project);

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [colourSelection, onApproved, revisionId]);


	return (
		<Dialog
			title="Approve Project Revision"
			content={
				<>
					<Flex
						alignItems="flex-start"
						gap={0.5}>
						<p style={{margin: 0}}><strong>Assign Project Colour</strong></p>
						<ColourPicker
							className={scss.colourPicker}
							disabled={isSubmitting}
							getOptionDescription={colour => (activelyUsedColoursFetch.result?.includes(colour.value) ? "(In Use)" : undefined)}
							getOptionStyles={colour => (activelyUsedColoursFetch.result?.includes(colour.value) ? {background: "rgba(255, 0, 0, 0.2)"} : undefined)}
							onChange={setColourSelection}
							value={colourSelection} />
						<p>Colours that are already in use by another active Project are shaded red in the dropdown.</p>
					</Flex>
					<Divider section={true} />
					<p><strong>Are you sure you want to approve this Project Revision?</strong></p>
					<p>Tasks will be created to produce the Project Items specified in this Project Revision.</p>
					<p>If the Project is not already included in the Production Schedule, then it'll be scheduled automatically.</p>
					<p>This action cannot be undone.</p>
					<p><strong>Only approve this Project if the initial deposit has been received!</strong></p>
				</>
			}
			size="small"
			submitting={isSubmitting}
			open={open}
			onClose={onClose}
			onOk={handleSubmit} />
	);

});

export default ProjectBuildRevisionApprovalDialog;
