import api from "api.js";
import BuildCategoryDialog from "BuildDatabase/Categories/BuildCategoryDialog.js";
import BuildCategoryPicker from "BuildDatabase/Categories/BuildCategoryPicker.js";
import BuildTaskGroupDialog from "./Groups/BuildTaskGroupDialog.js";
import BuildTaskGroupPicker from "./Groups/BuildTaskGroupPicker.js";
import BuildTaskStepsList from "./Steps/BuildTaskStepsList.js";
import Checkbox from "Components/LabelledCheckbox.js";
import ErrorBoundary from "Components/ErrorBoundary.js";
import Flex from "Components/Flex.js";
import Loadable from "Components/Loadable.js";
import SubduedStateLabel from "Components/SubduedStateLabel.js";
import ToastStore from "Toasts/ToastStore.js";
import pluralize from "pluralize";
import scss from "./BuildTaskForm.module.scss";
import useAsync from "Hooks/useAsync.js";
import useForm from "Hooks/useForm.js";
import useValue from "Hooks/useValue.js";
import {memo, useCallback, useRef, useState} from "react";
import {Form, Header, Ref} from "semantic-ui-react";

const BuildTaskForm = memo(({
	task,
	targetBuildCategoryId,
	formId,
	onCreatedCategory,
	onCreatedGroup,
	onSubmitted,
	onSubmittingChange
}) => {

	const form = useForm({BuildOrderIndex: 1, ...task});

	const [addAnother, setAddAnother] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isStepsSubmitting, setIsStepsSubmitting] = useState(false);

	const newSubCategoryDialogOpen = useValue(false);
	const newGroupDialogOpen = useValue(false);

	const titleInputRef = useRef();


	/**
	 * Fetch the task's steps
	 */
	const stepsFetch = useAsync(useCallback(() => {
		if (!task) return [];
		else return api({url: `/build/tasks/${task.Id}/steps`}).then(({data}) => data);
	}, [task]));


	/**
	 * Updating our current steps data in our state.
	 */
	const handleStepsDataUpdate = useCallback(stepsData => {
		stepsFetch.setResult(stepsData);
		if (task?.Id) onSubmitted(task, stepsData, task.Id);
	}, [onSubmitted, stepsFetch, task]);


	/**
	 * Adding a new step.
	 */
	const handleAddStep = useCallback(async step => {

		if (task?.Id) {

			setIsStepsSubmitting(true);
			onSubmittingChange?.(true);

			try {

				const result = await api({
					url: `/build/tasks/steps`,
					method: "POST",
					data: {
						...step,
						BuildTask: task.Id
					}
				}).then(({data}) => data);

				handleStepsDataUpdate([...stepsFetch.result, result]);

			}
			catch (e) {
				ToastStore.error(e);
			}

			setIsStepsSubmitting(false);
			onSubmittingChange?.(false);

		}
		else handleStepsDataUpdate([...stepsFetch.result, step]);

	}, [handleStepsDataUpdate, stepsFetch, task, onSubmittingChange]);


	/**
	 * Changing a step.
	 */
	const handleChangeStep = useCallback(async (data, step) => {

		let result;

		if (step?.Id) {

			setIsStepsSubmitting(true);
			onSubmittingChange?.(true);

			try {
				result = await api({
					url: `/build/tasks/steps/${step?.Id}`,
					method: "PUT",
					data: {
						...data,
						BuildTask: task.Id
					}
				}).then(({data}) => data);
			}
			catch (e) {
				ToastStore.error(e);
			}

			setIsStepsSubmitting(false);
			onSubmittingChange?.(false);

		}
		else result = data;

		const updatedStepsData = [...stepsFetch.result];
		const stepIndex = updatedStepsData.indexOf(updatedStepsData.find(s => (s === step)));

		updatedStepsData[stepIndex] = result;
		handleStepsDataUpdate(updatedStepsData);

	}, [handleStepsDataUpdate, stepsFetch, task, onSubmittingChange]);


	/**
	 * Deleting a step.
	 */
	const handleDeleteStep = useCallback(async step => {

		if (step?.Id) {

			setIsStepsSubmitting(true);
			onSubmittingChange?.(true);

			try {

				await api({
					url: `/build/tasks/steps/${step.Id}`,
					method: "DELETE"
				});

				handleStepsDataUpdate(stepsFetch.result.filter(({Id}) => (Id !== step.Id)));

			}
			catch (e) {
				ToastStore.error(e);
			}

			setIsStepsSubmitting(false);
			onSubmittingChange?.(false);

		}
		else handleStepsDataUpdate(stepsFetch.result.filter(s => (s !== step)));

	}, [handleStepsDataUpdate, stepsFetch, onSubmittingChange]);


	/**
	 * Deleted a step.
	 */
	const handleDeletedStep = useCallback(stepId => {
		handleStepsDataUpdate(stepsFetch.result.filter(({Id}) => (Id !== stepId)));
	}, [handleStepsDataUpdate, stepsFetch]);


	/**
	 * Sub-category created.
	 */
	const handleCreatedSubCategory = useCallback(category => {
		onCreatedCategory(category);
		form.setState({...form.state, BuildCategory: category});
		newSubCategoryDialogOpen.setFalse();
	}, [form, newSubCategoryDialogOpen, onCreatedCategory]);


	/**
	 * Group created.
	 */
	const handleCreatedGroup = useCallback(group => {
		onCreatedGroup(group);
		form.setState({...form.state, BuildTaskGroup: group});
		newGroupDialogOpen.setFalse();
	}, [form, newGroupDialogOpen, onCreatedGroup]);


	/**
	 * Submitting.
	 */
	const handleSubmit = useCallback(async e => {

		e.preventDefault();

		if (!form.state.BuildTaskGroup) {
			ToastStore.error("You must select a Group for this task.");
			return;
		}

		setIsSubmitting(true);
		onSubmittingChange?.(true);

		try {

			const taskId = task?.Id;

			const steps = (
				!taskId ?
					(
						stepsFetch.result?.length ?
							[...stepsFetch.result] :
							[{Title: null, BuildOrderIndex: 1}]
					) :
					undefined
			);

			const result = await api({
				url: (!taskId ? `/build/tasks` : `/build/tasks/${task.Id}`),
				method: (!taskId ? "POST" : "PUT"),
				data: {
					Title: form.state.Title,
					BuildCategory: (form.state.BuildCategory?.Id || form.state.BuildCategory || task?.BuildCategory || targetBuildCategoryId),
					BuildTaskGroup: (form.state.BuildTaskGroup?.Id || form.state.BuildTaskGroup),
					BuildOrderIndex: form.state.BuildOrderIndex,
					Steps: steps
				}
			}).then(({data}) => data);

			const savedTask = (!taskId ? result.Task : result);
			const savedSteps = (result.Steps || stepsFetch.result);

			if (steps && (result.Steps?.length !== steps.length)) {
				const stepsErrorCount = (steps.length - result.Steps.length);
				ToastStore.warning(`There was an error creating ${stepsErrorCount} ${pluralize("step", stepsErrorCount)} (of ${steps.length} total).`);
			}

			onSubmitted?.(savedTask, savedSteps, taskId, addAnother);

			if (addAnother) {

				form.setState({
					...form.state,
					Title: "",
					Steps: []
				});

				setTimeout(() => titleInputRef.current?.querySelector?.("input")?.focus?.());

			}

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);
		onSubmittingChange?.(false);

	}, [
		form,
		addAnother,
		onSubmitted,
		onSubmittingChange,
		stepsFetch,
		task,
		targetBuildCategoryId
	]);


	/**
	 * Toggling the "add another" checkbox.
	 */
	const handleToggleAddAnother = useCallback(() => {
		setAddAnother(!addAnother);
	}, [addAnother]);


	/**
	 * Render!
	 */
	return (
		<Flex
			className={scss.root}
			gap={1}>
			<Form
				id={formId}
				onSubmit={handleSubmit}>
				<Flex
					gap={0.5}>
					<div className={scss.headlineInputsContainer}>
						<Ref
							innerRef={titleInputRef}>
							<Form.Input
								autoFocus={true}
								disabled={isSubmitting}
								label="Title"
								maxLength={255}
								name="Title"
								onChange={form.updateStateFromSemanticInputChangeEvent}
								placeholder="Title"
								required={true}
								value={(form.state.Title || "")} />
						</Ref>
						<Form.Input
							disabled={isSubmitting}
							label="Build Priority"
							min={0}
							name="BuildOrderIndex"
							onChange={form.updateStateFromSemanticInputChangeEvent}
							placeholder="Build Priority"
							required={true}
							type="number"
							value={(form.state.BuildOrderIndex || "")} />
					</div>
					<Form.Field>
						<label
							children="Sub-Category" />
						<BuildCategoryPicker
							all={true}
							clearable={true}
							disabled={isSubmitting}
							label="Sub-Category"
							name="BuildCategory"
							onChange={form.updateProp}
							parentId={targetBuildCategoryId}
							reportIds={true}
							value={((form.state.BuildCategory && (form.state.BuildCategory !== targetBuildCategoryId)) ? form.state.BuildCategory : undefined)} />
						<a
							children="+ New Sub-Category"
							onClick={(!isSubmitting ? newSubCategoryDialogOpen.setTrue : () => null)}
							style={{display: "inline-block", marginTop: "0.5em"}} />
					</Form.Field>
					<Form.Field
						required={true}>
						<label
							children="Group" />
						<BuildTaskGroupPicker
							disabled={isSubmitting}
							buildCategoryId={targetBuildCategoryId}
							label="Group"
							name="BuildTaskGroup"
							onChange={form.updateProp}
							reportIds={true}
							value={form.state.BuildTaskGroup} />
						<a
							children="+ New Group"
							onClick={(!isSubmitting ? newGroupDialogOpen.setTrue : () => null)}
							style={{display: "inline-block", marginTop: "0.5em"}} />
					</Form.Field>
				</Flex>
			</Form>
			<Flex
				gap={0.5}>
				<Header
					content="Task Steps"
					sub={true} />
				<ErrorBoundary>
					<Loadable
						loading={stepsFetch.loading}
						loader={<SubduedStateLabel content="Loading..." />}
						error={stepsFetch.error}
						onErrorRetry={stepsFetch.call}>
						<BuildTaskStepsList
							disabled={(isSubmitting || isStepsSubmitting)}
							isNewTask={!task?.Id}
							onAddNew={handleAddStep}
							onChange={handleChangeStep}
							onDelete={handleDeleteStep}
							onDeleted={handleDeletedStep}
							steps={stepsFetch.result} />
						{(task?.Id && <SubduedStateLabel content={`Changes to steps apply immediately — you do not need to press the "OK" button.`} style={{fontSize: "0.9em", marginTop: "0.25em"}} />)}
					</Loadable>
				</ErrorBoundary>
			</Flex>
			{
				!task &&
					<Checkbox
						checked={addAnother}
						label="Add another Task after this one?"
						onChange={handleToggleAddAnother} />
			}
			{
				newSubCategoryDialogOpen.value &&
					<BuildCategoryDialog
						disableAddAnother={true}
						disableParentEditing={true}
						open={newSubCategoryDialogOpen.value}
						onClose={newSubCategoryDialogOpen.setFalse}
						onSubmitted={handleCreatedSubCategory}
						parentId={targetBuildCategoryId} />
			}
			{
				newGroupDialogOpen.value &&
					<BuildTaskGroupDialog
						defaultBuildCategory={targetBuildCategoryId}
						disableAddAnother={true}
						open={newGroupDialogOpen.value}
						onClose={newGroupDialogOpen.setFalse}
						onSubmitted={handleCreatedGroup} />
			}
		</Flex>
	);

});

export default BuildTaskForm;
