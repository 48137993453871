import scss from "./BuildDatabaseSheetHeader.module.scss";
import {memo} from "react";
import {Table} from "semantic-ui-react";

const BuildDatabaseSheetHeader = memo(({
	buildItems,
	genericColumns,
	onSelectedBuildItem
}) => {

	return (
		<Table.Header className={scss.root}>
			<Table.Row className={scss.row}>
				{
					genericColumns?.map((columnLabel, key) => (
						<Table.HeaderCell
							content={columnLabel}
							key={key} />
					))
				}
				{
					buildItems?.map((i, key) => (
						<Table.HeaderCell
							className={scss.buildItemCell}
							key={key}>
							<a onClick={() => onSelectedBuildItem(i)}>
								{i.Name} (P{i.BuildOrderIndex})
							</a>
						</Table.HeaderCell>
					))
				}
			</Table.Row>
		</Table.Header>
	);

});

export default BuildDatabaseSheetHeader;
