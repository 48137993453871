import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import useUser from "Hooks/useUser.js";
import Loadable from "Components/Loadable.js";
import SubduedStateLabel from "Components/SubduedStateLabel.js";
import ToastStore from "Toasts/ToastStore.js";
import {getPermissionUiName} from "Includes/Permission.js";
import {useCallback, useMemo, useState} from "react";
import {Checkbox, Header, Segment, Table} from "semantic-ui-react";

const UserPermissionsPane = ({user}) => {

	const authUser = useUser();
	const isAuthUser = (user.Id === authUser.Id);

	const permAdmin = "Administrator";

	const fetch = useAsync(useCallback(() => {
		return api({
			url: `/users/${user.Id}/permissions`
		}).then(({data}) => data);
	}, [user.Id]));


	const [isToggling, setIsToggling] = useState(false);


	const handleToggle = useCallback(async permission => {

		setIsToggling(true);

		try {

			await api({
				url: `/users/${user.Id}/permissions`,
				method: (!permission.Assigned ? "POST" : "DELETE"),
				data: {
					Permission: permission.Id
				}
			});

			fetch.setResult(
				fetch.result.map(p => {
					return {
						...p,
						Assigned: (
							(p.Id === permission.Id) ?
								!p.Assigned :
								((!permission.Assigned && permission.AssignmentLinks.includes(p.Id)) ? true : p.Assigned)
						)
					};
				})
			);

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsToggling(false);

	}, [fetch, user]);


	const sortedPermissions = useMemo(() => {

		const perms = [...(fetch.result || [])];

		return perms.sort((a, b) => {
			if (a.Id === permAdmin) return -1;
			else if (b.Id === permAdmin) return 1;
			else return ((a.Id > b.Id) ? 1 : -1);
		});

	}, [fetch.result]);


	const assignedPermissionsDependencies = useMemo(() => {
		return (fetch.result?.filter(p => p.Assigned).map(p => p.AssignmentLinks).flat() || []);
	}, [fetch.result]);


	return (
		<Segment>
			<Header
				content="Permissions"
				sub={true}
				style={{
					background: "#fff",
					position: "sticky",
					padding: "1em 0",
					margin: "-0.8em 0",
					top: "-1.2em",
					zIndex: 5
				}} />
			<SubduedStateLabel
				content="Permissions are synced on app launch, after login, and periodically on a timer."
				style={{marginTop: "0.5em"}} />
			<Loadable
				loading={fetch.loading}
				error={fetch.error}
				onErrorRetry={fetch.call}>
				<Table
					basic="very"
					compact={true}
					fixed={true}
					style={{
						marginTop: "-1em"
					}}
					unstackable={true}>
					<Table.Body>
						{
							sortedPermissions.map((permission, key) => {
								return (
									<Table.Row
										key={key}>
										<Table.Cell
											style={{
												width: "4rem"
											}}
											verticalAlign="top">
											<div
												style={{
													paddingTop: "0.5em"
												}}>
												<Checkbox
													checked={permission.Assigned}
													disabled={(
														isToggling ||
														user.Archived ||
														(isAuthUser && (permission.Id === permAdmin)) ||
														assignedPermissionsDependencies.includes(permission.Id)
													)}
													key={key}
													onChange={() => handleToggle(permission)}
													toggle={true} />
											</div>
										</Table.Cell>
										<Table.Cell
											verticalAlign="top">
											<p
												style={{
													marginBottom: "0.5em",
													paddingTop: "0.5em"
												}}>
												<strong>
													{getPermissionUiName(permission.Id)}
												</strong>
											</p>
											<p
												style={{
													margin: 0
												}}>
												{permission.Description}
											</p>
											{
												!!permission.AssignmentLinks.length &&
													<p
														style={{
															marginTop: "0.5em"
														}}>
														<em>(Automatically Assigns: {permission.AssignmentLinks.map(p => getPermissionUiName(p)).join(", ")})</em>
													</p>
											}
										</Table.Cell>
									</Table.Row>
								);
							})
						}
					</Table.Body>
				</Table>
			</Loadable>
		</Segment>
	);

};

export default UserPermissionsPane;
