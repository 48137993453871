import {memo} from "react";
import {Form, Ref} from "semantic-ui-react";

const ProfilePasswordChangeFormInput = memo(props => {

	return (
		<Ref innerRef={props.innerRef}>
			<Form.Input
				error={props.error}
				label={props.label}
				name={props.name}
				maxLength={(props.isNew ? 32 : undefined)}
				minLength={(props.isNew ? 8 : undefined)}
				onChange={props.onChange}
				placeholder={props.label}
				readOnly={props.readOnly}
				required={true}
				type="password"
				value={props.value} />
		</Ref>
	);

});

export default ProfilePasswordChangeFormInput;
