import api from "api.js";
import moment from "moment";
import scss from "./CustomersView.module.scss";
import useAsync from "Hooks/useAsync.js";
import useQuery from "Hooks/useQuery.js";
import useViewport from "Hooks/useViewport.js";
import CustomerStatusChip from "./CustomerStatusChip.js";
import CustomerStatusPicker from "./CustomerStatusPicker.js";
import CustomerType from "./CustomerType.js";
import CustomerTypePicker from "./CustomerTypePicker.js";
import Button from "Components/UriButton.js";
import DataTable from "Components/DataTable.js";
import View from "Views/View.js";
import {memo, useCallback, useMemo} from "react";
import {Link} from "react-router-dom";

const CustomersView = memo(() => {

	const {width: viewportWidth} = useViewport();
	const useMobileLayout = (viewportWidth <= 640);


	const query = useQuery(
		useMemo(() => ({
			Limit: 25,
			Page: 1,
			Status: null,
			Type: null,
			Search: "",
			SortOrder: "EnquiryDate",
			SortDirection: "Desc"
		}), [])
	);


	const customersFetch = useAsync(useCallback(() => {
		return api({
			url: `/customers`,
			params: query.value
		}).then(({data}) => data);
	}, [query.value]));


	const renderCustomerName = useCallback(c => {
		return <Link children={c.Name} to={`/customers/${c.Id}`} />;
	}, []);


	const fields = useMemo(() => {
		return [
			{
				label: "Status",
				render: i => <CustomerStatusChip size="small" status={i.Status} />,
				width: "6rem",
				align: "center"
			},
			{
				id: "Name",
				label: "Name",
				render: renderCustomerName,
				sortable: true,
				hidden: useMobileLayout
			},
			{
				label: "Type",
				render: i => CustomerType.getLabel(i.Type.Name),
				hidden: useMobileLayout
			},
			{
				id: "Customer",
				label: "Name",
				render(i) {
					return (
						<>
							<p style={{marginBottom: "0.25em"}}>
								{renderCustomerName(i)}
							</p>
							<p>{CustomerType.getLabel(i.Type.Name)}</p>
						</>
					);
				},
				hidden: !useMobileLayout
			},
			{
				id: "EnquiryDate",
				label: "Enquiry Date",
				render(i) {
					const date = i.Profile.EnquiryDate;
					if (!date) return "—";
					else return (new moment(date)).format("DD/MM/YYYY");
				},
				width: "9rem",
				align: "right",
				sortable: true
			}
		];
	}, [renderCustomerName, useMobileLayout]);


	return (
		<View>
			<DataTable
				actions={
					<Button
						icon="add"
						content="New"
						primary={true}
						size="small"
						uri="/customers/new" />
				}
				controls={
					<div className={`ui form small ${scss.controls}`}>
						<CustomerStatusPicker
							clearable={true}
							disabled={customersFetch.loading}
							label={undefined}
							name="Status"
							onChange={query.updateProp}
							placeholder="Status"
							value={query.value.Status} />
						<CustomerTypePicker
							clearable={true}
							disabled={customersFetch.loading}
							label={undefined}
							name="Type"
							onChange={query.updateProp}
							placeholder="Type"
							value={query.value.Type} />
					</div>
				}
				data={customersFetch}
				fields={fields}
				label="Customer Dashboard"
				query={query}
				searchable={true}
				sortable={true}
				stickyHeader={true} />
		</View>
	);

});

export default CustomersView;
