/**
 * Enum implementation
 *
 * @package RedSky
 * @subpackage Includes
 * @author Heron Web Ltd
 * @copyright Red Sky Shepherds Huts Limited
 */
class Enum {

	/**
	 * Get the name of a case by its value.
	 *
	 * @param {mixed} value
	 * @return {?String}
	 */
	static getCaseNameByValue(value) {

		for (const prop of Object.keys(this)) {
			if (Object.hasOwn(this, prop)) {
				if (this[prop] === value) {
					return prop;
				}
			}
		}

		return null;

	}


	/**
	 * Get a case's display label.
	 * 
	 * @param {String} caseName
	 * @return {String}
	 */
	static getLabel(caseName) {
		return caseName;
	}


	/**
	 * Get the enum's cases.
	 *
	 * @return {Array<String>}
	 */
	static get cases() {
		return Object.keys(this);
	}

}

export default Enum;
