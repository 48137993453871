import scss from "./TaskStepForm.module.scss";
import {memo, useCallback} from "react";
import {Button, Form, Label} from "semantic-ui-react";

const TaskStepForm = memo(({
	autoFocus,
	disabled,
	onChange,
	onChangeOrder,
	onDelete,
	step
}) => {

	const handleChange = useCallback(e => {
		onChange(step, {[e.target.name]: (e.target.value || null)});
	}, [onChange, step]);

	const handleDelete = useCallback(() => {
		onDelete(step);
	}, [onDelete, step]);

	return (
		<div
			className={scss.root}>
			<div
				className={scss.actions}>
				<Button
					basic={true}
					disabled={disabled}
					icon="trash alternate"
					negative={true}
					onClick={handleDelete}
					type="button" />
				<Button
					basic={true}
					disabled={disabled}
					icon="arrow up"
					onClick={() => onChangeOrder(step, -1)}
					type="button" />
				<Button
					basic={true}
					disabled={disabled}
					icon="arrow down"
					onClick={() => onChangeOrder(step, 1)}
					type="button" />
			</div>
			<Form.Input
				autoFocus={autoFocus}
				className={scss.input}
				disabled={disabled}
				placeholder="Task Step"
				name="Title"
				maxLength={255}
				onChange={handleChange}
				required={!!step.Id}
				size="small"
				value={(step.Title || "")} />
			{
				step.Complete &&
					<Label
						className={scss.lbl__complete}
						content="Completed"
						color="green" />
			}
		</div>
	);

});

export default TaskStepForm;
