import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import Picker from "Components/Picker.js";
import {memo, useCallback} from "react";

const BuildCategoryPicker = memo(props => {

	const {disabledIds, parentId, topLevelOnly} = props;

	const categoriesFetch = useAsync(useCallback(() => {
		return api({
			url: `/build/categories`,
			params: {
				Parent: (parentId || null),
				TopLevelOnly: (topLevelOnly ? 1 : 0)
			}
		}).then(({data}) => data);
	}, [parentId, topLevelOnly]));

	return (
		<Picker
			clearable={props.clearable}
			disabled={props.disabled}
			error={categoriesFetch.error}
			getOptionDisabled={category => disabledIds?.includes(category.Id)}
			label={props.label}
			loading={categoriesFetch.loading}
			placeholder={props.placeholder}
			name={props.name}
			onChange={props.onChange}
			options={(categoriesFetch.result || [])}
			reportIds={props.reportIds}
			value={props.value} />
	);

});

export default BuildCategoryPicker;
