import api from "api.js";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useState} from "react";

const TaskDeletionDialog = memo(({
	open,
	onClose,
	onDeleted,
	taskId
}) => {

	const [isSubmitting, setIsSubmitting] = useState(false);


	const handleSubmit = useCallback(async () => {

		setIsSubmitting(true);

		try {

			await api({
				url: `/tasks/${taskId}`,
				method: "DELETE"
			});

			onClose();
			onDeleted(taskId);

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [taskId, onClose, onDeleted]);


	return (
		<Dialog
			open={open}
			onClose={onClose}
			onOk={handleSubmit}
			submitting={isSubmitting}
			size="mini"
			title="Delete Task"
			content={
				<>
					<p>Are you sure you want to delete this Task?</p>
					<p>Deletion is permanent and non-recoverable.</p>
				</>
			} />
	);

});

export default TaskDeletionDialog;
