import {v4 as uuid} from "uuid";
import {useCallback, useRef, useState} from "react";

const useForm = (initialValues, onChange=null) => {

	const id = useRef(uuid());
	const originalValues = useRef(initialValues);
	const [state, setState] = useState(initialValues);


	const reset = useCallback(() => {
		setState(originalValues);
	}, [originalValues]);


	const handleUpdate = useCallback(props => {
		onChange?.();
		setState({...state, ...props});
	}, [state, onChange]);


	const handleCheckboxToggle = useCallback((e, {name}) => {
		handleUpdate({
			[name]: !state[name]
		});
	}, [state, handleUpdate]);


	const updateProp = useCallback((value, name) => {
		handleUpdate({[name]: (value || null)});
	}, [handleUpdate]);


	const updateStateFromNativeInputChangeEvent = useCallback(e => {
		const {name, value} = e.target;
		updateProp(value, name);
	}, [updateProp]);


	const updateStateFromSemanticInputChangeEvent = useCallback((e, {name, value}) => {
		updateProp(value, name);
	}, [updateProp]);


	return {
		id: id.current,
		state,
		setState,
		reset,
		handleCheckboxToggle,
		updateProp,
		updateStateFromNativeInputChangeEvent,
		updateStateFromSemanticInputChangeEvent
	};

};

export default useForm;
