import api from "api.js";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useState} from "react";

const CorrespondenceDeletionDialog = memo(({
	correspondence,
	open,
	onClose,
	onDeleted
}) => {

	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleDelete = useCallback(async () => {

		setIsSubmitting(true);

		try {

			await api({
				url: `/projects/build/revisions/correspondence/${correspondence.Id}`,
				method: "DELETE"
			});

			onDeleted();
			onClose();

			ToastStore.success("Correspondence record successfully deleted.");

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [
		correspondence.Id,
		onClose,
		onDeleted
	]);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			onOk={handleDelete}
			submitting={isSubmitting}
			size="tiny"
			title="Delete Correspondence Record"
			content="Are you sure you want to permanently delete this correspondence record?" />
	);

});

export default CorrespondenceDeletionDialog;
