import BuildTaskForm from "./BuildTaskForm.js";
import BuildTaskDeletionDialog from "./BuildTaskDeletionDialog.js";
import Dialog from "Components/Dialog.js";
import useValue from "Hooks/useValue.js";
import {memo, useState} from "react";
import {Button} from "semantic-ui-react";

const BuildTaskDialog = memo(({
	task,
	targetBuildCategoryId,
	open,
	onClose,
	onCreatedCategory,
	onCreatedGroup,
	onDeleted,
	onSubmitted
}) => {

	const formId = "build_task_dialog";

	const {
		value: deletionDialogOpen,
		setFalse: closeDeletionDialog,
		setTrue: openDeletionDialog
	} = useValue(false);

	const [isSubmitting, setIsSubmitting] = useState(false);

	return (
		<Dialog
			headerActions={(
				task &&
					<Button
						basic={true}
						content="Delete"
						disabled={isSubmitting}
						negative={true}
						onClick={openDeletionDialog}
						size="tiny" />
			)}
			okButtonForm={formId}
			okButtonType="submit"
			onClose={onClose}
			open={open}
			size="small"
			submitting={isSubmitting}
			title={(task?.Title || "Build Task")}>
			<BuildTaskForm
				formId={formId}
				task={task}
				targetBuildCategoryId={targetBuildCategoryId}
				onCreatedCategory={onCreatedCategory}
				onCreatedGroup={onCreatedGroup}
				onSubmitted={onSubmitted}
				onSubmittingChange={setIsSubmitting} />
			<BuildTaskDeletionDialog
				taskId={task?.Id}
				open={deletionDialogOpen}
				onClose={closeDeletionDialog}
				onDeleted={onDeleted} />
		</Dialog>
	);

});

export default BuildTaskDialog;
