import scss from "./BuildDatabaseSheetTaskGroupTotalsRow.module.scss";
import {getDurationLabelClockFormat} from "Includes/Duration.js";
import {memo, useMemo} from "react";
import {Table} from "semantic-ui-react";

const BuildDatabaseSheetTaskGroupTotalsRow = memo(({
	tasks,
	buildItems,
	sheetData,
	genericColumnsCount
}) => {

	/**
	 * Get the task steps that apply to the tasks we are 
	 * interested in (i.e. the tasks that apply to this group)
	 */
	const stepsForTasks = useMemo(() => {

		const stepIds = [];

		for (const task of (tasks || [])) {
			for (const step of (sheetData?.BuildTaskSteps[task.Id] || [])) {
				stepIds.push(step.Id);
			}
		}

		return stepIds;

	}, [sheetData, tasks]);

	/**
	 * Render!
	 */
	return (
		<Table.Row>
			{(
				(genericColumnsCount > 1) &&
					<Table.Cell
						colSpan={genericColumnsCount}
						content={null} />
			)}
			{
				buildItems.map((i, key) => {

					const taskLinksThisItemThisGroup = Object.keys(
						(sheetData?.BuildItemTaskStepLinks?.[i.Id] || {})
					).filter(taskStepId => {
						return stepsForTasks.includes(parseInt(taskStepId));
					}).map(Id => {
						return sheetData?.BuildItemTaskStepLinks?.[i.Id]?.[Id];
					});

					const totalDurationMinutes = (taskLinksThisItemThisGroup?.reduce((a, b) => (a + (b?.TimeAllowance || 0)), 0) || 0);

					return (
						<Table.Cell
							className={scss.cell}
							content={getDurationLabelClockFormat(totalDurationMinutes)}
							key={key}
							textAlign="center" />
					);

				})
			}
		</Table.Row>
	);

});

export default BuildDatabaseSheetTaskGroupTotalsRow;
