import initialState from "./State.js";
import reducer from "./Reducer.js";
import reduxPersistStorage from "redux-persist/lib/storage";
import {applyMiddleware, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import {persistReducer, persistStore} from "redux-persist";
import {createStateSyncMiddleware, initMessageListener} from "redux-state-sync";

/** `redux-persist` config */
const reduxPersistConfig = {
	key: "app",
	storage: reduxPersistStorage,
	whitelist: [
		"auth",
		"permissions",
		"user"
	]
};

/** `redux-state-sync` config */
const reduxStateSyncConfig = {
	blacklist: [
		"persist/PERSIST",			// avoid conflicts with `redux-persist`
		"persist/REHYDRATE"			// avoid conflicts with `redux-persist`
	]
};

/** Create our Redux store */
const reduxStore = createStore(
	persistReducer(reduxPersistConfig, reducer),
	initialState,
	composeWithDevTools(applyMiddleware(createStateSyncMiddleware(reduxStateSyncConfig)))
);

/** Create a `redux-persist` persistor for the store */
const reduxStorePersistor = persistStore(reduxStore);

/** Initialise `redux-state-sync` */
initMessageListener(reduxStore);

export default reduxStore;
export {reduxStore as Store, reduxStorePersistor as StorePersistor};
