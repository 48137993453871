import BuildCategoryDeletionDialog from "./BuildCategoryDeletionDialog.js";
import BuildCategoryForm from "./BuildCategoryForm.js";
import Dialog from "Components/Dialog.js";
import useValue from "Hooks/useValue.js";
import {memo, useState} from "react";
import {Link} from "react-router-dom";
import {Button} from "semantic-ui-react";

const BuildCategoryDialog = memo(({
	buildCategory,
	disableAddAnother,
	disableDeletion,
	disableParentEditing,
	open,
	onClose,
	onDeleted,
	onSubmitted,
	parentId,
	productRangeId,
	productRangeMode
}) => {

	const formId = "build_category_dialog";

	const {
		value: deletionDialogOpen,
		setFalse: closeDeletionDialog,
		setTrue: openDeletionDialog
	} = useValue(false);

	const [isSubmitting, setIsSubmitting] = useState(false);

	return (
		<Dialog
			headerActions={(
				(buildCategory && !disableDeletion) &&
					<Button
						basic={true}
						content="Delete"
						disabled={isSubmitting}
						negative={true}
						onClick={openDeletionDialog}
						size="tiny" />
			)}
			okButtonForm={formId}
			okButtonType="submit"
			open={open}
			onClose={onClose}
			size={((productRangeMode || disableParentEditing) ? "tiny" : "small")}
			submitting={isSubmitting}
			title={(buildCategory?.Name || (productRangeMode ? "Product Range" : "Product Category"))}>
			<BuildCategoryForm
				formId={formId}
				buildCategory={buildCategory}
				disableAddAnother={disableAddAnother}
				disableParentEditing={disableParentEditing}
				onSubmitted={onSubmitted}
				onSubmittingChange={setIsSubmitting}
				parentId={parentId}
				productRangeMode={productRangeMode} />
			{
				(disableDeletion && (productRangeId || parentId)) &&
					<p style={{fontSize: "0.85em", marginTop: "1.5em"}}>
						Trying to delete this Product Category? Use the <Link children="Product Range screen" to={`/build/ranges/${(productRangeId || parentId)}`} />.
					</p>
			}
			<BuildCategoryDeletionDialog
				buildCategoryId={buildCategory?.Id}
				open={deletionDialogOpen}
				onClose={closeDeletionDialog}
				onDeleted={onDeleted}
				productRangeMode={productRangeMode} />
		</Dialog>
	);

});

export default BuildCategoryDialog;
