import Enum from "Includes/Enum.js";

/**
 * Correspondence channel definitions
 * 
 * @package RedSky
 * @subpackage Projects
 * @author Heron Web Ltd
 * @copyright Red Sky Shepherd's Huts
 */
class CorrespondenceChannel extends Enum {

	static Email = 1;

	static Phone = 2;

	static Meeting = 3;

	static Post = 4;

	static Other = 5;

	static getIcon(caseName) {
		return {
			Email: "mail",
			Phone: "call",
			Meeting: "group",
			Post: "envelope square",
			Other: "ellipsis horizontal"
		}[caseName];
	}

}

export default CorrespondenceChannel;
