import Flex from "Components/Flex.js";
import Item from "./BuildTaskStepsListItem.js";
import {sortBuildResources} from "BuildDatabase/BuildDatabaseUtils.js";
import {memo} from "react";

const BuildTaskStepsList = memo(({
	disabled,
	isNewTask,
	onAddNew,
	onChange,
	onDelete,
	onDeleted,
	steps
}) => {

	const maxStepIndex = (Math.max(...steps.map(s => s.BuildOrderIndex), 0) || 0);

	return (
		<Flex
			gap={0.5}>
			{
				sortBuildResources(steps).map((step, key) => {
					return (
						<Item
							disabled={disabled}
							disableDeletion={(!isNewTask && (steps.length === 1))}
							key={(step?.Id || `${key}${steps.length}`)}
							onDelete={onDelete}
							onDeleted={onDeleted}
							onSubmit={onChange}
							step={step}
							stepIndex={key} />
					);
				})
			}
			<Item
				key="new"
				disabled={disabled}
				onSubmit={onAddNew}
				stepIndex={(maxStepIndex + 1)} />
		</Flex>
	);

});

export default BuildTaskStepsList;
