import api from "api.js";
import scss from "./StaffMemberDepartmentsPane.module.scss";
import useAsync from "Hooks/useAsync.js";
import useValue from "Hooks/useValue.js";
import AddToDepartmentDialog from "./AddStaffMemberToDepartmentDialog.js";
import Flex from "Components/Flex.js";
import Loadable from "Components/Loadable.js";
import RemoveFromDepartmentDialog from "Departments/StaffMemberLinks/RemoveStaffMemberFromDepartmentDialog.js";
import SubduedStateLabel from "Components/SubduedStateLabel.js";
import {memo, useCallback, useMemo} from "react";
import {Link} from "react-router-dom";
import {Button, Checkbox, Label} from "semantic-ui-react";

const StaffMemberDepartmentsPane = memo(({
	staffMemberId,
	preventMutation
}) => {

	const addDialogOpen = useValue(false);
	const deletionTarget = useValue(null);
	const showArchivedDepts = useValue(false);


	const fetch = useAsync(useCallback(() => {
		return api({
			url: `/staff/${staffMemberId}/departments`
		}).then(({data}) => data);
	}, [staffMemberId]));


	const handleAddedToDepartment = useCallback(department => {
		fetch.setResult([
			...fetch.result,
			department
		]);
	}, [fetch]);


	const handleRemovedFromDepartment = useCallback(departmentId => {
		fetch.setResult(fetch.result.filter(({Id}) => (Id !== departmentId)));
	}, [fetch]);


	const departmentsToRender = useMemo(() => {
		return (fetch.result || []).filter(dept => {
			return (showArchivedDepts.value || !dept.Archived);
		}).sort((a, b) => {
			return a.Name.localeCompare(b.Name);
		});
	}, [fetch.result, showArchivedDepts.value]);


	return (
		<Loadable
			loading={fetch.loading}
			error={fetch.error}
			onErrorRetry={fetch.call}>
			<Flex
				gap={0.5}>
				<Flex
					alignItems="center"
					className={scss.toolbar}
					columnar={true}>
					{
						!preventMutation &&
							<Button
								className={scss.btn__add}
								content="Add to Department"
								icon="add"
								size="tiny"
								onClick={addDialogOpen.setTrue}
								primary={true} />
					}
					<Checkbox
						checked={showArchivedDepts.value}
						label="Show archived Departments"
						onChange={showArchivedDepts.toggleBoolean} />
				</Flex>
				{
					fetch.result?.length ?
						departmentsToRender.map((department, key) => {
							return (
								<Flex
									alignItems="center"
									columnar={true}
									gap={0.25}
									key={key}>
									{
										(!preventMutation && !department.Archived) &&
											<Button
												basic={true}
												icon="close"
												size="mini"
												negative={true}
												onClick={() => deletionTarget.set(department)} />
									}
									{
										department.Archived &&
											<Label
												color="orange"
												content="Archived"
												size="tiny" />
									}
									<Link
										children={department.Name}
										to={`/departments/${department.Id}`} />
								</Flex>
							);
						}) :
						<SubduedStateLabel
							content="This Staff Member doesn't belong to any Departments." />
				}
				{
					(!!fetch.result?.length && !departmentsToRender.length) &&
						<SubduedStateLabel
							content="Nothing to show." />
				}
			</Flex>
			{
				addDialogOpen.value &&
					<AddToDepartmentDialog
						open={addDialogOpen.value}
						onAdded={handleAddedToDepartment}
						onClose={addDialogOpen.setFalse}
						staffMemberId={staffMemberId} />
			}
			{
				deletionTarget.value &&
					<RemoveFromDepartmentDialog
						open={!!deletionTarget.value}
						onClose={deletionTarget.setNull}
						onRemoved={handleRemovedFromDepartment}
						departmentId={deletionTarget.value?.Id}
						staffMemberId={staffMemberId} />
			}
		</Loadable>
	);

});

export default StaffMemberDepartmentsPane;
