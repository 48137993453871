import {memo} from "react";
import {Table} from "semantic-ui-react";

const BuildDatabaseSheetDividerRow = memo(({columnCount}) => {

	return (
		<Table.Row>
			<Table.Cell
				colSpan={columnCount} />
		</Table.Row>
	);

});

export default BuildDatabaseSheetDividerRow;
