import api from "api.js";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useState} from "react";

const ProjectDeletionDialog = memo(({
	projectId,
	open,
	onClose,
	onDeleted
}) => {

	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleDelete = useCallback(async () => {

		setIsSubmitting(true);

		try {

			await api({
				url: `/projects/${projectId}`,
				method: "DELETE"
			});

			onDeleted(projectId);

			onClose();
			ToastStore.success("Project deleted successfully.");

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [projectId, onClose, onDeleted]);

	return (
		<Dialog
			onClose={onClose}
			onOk={handleDelete}
			open={open}
			submitting={isSubmitting}
			size="tiny"
			title="Delete Project"
			content={
				<>
					<p>Are you sure you want to delete this Project?</p>
					<p>The Project, its Revisions, and all other associated data will be permanently deleted.</p>
				</>
			} />
	);

});

export default ProjectDeletionDialog;
