import api from "api.js";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useState} from "react";

const ProjectBuildRevisionBuildItemLinkDeletionDialog = memo(({
	linkId,
	open,
	onClose,
	onDeleted
}) => {

	const [isSubmitting, setIsSubmitting] = useState(false);


	const handleDelete = useCallback(async () => {

		setIsSubmitting(true);

		try {

			await api({
				url: `/projects/build/revisions/items/${linkId}`,
				method: "DELETE"
			});

			onDeleted(linkId);

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [linkId, onDeleted]);


	return (
		<Dialog
			title="Remove Project Item"
			content="Are you sure you want to remove this Project Item from this Project Revision?"
			size="tiny"
			open={open}
			onClose={onClose}
			onOk={handleDelete}
			submitting={isSubmitting} />
	);

});

export default ProjectBuildRevisionBuildItemLinkDeletionDialog;
