import CustomerStatus from "./CustomerStatus.js";
import EnumChip from "Components/EnumChip.js";
import {memo} from "react";

const CustomerStatusChip = memo(({
	size,
	status
}) => {

	return (
		<EnumChip
			enumClass={CustomerStatus}
			size={size}
			valueCase={status} />
	);

});

export default CustomerStatusChip;
