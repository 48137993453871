import api from "api.js";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useState} from "react";
import {useNavigate} from "react-router-dom";

const StaffMemberDeletionDialog = memo(({
	open,
	onClose,
	staffMemberId
}) => {

	const navigate = useNavigate();

	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleDelete = useCallback(async () => {

		setIsSubmitting(true);

		try {

			await api({
				url: `/staff/${staffMemberId}`,
				method: "DELETE"
			});

			navigate(`/staff`);

			onClose();
			ToastStore.success("Staff Member deleted successfully.");

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [navigate, onClose, staffMemberId]);

	return (
		<Dialog
			onClose={onClose}
			onOk={handleDelete}
			open={open}
			submitting={isSubmitting}
			size="tiny"
			title="Delete Staff Member"
			content={
				<>
					<p>Are you sure you want to permanently delete this Staff Member and all their data?</p>
					<p>Assigned Tasks will be retained and returned to the bin when reassignment is required.</p>
				</>
			} />
	);

});

export default StaffMemberDeletionDialog;
