import api from "api.js";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useState} from "react";

const BuildTaskGroupDeletionDialog = memo(({
	taskGroupId,
	open,
	onClose,
	onDeleted
}) => {

	const [isSubmitting, setIsSubmitting] = useState(false);


	const handleSubmit = useCallback(async () => {

		setIsSubmitting(true);

		try {

			await api({
				url: `/build/tasks/groups/${taskGroupId}`,
				method: "DELETE"
			});

			onDeleted(taskGroupId);

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [taskGroupId, onDeleted]);


	return (
		<Dialog
			open={open}
			onClose={onClose}
			onOk={handleSubmit}
			submitting={isSubmitting}
			size="tiny"
			title="Delete Task Group"
			content={
				<>
					<p>Are you sure you want to delete this Task Group?</p>
					<p>All Tasks in the Task Group will be permanently deleted and removed from any current Project Revisions.</p>
					<p>No existing Tasks already added to the Production Schedule will be affected.</p>
				</>
			} />
	);

});

export default BuildTaskGroupDeletionDialog;
