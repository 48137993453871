import api from "api.js";
import Dialog from "Components/Dialog.js";
import ToastStore from "Toasts/ToastStore.js";
import {memo, useCallback, useState} from "react";
import {useNavigate} from "react-router-dom";

const UserDeletionDialog = memo(({
	open,
	onClose,
	userId
}) => {

	const navigate = useNavigate();

	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleDelete = useCallback(async () => {

		setIsSubmitting(true);

		try {

			await api({
				url: `/users/${userId}`,
				method: "DELETE"
			});

			navigate(`/users`);

			onClose();
			ToastStore.success("User deleted successfully.");

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [userId, navigate, onClose]);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			onOk={handleDelete}
			submitting={isSubmitting}
			size="tiny"
			title="Delete User"
			content="Are you sure you want to permanently delete this User and all their data?" />
	);

});

export default UserDeletionDialog;
