import api from "api.js";
import dAuth from "Dispatchers/dAuth.js";
import useAuth from "Hooks/useAuth.js";
import usePermissions from "Hooks/usePermissions.js";
import useUser from "Hooks/useUser.js";
import AccessDeniedView from "Index/AccessDeniedView.js";
import AppBar from "Ui/AppBar.js";
import ErrorBoundary from "Components/ErrorBoundary.js";
import ErrorBoundaryRouted from "Components/ErrorBoundaryRouted.js";
import Router from "./Router.js";
import Routes from "./Routes.js";
import TargetUriListener from "./Listeners/TargetUriListener.js";
import ToastProvider from "Toasts/ToastProvider.js";
import ToastStore from "Toasts/ToastStore.js";
import {useCallback, useEffect, useMemo, useRef} from "react";
import {BrowserRouter} from "react-router-dom";

const Main = () => {

	const initComplete = useRef(false);

	const {isAuthed} = useAuth();
	const {hasPermission} = usePermissions();
	const user = useUser();

	const routes = useMemo(() => {
		return Routes.filter(route => {
			return (isAuthed || route.public);
		}).map(route => {

			const authed = (
				route.public ||
				!route.permission ||
				hasPermission(route.permission)
			);

			if (!authed) {
				return {
					...route,
					element: (
						<AccessDeniedView
							permission={route.permission} />
					)
				};
			}
			else return route;

		});
	}, [isAuthed, hasPermission]);


	const refreshAuth = useCallback(async () => {

		if (!isAuthed) {
			return;
		}

		try {

			const result = await api({
				url: `/auth/refresh`,
				method: "POST"
			});

			if (result.data?.user?.Id === user?.Id) {
				dAuth(result.data);
			}

		}
		catch (e) {
			// ...
		}

	}, [isAuthed, user]);


	useEffect(() => {
		if (!initComplete.current) {

			if (isAuthed) {
				refreshAuth();
			}

			initComplete.current = true;

		}
	}, [isAuthed, refreshAuth]);


	useEffect(() => {
		const intervalId = setInterval(refreshAuth, (900 * 1000));
		return () => clearInterval(intervalId);
	}, [refreshAuth]);


	return (
		<BrowserRouter>
			<ErrorBoundary fallback={null}>
				{(isAuthed && <AppBar />)}
			</ErrorBoundary>
			<ErrorBoundaryRouted>
				<Router routes={routes} />
			</ErrorBoundaryRouted>
			<ErrorBoundary fallback={null}>
				<TargetUriListener />
			</ErrorBoundary>
			<ErrorBoundary fallback={null}>
				<ToastProvider store={ToastStore} />
			</ErrorBoundary>
		</BrowserRouter>
	);

};

export default Main;
