import BuildTaskGroupDeletionDialog from "./BuildTaskGroupDeletionDialog.js";
import BuildTaskGroupForm from "./BuildTaskGroupForm.js";
import Dialog from "Components/Dialog.js";
import useValue from "Hooks/useValue.js";
import {memo, useState} from "react";
import {Button} from "semantic-ui-react";

const BuildTaskGroupDialog = memo(({
	taskGroup,
	defaultBuildCategory,
	disableAddAnother,
	open,
	onClose,
	onDeleted,
	onSubmitted
}) => {

	const formId = "build_task_group_dialog";

	const {
		value: deletionDialogOpen,
		setFalse: closeDeletionDialog,
		setTrue: openDeletionDialog
	} = useValue(false);

	const [isSubmitting, setIsSubmitting] = useState(false);

	return (
		<Dialog
			headerActions={(
				taskGroup &&
					<Button
						basic={true}
						content="Delete"
						disabled={isSubmitting}
						negative={true}
						onClick={openDeletionDialog}
						size="tiny" />
			)}
			okButtonForm={formId}
			okButtonType="submit"
			onClose={onClose}
			open={open}
			size="tiny"
			submitting={isSubmitting}
			title={(taskGroup?.Name || "Task Group")}>
			<BuildTaskGroupForm
				formId={formId}
				taskGroup={taskGroup}
				defaultBuildCategory={defaultBuildCategory}
				disableAddAnother={disableAddAnother}
				onSubmitted={onSubmitted}
				onSubmittingChange={setIsSubmitting} />
			<BuildTaskGroupDeletionDialog
				taskGroupId={taskGroup?.Id}
				open={deletionDialogOpen}
				onClose={closeDeletionDialog}
				onDeleted={onDeleted} />
		</Dialog>
	);

});

export default BuildTaskGroupDialog;
