import api from "api.js";
import useAsync from "Hooks/useAsync.js";
import useQuery from "Hooks/useQuery.js";
import BooleanStateIcon from "Components/BooleanStateIcon.js";
import DataTable from "Components/DataTable.js";
import NullableBooleanCheckboxSet from "Components/NullableBooleanCheckboxSet.js";
import UriButton from "Components/UriButton.js";
import View from "Views/View.js";
import {useCallback, useMemo} from "react";
import {Link} from "react-router-dom";

const DepartmentsView = () => {

	const query = useQuery(
		useMemo(() => ({
			Archived: 0,
			Search: "",
			SortOrder: "Name",
			SortDirection: "Asc"
		}), [])
	);


	const fetch = useAsync(useCallback(() => {
		return api({
			url: `/departments`,
			params: query.value
		}).then(({data}) => data);
	}, [query.value]));


	const fields = useMemo(() => {
		return [
			{
				id: "Name",
				label: "Name",
				render: i => <Link children={i.Name} to={`/departments/${i.Id}`} />,
				sortable: true
			},
			{
				id: "Archived",
				label: "Archived",
				title: "Archived Departments are no longer used and don't display in the system.",
				render: i => (
					<BooleanStateIcon
						useDashAsFalse={true}
						value={i.Archived} />
				),
				renderFilters(filterValue, onUpdateFilterValue) {
					return (
						<NullableBooleanCheckboxSet
							labelTrue="Archived"
							labelFalse="Non-archived"
							onChange={onUpdateFilterValue}
							reportIntegers={true}
							value={filterValue} />
					);
				},
				align: "center",
				width: "9rem",
				filterable: true,
				defaultFilterValue: null
			}
		];
	}, []);


	return (
		<View>
			<DataTable
				actions={
					<UriButton
						content="New"
						icon="add"
						primary={true}
						size="small"
						uri="/departments/new" />
				}
				data={fetch}
				fields={fields}
				label="Departments"
				noPagination={true}
				query={query}
				searchable={true}
				sortable={true}
				stickyHeader={true} />
		</View>
	);

};

export default DepartmentsView;
