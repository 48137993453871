import {memo} from "react";

const ProductionScheduleViewDateHeader = memo(({date}) => {

	return (
		<div>
			<div><strong>{date.format("dddd")}</strong></div>
			<div>{date.format("DD/MM/YYYY")}</div>
		</div>
	);

});

export default ProductionScheduleViewDateHeader;
