import {useCallback, useState} from "react";

const useValue = (initialValue=null) => {

	const [value, set] = useState(initialValue);

	const setTrue = useCallback(() => set(true), []);
	const setFalse = useCallback(() => set(false), []);
	const setNull = useCallback(() => set(null), []);
	const toggleBoolean = useCallback(() => set(!value), [value]);

	return {
		value,
		set,
		setTrue,
		setFalse,
		setNull,
		toggleBoolean
	};

};

export default useValue;
