import CustomerContactForm from "./CustomerContactForm.js";
import Dialog from "Components/Dialog.js";
import {memo, useCallback, useState} from "react";

const CustomerContactDialog = memo(({
	contact,
	customerId,
	open,
	onClose,
	onSavedContact
}) => {

	const formId = "customer_contact_dialog";
	const [isSubmitting, setIsSubmitting] = useState(false);


	const handleSavedContact = useCallback((savedContact, existingContact) => {
		onSavedContact(savedContact, existingContact);
		onClose();
	}, [onSavedContact, onClose]);


	return (
		<Dialog
			open={open}
			onClose={onClose}
			okButtonForm={formId}
			okButtonType="submit"
			submitting={isSubmitting}
			size="tiny"
			title="Customer Contact">
			<CustomerContactForm
				contact={contact}
				customerId={customerId}
				formId={formId}
				gap={0.5}
				hideControls={true}
				onSaved={handleSavedContact}
				onSubmitting={setIsSubmitting} />
		</Dialog>
	);

});

export default CustomerContactDialog;
